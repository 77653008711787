import { useEffect, useRef, useState, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import Footer from '../components/Footer/Footer';
import { LoginResponse } from '../models/auth/login.model';
import { useAppSelector } from '../store';
import { AdminLoginResponseModel } from '../models/admin/admin.login.model';
import AdminHome from './AdminHome';
import { PostUserBucketService } from '../services/bucket/post.user.bucket.service';
import { SaveCodeService } from '../services/oneDrive/auth/save.code.service';
import { BoxPostTokenService } from '../services/auth/box/box.post.token.service';
import { AWSPostTokenService } from '../services/auth/aws/aws.post.token.service';
import { CreateAWSBucketService } from '../services/aws/create.aws.bucket.service';
import { PostGoogleTokenService } from '../services/googleDrive/auth/token.google.service';
import '../styles/scrollbar.css';

// Import Material UI icons
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ShareIcon from '@mui/icons-material/Share';
import SearchIcon from '@mui/icons-material/Search';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import GroupsIcon from '@mui/icons-material/Groups';
import SecurityIcon from '@mui/icons-material/Security';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import DiamondIcon from '@mui/icons-material/Diamond';

// Import logo and images
import VeewerLogo from '../assets/Veewer siyah @4x.png';
import ProductsPage from '../assets/ProductsPage.png';
import ProductDetails from '../assets/ProductDetails.png';
import Configurator from '../assets/Configurator.png';
import { baseUrl } from '../config/axios.config';

// Import DemoModal
import DemoModal from '../components/DemoModal/DemoModal';

const Home = () => {
	const navigate = useNavigate();
	const [showHint, setShowHint] = useState(false);
	const [isModelLoaded, setIsModelLoaded] = useState(false);
	const [showScrollIndicator, setShowScrollIndicator] = useState(true);
	const hintTimeoutRef = useRef<NodeJS.Timeout>();
	const scrollIndicatorRef = useRef<HTMLDivElement>(null);
	const currentYear = new Date().getFullYear();


	// Intersection Observer için ref
	const iframeRef = useRef<HTMLIFrameElement>(null);

	const loginInfo = sessionStorage.getItem('login') as string;
	const jsonLoginInfo = JSON.parse(loginInfo || '{}') as LoginResponse;
	const adminLoginInfo = sessionStorage.getItem('adminLogin') as string;
	const jsonAdminLoginInfo = JSON.parse(adminLoginInfo || '{}') as AdminLoginResponseModel;


	const memoizedUseCases = useMemo(() => [
		{
			icon: <ViewInArIcon sx={{ fontSize: 48 }} className="text-blue-600" />,
			title: "Interactive 3D Viewer",
			description: "Experience your 3D models in real-time with our powerful viewer. Rotate, zoom, and explore from any angle.",
			features: ["Real-time rendering", "Smooth animations", "High performance"]
		},
		{
			icon: <CloudUploadIcon sx={{ fontSize: 48 }} className="text-blue-600" />,
			title: "Cloud Storage",
			description: "Store and manage your 3D assets securely in the cloud. Access them from anywhere, anytime.",
			features: ["Secure storage", "Easy access", "Version control"]
		},
		{
			icon: <GroupsIcon sx={{ fontSize: 48 }} className="text-blue-600" />,
			title: "Team Collaboration",
			description: "Work together seamlessly with your team. Share models, leave comments, and track changes.",
			features: ["Real-time comments", "Share links", "Team management"]
		}
	], []);

	const handleIframeLoad = useCallback(() => {
		setIsModelLoaded(true);
	}, []);

	// iframe'in src'sini memoize edelim
	const iframeSrc = useMemo(() => 
		`${baseUrl}objects/content/dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6c3luYy05MzE0L2hvbmRhLXN1cHJhLTEwMC1lbmdpbmUtMS5zbmFwc2hvdC42X0d1aWRfNTU5MzFmZWItYzk1NS00ZmMwLThkM2ItY2EyMmM1NDFkYmY5LnppcA/view/00dc8f6d-9174-4ee6-99e3-3b59d7ea58b9/properties/backgroundColor=fbfcfc&isAnimated=true&hideBottomToolBar=true&activeTools=viewcube,homeViewWrappe&lightPreset=1&showWatermark=false`,
	[baseUrl]);

	useEffect(() => {
		if (loginInfo !== null && (sessionStorage.getItem('b') === undefined || sessionStorage.getItem('b') === null)) {
			const createUserBucketService = new PostUserBucketService();
			createUserBucketService
				.PostAsync({
					uid: jsonLoginInfo.uid,
				})
				.then((res: { bucket: string }) => {
					sessionStorage.setItem('b', res.bucket);
				})
				.catch((error: Error) => {
					console.log(error);
				});
		}
	}, [loginInfo]);

	useEffect(() => {
		if (window.location.href.indexOf('?code') !== -1) {
			if (
				sessionStorage.getItem('clickOneDrive') !== null &&
				sessionStorage.getItem('clickOneDrive') === 'true'
			) {
				const code = window.location.href
					.split('/?')[1]
					.split('=')[1]
					.split('&')[0];

				const saveCodeService = new SaveCodeService();
				const b = sessionStorage.getItem('b') as string;
				saveCodeService
					.PostAsync({
						code,
						bucketName: b,
					})
					.then((data: { isSuccess: boolean }) => {
						navigate('');
						sessionStorage.setItem('clickOneDrive', 'false');
						sessionStorage.setItem('microsoft-auth', JSON.stringify(data));
					})
					.catch((error: Error) => {
						console.log(error);
					});
			} else if (
				sessionStorage.getItem('clickBox') !== null &&
				sessionStorage.getItem('clickBox') === 'true'
			) {
				const boxTokenService = new BoxPostTokenService();
				const code = window.location.href
					.split('/?')[1]
					.split('=')[1]
					.split('&')[0];
				const b = sessionStorage.getItem('b') as string;
				boxTokenService
					.PostAsync({
						code,
						bucketName: b,
					})
					.then((data: { isSuccess: boolean }) => {
						navigate('');
						sessionStorage.setItem('clickBox', 'false');
						sessionStorage.setItem('box-auth', JSON.stringify(data));
					})
					.catch((error: Error) => {
						console.log(error);
					});
			} else if (
				sessionStorage.getItem('clickAWS') !== null &&
				sessionStorage.getItem('clickAWS') === 'true'
			) {
				const awsTokenService = new AWSPostTokenService();
				const code = window.location.href
					.split('/?')[1]
					.split('=')[1]
					.split('&')[0];
				const b = sessionStorage.getItem('b') as string;
				awsTokenService
					.PostAsync({
						code,
						bucketName: b,
					})
					.then((data: { isSuccess: boolean }) => {
						const awsCreateBucketService = new CreateAWSBucketService();
						const bucketName = sessionStorage.getItem('b') as string;
						awsCreateBucketService
							.PostAsync({
								bucketName,
							})
							.then(() => {
								navigate('');
								sessionStorage.setItem('clickAWS', 'false');
								sessionStorage.setItem('aws-auth', JSON.stringify(data));
							})
							.catch((error: Error) => {
								console.log(error);
							});
					})
					.catch((error: Error) => {
						console.log(error);
					});
			} else {
				const googleTokenService = new PostGoogleTokenService();
				const code = window.location.href
					.split('/?')[1]
					.split('=')[1]
					.split('&')[0];
				const b = sessionStorage.getItem('b') as string;
				googleTokenService
					.PostAsync({
						code,
						bucketName: b,
					})
					.then((data: { isSuccess: boolean }) => {
						navigate('');
						sessionStorage.setItem('clickGoogleDrive', 'false');
						sessionStorage.setItem('google-auth', JSON.stringify(data));
					})
					.catch((error: Error) => {
						console.log(error);
					});
			}
		}
	}, []);

	useEffect(() => {
		// Model yüklendiğinde ve kullanıcı görünce çalışacak observer
		const observer = new IntersectionObserver(
			(entries) => {
				const entry = entries[0];
				
				if (entry.isIntersecting && isModelLoaded && !showHint) {
					setShowHint(true);
					// 3 saniye sonra hint'i kaldır
					hintTimeoutRef.current = setTimeout(() => {
						setShowHint(false);
					}, 3000);
					// Gözlemi durdur - aynı oturumda tekrar gösterme
					observer.disconnect();
				}
			},
			{ threshold: 0.5 } // En az %50'si görünür olduğunda tetiklensin
		);

		if (iframeRef.current) {
			observer.observe(iframeRef.current);
		}

		return () => {
			observer.disconnect();
			if (hintTimeoutRef.current) {
				clearTimeout(hintTimeoutRef.current);
			}
		};
	}, [isModelLoaded]);

	useEffect(() => {
		const handleScroll = () => {
			// Hide indicator if scrolled even slightly
			if (window.scrollY > 50) {
				setShowScrollIndicator(false);
			} else {
				setShowScrollIndicator(true);
			}
		};

		window.addEventListener('scroll', handleScroll);
		return () => window.removeEventListener('scroll', handleScroll);
	}, []);

	const features = [
		{
			icon: <ViewInArIcon sx={{ fontSize: 40 }} />,
			title: "AR Experience",
			description: "Offer your customers the opportunity to experience and explore your products in the real world, bringing your catalog to life.",
			color: "from-blue-500 to-blue-600"
		},
		{
			icon: <DiamondIcon sx={{ fontSize: 40 }} />,
			title: "File Formats",
			description: "VEEWER supports over 70 CAD model formats, instantly converting your files into web-viewable formats and making sharing effortless.",
			color: "from-purple-500 to-purple-600"
		},
		{
			icon: <SecurityIcon sx={{ fontSize: 40 }} />,
			title: "Customizable Embed",
			description: "You're in full control: Customize and integrate the embed code exactly the way you need for your website or application.",
			color: "from-green-500 to-green-600"
		},
		{
			icon: <GroupsIcon sx={{ fontSize: 40 }} />,
			title: "Team Collaboration",
			description: "Share your models with your team members in a single organization account and make collaboration more efficient.",
			color: "from-orange-500 to-orange-600"
		}
	];

	const workflow = [
		{
			icon: <CloudUploadIcon sx={{ fontSize: 48 }} className="text-blue-600" />,
			title: "Upload",
			description: "Easily upload your 3D models to Veewer"
		},
		{
			icon: <ShareIcon sx={{ fontSize: 48 }} className="text-blue-600" />,
			title: "Share",
			description: "Share your content with a simple link"
		},
		{
			icon: <SearchIcon sx={{ fontSize: 48 }} className="text-blue-600" />,
			title: "Review",
			description: "View your 3D models in browser or mobile"
		}
	];

	const handleGetStarted = useCallback(() => {
		navigate('/login?tab=signup');
	}, [navigate]);

	const handleContactSales = useCallback(() => {
		window.location.href = 'mailto:contact@veewer.com';
	}, []);

	const supportedFormats = [
		'dwg', 'dxf', 'dgn', 'dwf', 'dwfx', 'dwt', 'idw', 'vue', 'wire',
		'obj', 'fbx', 'gltf', 'glb', 'stl', 'stla', 'stlb', '3dm', '3ds', 'max',
		'step', 'stp', 'stpz', 'iges', 'igs', 'ige', 'prt', 'asm', 'par',
		'f3d', 'rvt', 'rvm', 'ipt', 'iam', 'nwc', 'nwd',
		'catpart', 'catproduct', 'cgr', 'sldasm', 'sldprt', 'smb', 'smt',
		'jt', 'sat', 'sab', 'x_b', 'x_t', 'xas', 'xpr', 'brd', 'sch', 'fsch', 'gbxml',
		'collaboration', 'session', 'model', 'pmlprj', 'pmlprjz', 'psmodel', 'emodel',
		'dae', 'ddx', 'ddz', 'dgk', 'dlv3', 'dmt', 'exp', 'mpf', 'msr', 'neu', 'new', 'osb', 'skp', 'ste'
	].map((format: string) => format.toLowerCase());

	const [isFormatModalOpen, setIsFormatModalOpen] = useState(false);
	const [fileFormat, setFileFormat] = useState('');
	const [checkResult, setCheckResult] = useState<'success' | 'error' | null>(null);
	const [showConfetti, setShowConfetti] = useState(false);
	const [isDemoModalOpen, setIsDemoModalOpen] = useState(false);  // Bu satırı ekleyelim

	const handleCloseModal = () => {
		setIsFormatModalOpen(false);
		setFileFormat('');
		setCheckResult(null);
		setShowConfetti(false);
	};

	const handleFormatCheck = () => {
		const format = fileFormat.toLowerCase().trim().replace('.', '');
		const isSupported = supportedFormats.some(
			supportedFormat => supportedFormat.toLowerCase() === format
		);
		
		if (isSupported) {
			setCheckResult('success');
			setShowConfetti(true);
		} else {
			setCheckResult('error');
		}
	};

	// Add new state for formats modal
	const [showFormatsModal, setShowFormatsModal] = useState(false);

	// Kullanıcının giriş yapıp yapmadığını kontrol et
	const isAuthenticated = () => {
		const loginInfo = sessionStorage.getItem('login');
		const adminLoginInfo = sessionStorage.getItem('adminLogin');
		
		if (!loginInfo && !adminLoginInfo) return false;
		
		try {
			const jsonLoginInfo = loginInfo ? JSON.parse(loginInfo) : null;
			const jsonAdminLoginInfo = adminLoginInfo ? JSON.parse(adminLoginInfo) : null;
			
			return (jsonLoginInfo?.isAuthenticated || jsonAdminLoginInfo?.isAuthenticated) || false;
		} catch {
			return false;
		}
	};

	// Tüm "Start using for free" butonları için ortak bir render fonksiyonu
	const renderStartButton = () => {
		if (isAuthenticated()) return null;
		
		return (
			<motion.button
				whileHover={{ scale: 1.02 }}
				whileTap={{ scale: 0.98 }}
				onClick={handleGetStarted}
				className="bg-gradient-to-r from-[#16254c] to-[#1e3a8a] text-white px-8 py-3 rounded-xl hover:opacity-90 transition-all shadow-xl text-base sm:text-lg font-semibold cursor-pointer"
			>
				Start using for free
				<span className="text-base sm:text-lg ml-2">→</span>
			</motion.button>
		);
	};

	const renderWhiteStartButton = () => {
		if (isAuthenticated()) return null;
		
		return (
			<motion.button
				whileHover={{ scale: 1.02 }}
				whileTap={{ scale: 0.98 }}
				onClick={handleGetStarted}
				className="bg-white text-[#16254c] px-8 py-3 rounded-xl hover:opacity-90 transition-all shadow-xl text-base sm:text-lg font-semibold cursor-pointer"
			>
				Start using for free
				<span className="text-base sm:text-lg ml-2">→</span>
			</motion.button>
		);
	};

	// Footer'a prop olarak gönderelim
	const handleOpenDemoModal = () => setIsDemoModalOpen(true);

	return adminLoginInfo && jsonAdminLoginInfo.isAuthenticated ? (
		<AdminHome />
	) : (
		<div className="min-h-screen overflow-x-hidden">
			{/* Hero Section */}
			<section className="min-h-[calc(100vh-80px)] relative bg-[#fbfcfc]">
				<div className="absolute inset-0 bg-grid-pattern opacity-5" />
				<div className="container mx-auto px-4 sm:px-6 lg:px-8 py-8 md:py-12 min-h-full">
					<div className="grid grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-16 min-h-full items-center">
						{/* Sol taraf - Logo ve Yazılar */}
						<motion.div 
							initial={{ opacity: 0, x: -30 }}
							animate={{ opacity: 1, x: 0 }}
							transition={{ duration: 1, ease: "easeOut" }}
							className="flex flex-col justify-center w-full max-w-2xl mx-auto lg:mx-0 pt-4 lg:pt-0"
						>
							<motion.div 
								initial={{ opacity: 0, y: 20 }}
								animate={{ opacity: 1, y: 0 }}
								transition={{ delay: 0.4 }}
								className="flex flex-col items-center xl:!items-start"
							>
								{/* Main heading container */}
								<div className="flex flex-col mb-6 sm:mb-8">
									<div className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl text-gray-900 tracking-tight leading-[1.15] font-light">
										<div className="text-left xl:!text-left">Get people talking</div>
										<div className="text-center xl:!text-left">about your product</div>
										<div className="flex items-center gap-2 justify-center xl:!justify-start">
											with 
											<img 
												src={VeewerLogo} 
												alt="Veewer" 
												className="h-8 sm:h-10 md:h-12 lg:h-14 xl:h-14 2xl:h-16 translate-y-[2px] lg:translate-y-[4px] xl:translate-y-[6px] object-contain"
											/>
										</div>
									</div>
								</div>

								{/* Subtitle and CTA */}
								<div className="flex flex-col items-center xl:!items-start space-y-6 sm:space-y-8 w-full sm:max-w-lg relative z-30">
									<p className="text-left text-base sm:text-lg text-gray-600 xl:!text-left">
										Upload, share, and showcase your interactive 3D models. Let your audience explore your designs from every angle.
									</p>
									{renderStartButton()} {/* Hero section için normal buton */}
								</div>
							</motion.div>
						</motion.div>

						{/* Sağ taraf - iframe */}
						<motion.div
							initial={{ opacity: 0, x: 30 }}
							animate={{ opacity: 1, x: 0 }}
							transition={{ duration: 1, ease: "easeOut" }}
							className="relative w-full h-[250px] sm:h-[350px] md:h-[450px] lg:h-[550px] bg-gradient-to-br from-blue-50 to-gray-50 group rounded-3xl"
						>
							{/* Hover Overlay */}
							<div className="absolute inset-0 bg-black/40 opacity-0 group-hover:opacity-100 transition-opacity duration-300 flex items-center justify-center z-20 pointer-events-none rounded-3xl">
								<div className="text-white text-center transform translate-y-4 group-hover:translate-y-0 transition-transform duration-300">
									<div className="text-4xl mb-2">👆</div>
									<p className="text-lg font-medium">Click to interact with the model</p>
								</div>
							</div>
							
							<iframe 
								ref={iframeRef}
								src={iframeSrc} 
								title="Veewer Demo - Blower" 
								className="w-full h-full rounded-3xl relative z-10"
								loading="eager"
								onLoad={handleIframeLoad}
							/>
							
							{/* Interactive Hint Overlay */}
							{showHint && (
								<motion.div
									initial={{ opacity: 0 }}
									animate={{ opacity: 1 }}
									exit={{ opacity: 0 }}
									transition={{ duration: 0.5 }}
									className="absolute inset-0 flex items-center justify-center pointer-events-none z-20"
								>
									<motion.div
										initial={{ scale: 0.9, opacity: 0 }}
										animate={{ scale: 1, opacity: 1 }}
										exit={{ scale: 0.9, opacity: 0 }}
										transition={{ duration: 0.5 }}
										className="bg-black/70 backdrop-blur-sm rounded-xl p-4 lg:p-8 text-white text-center max-w-md mx-4 border border-white/10"
									>
										<motion.div 
											animate={{ rotate: [0, -10, 10, -10, 0] }}
											transition={{ duration: 2 }}
											className="text-2xl lg:text-4xl mb-4 flex justify-center gap-6"
										>
											<span>🖱️</span>
											<span>🔄</span>
											<span>🔍</span>
										</motion.div>
										<p className="text-lg lg:text-xl font-medium mb-2 lg:mb-3">Interact with the 3D Model!</p>
										<p className="text-sm lg:text-base opacity-90">
											• Drag to rotate<br/>
											• Scroll to zoom in/out<br/>
											• Right-click to pan
										</p>
									</motion.div>
								</motion.div>
							)}
						</motion.div>
					</div>
				</div>
			</section>

			{/* Interactive Demo Section */}
			<section className="py-32 bg-gradient-to-br from-[#16254c] via-[#1e3a8a] to-[#16254c] relative overflow-hidden">
				{/* Animated background dots */}
				<div className="absolute inset-0 bg-grid-pattern opacity-5 animate-pulse"></div>
				
				<div className="container mx-auto px-4 relative z-10">
					<motion.div
						initial={{ opacity: 0, y: 20 }}
						whileInView={{ opacity: 1, y: 0 }}
						transition={{ duration: 0.8 }}
						className="text-center mb-20"
					>
						<h2 className="text-4xl font-bold mb-4 text-white">How to Create Your Own 3D Catalogues?</h2>
						<p className="text-xl text-blue-200">
							Three simple steps to showcase your products in 3D
						</p>
					</motion.div>

					<div className="grid md:grid-cols-3 gap-12 max-w-6xl mx-auto">
						{/* Upload */}
						<motion.div
							initial={{ opacity: 0, y: 20 }}
							whileInView={{ opacity: 1, y: 0 }}
							transition={{ delay: 0.2 }}
							whileHover={{ y: -10, scale: 1.02 }}
							className="relative bg-gradient-to-br from-white/10 to-white/5 backdrop-blur-lg p-8 rounded-2xl border border-white/10 shadow-2xl group"
						>
							<div className="absolute -top-6 -left-6 w-12 h-12 bg-gradient-to-br from-blue-500 to-blue-600 text-white rounded-xl flex items-center justify-center text-2xl font-bold shadow-lg">
								1
							</div>
							<div className="mb-6 text-blue-400 transform group-hover:scale-110 transition-transform duration-300">
								<CloudUploadIcon sx={{ fontSize: 48 }} />
							</div>
							<h3 className="text-2xl font-bold mb-4 text-white">Upload</h3>
							<p className="text-blue-200 leading-relaxed">
								Upload your 3D model to our platform.
							</p>
						</motion.div>

						{/* Generate */}
						<motion.div
							initial={{ opacity: 0, y: 20 }}
							whileInView={{ opacity: 1, y: 0 }}
							transition={{ delay: 0.4 }}
							whileHover={{ y: -10, scale: 1.02 }}
							className="relative bg-gradient-to-br from-white/10 to-white/5 backdrop-blur-lg p-8 rounded-2xl border border-white/10 shadow-2xl group"
						>
							<div className="absolute -top-6 -left-6 w-12 h-12 bg-gradient-to-br from-blue-500 to-blue-600 text-white rounded-xl flex items-center justify-center text-2xl font-bold shadow-lg">
								2
							</div>
							<div className="mb-6 text-blue-400 transform group-hover:scale-110 transition-transform duration-300">
								<ViewInArIcon sx={{ fontSize: 48 }} />
							</div>
							<h3 className="text-2xl font-bold mb-4 text-white">Generate</h3>
							<p className="text-blue-200 leading-relaxed">
								Generate the embed code for your model.
							</p>
						</motion.div>

						{/* Embed */}
						<motion.div
							initial={{ opacity: 0, y: 20 }}
							whileInView={{ opacity: 1, y: 0 }}
							transition={{ delay: 0.6 }}
							whileHover={{ y: -10, scale: 1.02 }}
							className="relative bg-gradient-to-br from-white/10 to-white/5 backdrop-blur-lg p-8 rounded-2xl border border-white/10 shadow-2xl group"
						>
							<div className="absolute -top-6 -left-6 w-12 h-12 bg-gradient-to-br from-blue-500 to-blue-600 text-white rounded-xl flex items-center justify-center text-2xl font-bold shadow-lg">
								3
							</div>
							<div className="mb-6 text-blue-400 transform group-hover:scale-110 transition-transform duration-300">
								<ShareIcon sx={{ fontSize: 48 }} />
							</div>
							<h3 className="text-2xl font-bold mb-4 text-white">Embed</h3>
							<p className="text-blue-200 leading-relaxed">
								Embed the 3D model on your webpage.
							</p>
						</motion.div>
					</div>
				</div>
				<div className="mt-16 text-center relative z-20">  {/* z-index ve relative ekledik */}
					{renderStartButton()}
				</div>
			</section>

			{/* Use Cases Section - Paralaks efekti */}
			<section className="py-32 relative bg-gradient-to-br from-gray-50 to-white overflow-hidden">
				{/* Decorative elements */}
				<div className="absolute inset-0 bg-grid-pattern opacity-5"></div>
				<div className="absolute top-0 right-0 w-1/3 h-1/3 bg-gradient-to-br from-[#16254c]/10 to-transparent rounded-full blur-3xl"></div>
				<div className="absolute bottom-0 left-0 w-1/3 h-1/3 bg-gradient-to-tr from-[#1e3a8a]/10 to-transparent rounded-full blur-3xl"></div>

				<div className="container mx-auto px-4 relative z-10">
					<motion.div 
						initial={{ opacity: 0, y: 20 }}
						whileInView={{ opacity: 1, y: 0 }}
						transition={{ duration: 0.8 }}
						className="text-center mb-20"
					>
						<span className="inline-block px-6 py-2 bg-[#16254c]/5 text-[#16254c] rounded-full text-sm font-semibold mb-6">
							BUSINESS IMPACT
						</span>
						<h2 className="text-4xl md:text-5xl font-bold mb-6 bg-gradient-to-r from-[#16254c] to-[#1e3a8a] text-transparent bg-clip-text">
							Take Your Business to the Next Level!
						</h2>
					</motion.div>

					<div className="grid md:grid-cols-2 gap-8 max-w-6xl mx-auto">
						{/* Customer Experience */}
						<motion.div
							initial={{ opacity: 0, x: -30 }}
							whileInView={{ opacity: 1, x: 0 }}
							transition={{ delay: 0.2 }}
							viewport={{ once: true }}
							className="group relative"
						>
							<div className="relative bg-white p-10 rounded-2xl shadow-lg hover:shadow-xl transition-all duration-300 border border-gray-100 overflow-hidden">
								<div className="absolute top-0 right-0 w-32 h-32 bg-gradient-to-br from-[#16254c]/10 to-[#1e3a8a]/10 rounded-full blur-2xl"></div>
								
								<div className="relative">
									<div className="flex items-center gap-4 mb-6">
										<div className="p-3 bg-[#16254c]/5 rounded-xl">
											<SearchIcon sx={{ fontSize: 32 }} className="text-[#16254c]" />
										</div>
										<h3 className="text-2xl font-bold text-gray-900">Customer Experience</h3>
									</div>
									<p className="text-gray-600 leading-relaxed">
										Realistic and interactive 3D visuals allow customers to examine products in detail, 
										building trust and simplifying purchase decisions.
									</p>
								</div>
							</div>
						</motion.div>

						{/* Sales Growth */}
						<motion.div
							initial={{ opacity: 0, x: 30 }}
							whileInView={{ opacity: 1, x: 0 }}
							transition={{ delay: 0.3 }}
							viewport={{ once: true }}
							className="group relative"
						>
							<div className="relative bg-white p-10 rounded-2xl shadow-lg hover:shadow-xl transition-all duration-300 border border-gray-100 overflow-hidden">
								<div className="absolute top-0 right-0 w-32 h-32 bg-gradient-to-br from-[#16254c]/10 to-[#1e3a8a]/10 rounded-full blur-2xl"></div>
								
								<div className="relative">
									<div className="flex items-center gap-4 mb-6">
										<div className="p-3 bg-[#16254c]/5 rounded-xl">
											<DiamondIcon sx={{ fontSize: 32 }} className="text-[#16254c]" />
										</div>
										<h3 className="text-2xl font-bold text-gray-900">Sales Growth</h3>
									</div>
									<p className="text-gray-600 leading-relaxed">
										Reduces returns and increases revenue through more upselling and 
										cross-selling opportunities.
									</p>
								</div>
							</div>
						</motion.div>

						{/* Cost Efficiency */}
						<motion.div
							initial={{ opacity: 0, x: -30 }}
							whileInView={{ opacity: 1, x: 0 }}
							transition={{ delay: 0.4 }}
							viewport={{ once: true }}
							className="group relative"
						>
							<div className="relative bg-white p-10 rounded-2xl shadow-lg hover:shadow-xl transition-all duration-300 border border-gray-100 overflow-hidden">
								<div className="absolute top-0 right-0 w-32 h-32 bg-gradient-to-br from-[#16254c]/10 to-[#1e3a8a]/10 rounded-full blur-2xl"></div>
								
								<div className="relative">
									<div className="flex items-center gap-4 mb-6">
										<div className="p-3 bg-[#16254c]/5 rounded-xl">
											<CloudUploadIcon sx={{ fontSize: 32 }} className="text-[#16254c]" />
										</div>
										<h3 className="text-2xl font-bold text-gray-900">Cost Efficiency</h3>
									</div>
									<p className="text-gray-600 leading-relaxed">
										Digital models eliminate prototyping costs while enabling you to present 
										your products to customers worldwide quickly and effectively.
									</p>
								</div>
							</div>
						</motion.div>

						{/* Competitive Advantage */}
						<motion.div
							initial={{ opacity: 0, x: 30 }}
							whileInView={{ opacity: 1, x: 0 }}
							transition={{ delay: 0.5 }}
							viewport={{ once: true }}
							className="group relative"
						>
							<div className="relative bg-white p-10 rounded-2xl shadow-lg hover:shadow-xl transition-all duration-300 border border-gray-100 overflow-hidden">
								<div className="absolute top-0 right-0 w-32 h-32 bg-gradient-to-br from-[#16254c]/10 to-[#1e3a8a]/10 rounded-full blur-2xl"></div>
								
								<div className="relative">
									<div className="flex items-center gap-4 mb-6">
										<div className="p-3 bg-[#16254c]/5 rounded-xl">
											<ViewInArIcon sx={{ fontSize: 32 }} className="text-[#16254c]" />
										</div>
										<h3 className="text-2xl font-bold text-gray-900">Competitive Advantage</h3>
									</div>
									<p className="text-gray-600 leading-relaxed">
										Innovative technology highlights your brand as modern and helps you 
										stand out in the industry.
									</p>
								</div>
							</div>
						</motion.div>
					</div>
				</div>
				<div className="mt-16 text-center relative z-20">  {/* z-index ve relative ekledik */}
					{renderStartButton()}
				</div>
			</section>

			{/* Workflow Section - Yeni tasarım */}
			<section className="py-32 bg-gradient-to-br from-gray-900 to-blue-900 relative overflow-hidden">
				<div className="absolute inset-0 bg-grid-pattern opacity-5"></div>
				
				<div className="container mx-auto px-4 relative z-10">
					<motion.div
						initial={{ opacity: 0, y: 20 }}
						whileInView={{ opacity: 1, y: 0 }}
						transition={{ duration: 0.8 }}
						className="text-center mb-20"
					>
						<span className="text-blue-400 font-semibold text-lg mb-4 block">USE CASES</span>
						<h2 className="text-4xl font-bold mb-4 text-white">Here are 3 key scenarios to showcase VEEWER to your customers</h2>
					</motion.div>

					<div className="max-w-7xl mx-auto space-y-24">
						{/* Products Page */}
						<motion.div
							initial={{ opacity: 0, x: -50 }}
							whileInView={{ opacity: 1, x: 0 }}
							transition={{ duration: 0.8 }}
							className="relative group"
						>
							<div onClick={() => navigate('/products')} className="grid lg:grid-cols-2 cursor-pointer gap-8 items-center">
								<div className="order-2 lg:order-1 p-8">
									<div className="relative">
										<span className="text-blue-400 text-sm font-semibold tracking-wider">01 — PRODUCTS PAGE</span>
										<h3 className="text-3xl font-bold text-white mt-2 mb-4">Interactive Product Showcase</h3>
										<p className="text-blue-100 text-lg leading-relaxed mb-6">
											Create an engaging digital showroom where customers can discover and interact with your products in 3D. Transform your catalog into an immersive experience.
										</p>
										<ul className="space-y-3">
											{["360° Product Views", "Real-time Interaction", "Seamless Integration"].map((feature, index) => (
												<li key={index} className="flex items-center text-blue-200">
													<svg className="w-5 h-5 mr-2 text-blue-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
														<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
													</svg>
													{feature}
												</li>
											))}
										</ul>
									</div>
								</div>
								<div onClick={() => navigate('/products')} className="order-1 lg:order-2 cursor-pointer">
									<div className="relative group">
										<div className="absolute -inset-1 bg-gradient-to-r from-blue-500 to-purple-500 rounded-2xl blur opacity-50 group-hover:opacity-75 transition duration-500"></div>
										<div className="relative aspect-[16/9] overflow-hidden rounded-2xl">
											<img 
												src={ProductsPage} 
												alt="Products Page" 
												className="w-full h-full object-contain transform group-hover:scale-105 transition duration-700"
											/>
										</div>
									</div>
								</div>
							</div>
						</motion.div>

						{/* Product Details */}
						<motion.div
							initial={{ opacity: 0, x: 50 }}
							whileInView={{ opacity: 1, x: 0 }}
							transition={{ duration: 0.8 }}
							className="relative group"
						>
							<div onClick={() => navigate('/ekana-sofa')} className="grid lg:grid-cols-2 gap-8 items-center cursor-pointer">
								<div className="lg:order-2 p-8">
									<div className="relative">
										<span className="text-blue-400 text-sm font-semibold tracking-wider">02 — PRODUCT DETAILS</span>
										<h3 className="text-3xl font-bold text-white mt-2 mb-4">Detailed 3D Exploration</h3>
										<p className="text-blue-100 text-lg leading-relaxed mb-6">
											Give customers the power to examine products in detail. From dimensions to materials, every aspect becomes interactive and engaging.
										</p>
										<ul className="space-y-3">
											{["Precise Measurements", "Material Inspection", "Interactive Features"].map((feature, index) => (
												<li key={index} className="flex items-center text-blue-200">
													<svg className="w-5 h-5 mr-2 text-blue-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
														<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
													</svg>
													{feature}
												</li>
											))}
										</ul>
									</div>
								</div>
								<div onClick={() => navigate('/ekana-sofa')} className="lg:order-1 cursor-pointer">
									<div className="relative group">
										<div className="absolute -inset-1 bg-gradient-to-r from-purple-500 to-pink-500 rounded-2xl blur opacity-50 group-hover:opacity-75 transition duration-500"></div>
										<div className="relative aspect-[16/9] overflow-hidden rounded-2xl">
											<img 
												src={ProductDetails} 
												alt="Product Details" 
												className="w-full h-full object-contain transform group-hover:scale-105 transition duration-700"
											/>
										</div>
									</div>
								</div>
							</div>
						</motion.div>

						{/* Configurator */}
						<motion.div
							initial={{ opacity: 0, x: -50 }}
							whileInView={{ opacity: 1, x: 0 }}
							transition={{ duration: 0.8 }}
							className="relative group"
						>
							<div onClick={() => navigate('/configurator')} className="grid lg:grid-cols-2 gap-8 items-center cursor-pointer">
								<div className="order-2 lg:order-1 p-8">
									<div className="relative">
										<span className="text-blue-400 text-sm font-semibold tracking-wider">03 — CONFIGURATOR</span>
										<h3 className="text-3xl font-bold text-white mt-2 mb-4">Custom Product Builder</h3>
										<p className="text-blue-100 text-lg leading-relaxed mb-6">
											Enable real-time product customization. Let customers visualize their choices instantly with our powerful configurator.
										</p>
										<ul className="space-y-3">
											{["Real-time Updates", "Color & Material Options", "Instant Visualization"].map((feature, index) => (
												<li key={index} className="flex items-center text-blue-200">
													<svg className="w-5 h-5 mr-2 text-blue-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
														<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
													</svg>
													{feature}
												</li>
											))}
										</ul>
									</div>
								</div>
								<div onClick={() => navigate('/configurator')} className="order-1 lg:order-2 cursor-pointer">
									<div className="relative group">
										<div className="absolute -inset-1 bg-gradient-to-r from-pink-500 to-orange-500 rounded-2xl blur opacity-50 group-hover:opacity-75 transition duration-500"></div>
										<div className="relative aspect-[16/9] overflow-hidden rounded-2xl">
											<img 
												src={Configurator} 
												alt="Configurator" 
												className="w-full h-full object-contain transform group-hover:scale-105 transition duration-700"
											/>
										</div>
									</div>
								</div>
							</div>
						</motion.div>
					</div>
				</div>
				<div className="mt-16 text-center relative z-20">  {/* z-index ve relative ekledik */}
					{renderWhiteStartButton()}
				</div>
			</section>

			{/* Features Section */}
			<section className="py-32 bg-gradient-to-br from-white to-gray-50 relative overflow-hidden">
				{/* Subtle grid background */}
				<div className="absolute inset-0">
					<div className="absolute inset-0 bg-[linear-gradient(to_right,#00000008_1px,transparent_1px),linear-gradient(to_bottom,#00000008_1px,transparent_1px)] bg-[size:4rem_4rem]"></div>
				</div>
				
				<div className="container mx-auto px-4 relative z-10">
					<motion.div
						initial={{ opacity: 0, y: 20 }}
						whileInView={{ opacity: 1, y: 0 }}
						transition={{ duration: 0.8 }}
						className="text-center mb-20"
					>
						<motion.span 
							initial={{ opacity: 0, y: 20 }}
							whileInView={{ opacity: 1, y: 0 }}
							transition={{ delay: 0.2 }}
							className="inline-block px-6 py-2 bg-gradient-to-r from-blue-500/10 to-purple-500/10 text-blue-600 rounded-full text-sm font-semibold mb-6"
						>
							FEATURES
						</motion.span>
						<motion.h2 
							initial={{ opacity: 0, y: 20 }}
							whileInView={{ opacity: 1, y: 0 }}
							transition={{ delay: 0.4 }}
							className="text-4xl md:text-5xl font-bold mb-6 text-gray-900"
						>
							Everything you need to showcase your products
						</motion.h2>
					</motion.div>

					<div className="grid lg:grid-cols-4 md:grid-cols-2 gap-8 max-w-7xl mx-auto">
						{[
							{
								icon: <div className="relative">
									<div className="absolute -inset-2 bg-blue-500/20 rounded-full blur-lg group-hover:blur-xl transition-all duration-500"></div>
									<div className="relative bg-gradient-to-br from-blue-500 to-blue-600 p-4 rounded-2xl">
										<ViewInArIcon sx={{ fontSize: 40 }} className="text-white" />
									</div>
								</div>,
								title: "AR",
								description: "Offer your customers the opportunity to experience and explore your products in the real world.",
								color: "from-blue-500 to-blue-600",
								gradient: "from-blue-500/5 to-blue-600/5",
								shadow: "group-hover:shadow-blue-200"
							},
							{
								icon: <div className="relative">
									<div className="absolute -inset-2 bg-purple-500/20 rounded-full blur-lg group-hover:blur-xl transition-all duration-500"></div>
									<div className="relative bg-gradient-to-br from-purple-500 to-purple-600 p-4 rounded-2xl">
										<svg className="w-10 h-10 text-white" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M13 2H6C5.46957 2 4.96086 2.21071 4.58579 2.58579C4.21071 2.96086 4 3.46957 4 4V20C4 20.5304 4.21071 21.0391 4.58579 21.4142C4.96086 21.7893 5.46957 22 6 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V9L13 2Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
											<path d="M13 2V9H20" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
										</svg>
									</div>
								</div>,
								title: "File Formats",
								description: <>
									VEEWER supports over 70 CAD model formats.
									<br/>
									<span className='text-[10px]'>									
Object enabler viewing support is only available for AutoCAD® Architecture, AutoCAD® Plant 3D, and AutoCAD® Civil 3D.<br/>
Files from Revit® 2015 or later versions. </span>
									<button 
										onClick={() => setIsFormatModalOpen(true)}
										className="mt-2 text-purple-500 hover:text-purple-600 font-semibold transition-colors block"
									>
										Try your format! ✨
									</button>
								</>,
								color: "from-purple-500 to-purple-600",
								gradient: "from-purple-500/5 to-purple-600/5",
								shadow: "group-hover:shadow-purple-200"
							},
							{
								icon: <div className="relative">
									<div className="absolute -inset-2 bg-green-500/20 rounded-full blur-lg group-hover:blur-xl transition-all duration-500"></div>
									<div className="relative bg-gradient-to-br from-green-500 to-green-600 p-4 rounded-2xl">
										<svg className="w-10 h-10 text-white" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M16 8L8 16M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
											<path d="M8 8L16 16" stroke="currentColor" strokeWidth="2" strokeLinecap="round"/>
										</svg>
									</div>
								</div>,
								title: "Customizable Embed",
								description: "You're in full control: Customize and integrate the embed code exactly the way you need.",
								color: "from-green-500 to-green-600",
								gradient: "from-green-500/5 to-green-600/5",
								shadow: "group-hover:shadow-green-200"
							},
							{
								icon: <div className="relative">
									<div className="absolute -inset-2 bg-orange-500/20 rounded-full blur-lg group-hover:blur-xl transition-all duration-500"></div>
									<div className="relative bg-gradient-to-br from-orange-500 to-orange-600 p-4 rounded-2xl">
										<svg className="w-10 h-10 text-white" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M17 21V19C17 17.9391 16.5786 16.9217 15.8284 16.1716C15.0783 15.4214 14.0609 15 13 15H5C3.93913 15 2.92172 15.4214 2.17157 16.1716C1.42143 16.9217 1 17.9391 1 19V21M23 21V19C22.9993 18.1137 22.7044 17.2528 22.1614 16.5523C21.6184 15.8519 20.8581 15.3516 20 15.13M16 3.13C16.8604 3.3503 17.623 3.8507 18.1676 4.55231C18.7122 5.25392 19.0078 6.11683 19.0078 7.005C19.0078 7.89317 18.7122 8.75608 18.1676 9.45769C17.623 10.1593 16.8604 10.6597 16 10.88M13 7C13 9.20914 11.2091 11 9 11C6.79086 11 5 9.20914 5 7C5 4.79086 6.79086 3 9 3C11.2091 3 13 4.79086 13 7Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
										</svg>
									</div>
								</div>,
								title: "Teams",
								description: "Share your models with your team members in a single organization account and make collaboration more efficient.",
								color: "from-orange-500 to-orange-600",
								gradient: "from-orange-500/5 to-orange-600/5",
								shadow: "group-hover:shadow-orange-200"
							}
						].map((feature, index) => (
							<motion.div
								key={index}
								initial={{ opacity: 0, y: 30 }}
								whileInView={{ opacity: 1, y: 0 }}
								transition={{ delay: index * 0.1 }}
								viewport={{ once: true }}
								className="relative group"
							>
								{/* Main card */}
								<div className={`relative bg-white p-8 rounded-2xl shadow-lg transition-all duration-500 group-hover:-translate-y-2 group-hover:shadow-xl ${feature.shadow} border border-gray-100 hover:border-gray-200`}>
									{/* Background gradient blob */}
									<div className={`absolute top-0 right-0 w-32 h-32 bg-gradient-to-br ${feature.gradient} rounded-full blur-2xl transition-all duration-500 group-hover:scale-150`}></div>
									
									{/* Content */}
									<div className="relative z-10 flex flex-col items-center text-center">
										{/* Icon */}
										<div className="mb-6 transform group-hover:scale-110 transition-all duration-500">
											{feature.icon}
										</div>
										
										{/* Title */}
										<h3 className="text-xl font-bold text-gray-800 mb-4">
											{feature.title}
										</h3>

										{/* Description */}
										<p className="text-gray-600 leading-relaxed">
											{feature.description}
										</p>
									</div>
								</div>
							</motion.div>
						))}
					</div>
				</div>
				<div className="mt-16 text-center relative z-20">  {/* z-index ve relative ekledik */}
					{renderStartButton()}
				</div>
			</section>

			{/* Advanced Features Section */}
			<section className="py-32 bg-gradient-to-br from-[#16254c] via-[#1e3a8a] to-[#16254c] relative overflow-hidden">
				{/* Animated background */}
				<div className="absolute inset-0">
					<div className="absolute inset-0 bg-[linear-gradient(to_right,#ffffff10_1px,transparent_1px),linear-gradient(to_bottom,#ffffff10_1px,transparent_1px)] bg-[size:4rem_4rem] [mask-image:radial-gradient(ellipse_60%_50%_at_50%_0%,#000_70%,transparent_110%)]"></div>
				</div>
				
				<div className="container mx-auto px-4 relative z-10">
					<div className="max-w-7xl mx-auto">
						{/* Header */}
						<motion.div
							initial={{ opacity: 0, y: 20 }}
							whileInView={{ opacity: 1, y: 0 }}
							transition={{ duration: 0.8 }}
							className="text-center mb-20"
						>
							<motion.span 
								initial={{ opacity: 0, y: 20 }}
								whileInView={{ opacity: 1, y: 0 }}
								transition={{ delay: 0.2 }}
								className="inline-block px-6 py-2 bg-white/10 text-blue-300 rounded-full text-sm font-semibold mb-6"
							>
								ADVANCED FEATURES
							</motion.span>
							<motion.h2 
								initial={{ opacity: 0, y: 20 }}
								whileInView={{ opacity: 1, y: 0 }}
								transition={{ delay: 0.4 }}
								className="text-4xl md:text-5xl font-bold mb-6 text-white"
							>
								Unmatched Features <br/>
								<span className="bg-gradient-to-r from-blue-400 via-purple-400 to-blue-400 text-transparent bg-clip-text">
									Limitless Experience
								</span>
							</motion.h2>
						</motion.div>

						{/* Features Grid */}
						<div className="grid lg:grid-cols-3 gap-8">
							{/* Unlimited File Size */}
							<motion.div
								initial={{ opacity: 0, y: 30 }}
								whileInView={{ opacity: 1, y: 0 }}
								transition={{ delay: 0.1 }}
								viewport={{ once: true }}
								className="group relative"
							>
								<div className="absolute inset-0.5 bg-gradient-to-br from-blue-500 to-purple-600 rounded-2xl blur opacity-50 group-hover:opacity-100 transition-all duration-500"></div>
								<div className="relative bg-[#16254c]/90 backdrop-blur-xl p-8 rounded-2xl border border-white/10 hover:border-white/20 transition-all duration-500">
									{/* Icon */}
									<div className="flex items-center gap-6 mb-8">
										<div className="relative">
											<div className="absolute -inset-2 bg-blue-500/20 rounded-full blur-lg group-hover:blur-xl transition-all duration-500"></div>
											<div className="relative bg-gradient-to-br from-blue-400 to-blue-500 p-4 rounded-xl">
												<svg className="w-8 h-8 text-white" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path d="M21 15V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V15M17 8L12 3M12 3L7 8M12 3V15" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
												</svg>
											</div>
										</div>
										<h3 className="text-xl font-bold text-white">Unlimited File Size</h3>
									</div>

									{/* Content */}
									<p className="text-blue-100/80">With every subscription plan, there is no limit to the file size you can upload. Even large and complex CAD files can be easily uploaded and processed seamlessly.</p>
								</div>
							</motion.div>

							{/* Cloud Integration */}
							<motion.div
								initial={{ opacity: 0, y: 30 }}
								whileInView={{ opacity: 1, y: 0 }}
								transition={{ delay: 0.2 }}
								viewport={{ once: true }}
								className="group relative"
							>
								<div className="absolute inset-0.5 bg-gradient-to-br from-purple-500 to-pink-600 rounded-2xl blur opacity-50 group-hover:opacity-100 transition-all duration-500"></div>
								<div className="relative bg-[#16254c]/90 backdrop-blur-xl p-8 rounded-2xl border border-white/10 hover:border-white/20 transition-all duration-500">
									{/* Icon */}
									<div className="flex items-center gap-6 mb-8">
										<div className="relative">
											<div className="absolute -inset-2 bg-purple-500/20 rounded-full blur-lg group-hover:blur-xl transition-all duration-500"></div>
											<div className="relative bg-gradient-to-br from-purple-400 to-purple-500 p-4 rounded-xl">
												<svg className="w-8 h-8 text-white" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path d="M20 16.2A5 5 0 0 0 18 7h-1.26A8 8 0 1 0 4 15.25M8 16L12 12M12 12L16 16M12 12V21" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
												</svg>
											</div>
										</div>
										<h3 className="text-xl font-bold text-white">Cloud Integration</h3>
									</div>

									{/* Content */}
									<p className="text-blue-100/80">Connect your OneDrive and Box cloud accounts to effortlessly upload your models directly from the cloud.</p>
								</div>
							</motion.div>

							{/* Free Trial */}
							<motion.div
								initial={{ opacity: 0, y: 30 }}
								whileInView={{ opacity: 1, y: 0 }}
								transition={{ delay: 0.3 }}
								viewport={{ once: true }}
								className="group relative"
							>
								<div className="absolute inset-0.5 bg-gradient-to-br from-pink-500 to-orange-600 rounded-2xl blur opacity-50 group-hover:opacity-100 transition-all duration-500"></div>
								<div className="relative bg-[#16254c]/90 backdrop-blur-xl p-8 rounded-2xl border border-white/10 hover:border-white/20 transition-all duration-500">
									{/* Icon */}
									<div className="flex items-center gap-6 mb-8">
										<div className="relative">
											<div className="absolute -inset-2 bg-pink-500/20 rounded-full blur-lg group-hover:blur-xl transition-all duration-500"></div>
											<div className="relative bg-gradient-to-br from-pink-400 to-pink-500 p-4 rounded-xl">
												<svg className="w-8 h-8 text-white" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path d="M12 8V12M12 16H12.01M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
												</svg>
											</div>
										</div>
										<h3 className="text-xl font-bold text-white">Life Time Free</h3>
									</div>

									{/* Content */}
									<p className="text-blue-100/80">All our users have the opportunity to experience every feature of the platform for free. AR, customizable embedded code, teams, and much more – try it all without restrictions.</p>
								</div>
							</motion.div>
						</div>
					</div>
				</div>
				<div className="mt-16 text-center relative z-20">  {/* z-index ve relative ekledik */}
					{renderWhiteStartButton()}
				</div>
			</section>

					{/* Book a Demo Section */}
					<div className="bg-gray-100">
									<div className="container mx-auto px-4 py-12">
										<div className="flex flex-col items-center text-center">
											<h2 className="text-3xl md:text-4xl font-bold mb-4 text-[#16254c]">Ready to Experience VEEWER?</h2>
											<p className="text-gray-600 mb-8 max-w-2xl">
												Schedule a personalized demo to see how VEEWER can transform your 3D visualization experience.
						</p>
						<motion.button
							onClick={handleOpenDemoModal}
							className="bg-gradient-to-r from-[#16254c] to-[#1e3a75] text-white px-8 py-3 rounded-xl 
							hover:from-[#1e3a75] hover:to-[#254692] transition-all duration-300 shadow-lg 
							hover:shadow-[#16254c]/25 text-lg font-medium flex items-center gap-2"
							whileHover={{ scale: 1.02 }}
							whileTap={{ scale: 0.98 }}
						>
							Book a Demo
							<svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
								<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
							</svg>
						</motion.button>
					</div>
				</div>
			</div>

			{/* Footer */}
			<Footer onDemoClick={handleOpenDemoModal} />

			{/* Scroll Indicator */}
			<motion.div 
				initial={{ opacity: 0 }}
				animate={{ 
					opacity: [0.3, 1, 0.3], 
					y: [0, 10, 0] 
				}}
				transition={{ 
					duration: 2,
					repeat: Infinity,
					repeatType: "loop"
				}}
				className="fixed bottom-8 left-1/2 transform -translate-x-1/2 flex-col items-center gap-2 text-blue-600 pointer-events-none"
				style={{ display: showScrollIndicator ? 'flex' : 'none' }}
				ref={scrollIndicatorRef}
			>
				<p className="text-sm font-medium hidden xl:block 2xl:block">Scroll to explore more</p>
				<svg 
					className="w-6 h-6 animate-bounce hidden xl:block 2xl:block" 
					fill="none" 
					stroke="currentColor" 
					viewBox="0 0 24 24"
				>
					<path 
						strokeLinecap="round" 
						strokeLinejoin="round" 
						strokeWidth={2} 
						d="M19 14l-7 7m0 0l-7-7m7 7V3"
					/>
				</svg>
			</motion.div>

			{/* File Format Check Modal */}
			<AnimatePresence>
				{isFormatModalOpen && (
					<motion.div
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						exit={{ opacity: 0 }}
						className="fixed inset-0 bg-black/50 backdrop-blur-sm z-50 flex items-center justify-center p-4"
						onClick={handleCloseModal}
					>
						<motion.div
							initial={{ scale: 0.95, opacity: 0 }}
							animate={{ scale: 1, opacity: 1 }}
							exit={{ scale: 0.95, opacity: 0 }}
							onClick={(e) => e.stopPropagation()}
							className="bg-[#0a0a1f] rounded-2xl p-6 max-w-md w-full relative overflow-hidden border border-purple-500/20"
						>
							{/* Animated Background */}
							<div className="absolute inset-0 bg-grid-pattern opacity-5" />
							<div className="absolute top-0 left-0 w-full h-full">
								<div className="absolute top-0 left-0 w-64 h-64 bg-purple-500 rounded-full mix-blend-multiply filter blur-xl opacity-10 animate-blob" />
								<div className="absolute top-0 right-0 w-64 h-64 bg-blue-500 rounded-full mix-blend-multiply filter blur-xl opacity-10 animate-blob animation-delay-2000" />
							</div>

							{/* Close Button */}
							<button
								onClick={handleCloseModal}
								className="absolute top-3 right-3 text-gray-400 hover:text-white transition-colors z-10"
								aria-label="Close"
							>
								<svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
									<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
								</svg>
							</button>

							{/* Modal Content */}
							<div className="relative z-10">
								<div className="text-center mb-6">
									<motion.div
										initial={{ y: -20 }}
										animate={{ y: 0 }}
										className="text-4xl mb-3">🎮</motion.div>
									<h3 className="text-2xl font-bold text-white mb-2">Let's Play!</h3>
									<p className="text-gray-400 text-sm">Type your file format to check if we can handle it</p>
								</div>

								<div className="space-y-6">
									{/* Input Field */}
									<div className="relative h-12">
										<input
											type="text"
											value={fileFormat}
											onChange={(e) => setFileFormat(e.target.value)}
											placeholder="e.g., obj, fbx, step"
											className="w-full h-full px-4 rounded-xl bg-white/5 border-2 border-gray-700 focus:border-purple-500 outline-none transition-colors text-white placeholder-gray-500 pr-[130px] text-sm"
										/>
										<motion.button
											whileHover={{ scale: 1.05 }}
											whileTap={{ scale: 0.95 }}
											onClick={() => {
												handleFormatCheck();
												if (supportedFormats.includes(fileFormat.toLowerCase().replace('.', ''))) {
													setShowConfetti(true);
													setTimeout(() => setShowConfetti(false), 3000);
												}
											}}
											className="absolute right-2 top-1/2 -translate-y-1/2 bg-gradient-to-r from-purple-500 to-purple-600 text-white px-4 py-1.5 rounded-lg hover:opacity-90 transition-all shadow-xl whitespace-nowrap"
										>
											Let's Check! 🚀
										</motion.button>
									</div>

									{/* Result Message */}
									<AnimatePresence mode="wait">
										{checkResult && (
											<motion.div
												initial={{ opacity: 0, y: 20 }}
												animate={{ opacity: 1, y: 0 }}
												exit={{ opacity: 0, y: -20 }}
												className={`p-4 rounded-xl ${
													checkResult === 'success' 
														? 'bg-gradient-to-r from-green-500/20 to-blue-500/20 border border-green-500/30' 
														: 'bg-gradient-to-r from-red-500/20 to-orange-500/20 border border-red-500/30'
												}`}
											>
												{checkResult === 'success' ? (
													<div className="flex flex-col items-center space-y-3">
														<motion.div 
															initial={{ scale: 0 }}
															animate={{ scale: [0, 1.2, 1] }}
															transition={{ duration: 0.5 }}
															className="text-4xl"
														>
															🎉
														</motion.div>
														<div className="text-center">
															<h4 className="text-lg font-bold text-white mb-1">Awesome! You've Got This!</h4>
															<p className="text-gray-300 text-sm mb-3">Your file format is ready to rock with VEEWER</p>
														</div>
														<motion.button
															whileHover={{ scale: 1.05 }}
															whileTap={{ scale: 0.95 }}
															onClick={handleGetStarted}
															className="bg-gradient-to-r from-purple-500 to-purple-600 text-white px-6 py-2 rounded-xl hover:opacity-90 transition-all shadow-xl text-sm font-semibold group"
														>
															<span className="flex items-center gap-2">
																 Start Your Journey 
																<motion.span
																	animate={{ x: [0, 4, 0] }}
																	 transition={{ repeat: Infinity, duration: 1.5 }}
																>
																	→
																</motion.span>
															</span>
														</motion.button>
													</div>
												) : (
													<div className="flex flex-col items-center space-y-3 text-center">
														<motion.div 
															animate={{ rotate: [0, -10, 10, -10, 0] }}
															 transition={{ duration: 0.5 }}
															 className="text-4xl"
														>
															🎮
														</motion.div>
														<div>
															<h4 className="text-lg font-bold text-white mb-1">Oops! New Level Unlocked!</h4>
															<p className="text-gray-300 text-sm mb-3">
																This format is not in our current quest list, but our format wizards might be able to help.
															</p>
														</div>
														<motion.button
															whileHover={{ scale: 1.05 }}
															whileTap={{ scale: 0.95 }}
															onClick={handleContactSales}
															className="bg-gradient-to-r from-orange-500 to-red-500 text-white px-6 py-2 rounded-xl hover:opacity-90 transition-all shadow-xl text-sm font-semibold"
														>
															Contact Our Format Wizards 🧙‍♂️
														</motion.button>
													</div>
												)}
											</motion.div>
										)}
									</AnimatePresence>

									{/* Supported Formats Preview */}
									<motion.div 
										initial={{ opacity: 0 }}
										animate={{ opacity: 1 }}
										transition={{ delay: 0.2 }}
										className="text-sm text-gray-400"
									>
										<p className="mb-2">Popular formats:</p>
										<div className="flex flex-wrap gap-1.5">
											{['obj', 'fbx', 'gltf', 'step', 'stl', 'iges'].map((format) => (
												<motion.span
													key={format}
													whileHover={{ scale: 1.05 }}
													 className="px-2 py-1 bg-white/5 border border-gray-700 rounded-lg hover:border-purple-500/50 transition-colors cursor-pointer text-xs"
													 onClick={() => {
															setFileFormat(format);
															handleFormatCheck();
														}}
												>
													 .{format}
												</motion.span>
											))}
											<motion.span
												whileHover={{ scale: 1.05 }}
												className="px-2 py-1 bg-white/5 border border-gray-700 rounded-lg hover:border-purple-500/50 transition-colors text-xs cursor-pointer"
												onClick={() => setShowFormatsModal(true)}
											>
												+{supportedFormats.length - 6} more
											</motion.span>
										</div>
									</motion.div>
								</div>
							</div>

							{/* Confetti Effect */}
							{showConfetti && (
								<div className="absolute inset-0 pointer-events-none z-50">
									{Array.from({ length: 50 }).map((_, i) => (
										<motion.div
											key={i}
											initial={{
												x: Math.random() * window.innerWidth,
												y: -20,
												scale: Math.random() * 0.5 + 0.5,
												rotate: Math.random() * 360
											}}
											animate={{
												y: window.innerHeight + 20,
												rotate: Math.random() * 720
											}}
											transition={{
												duration: Math.random() * 2 + 1,
												ease: "linear"
											}}
											className={`absolute w-2 h-2 rounded-full ${
												['bg-purple-500', 'bg-blue-500', 'bg-pink-500', 'bg-yellow-500'][Math.floor(Math.random() * 4)]
											}`}
										/>
									))}
								</div>
							)}
						</motion.div>
					</motion.div>
				)}
			</AnimatePresence>

			{/* All Formats Modal */}
			<AnimatePresence>
				{showFormatsModal && (
					<motion.div
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						exit={{ opacity: 0 }}
						className="fixed inset-0 bg-black/50 backdrop-blur-sm z-[60] flex items-center justify-center p-4"
						onClick={() => setShowFormatsModal(false)}
					>
						<motion.div
							initial={{ scale: 0.95, opacity: 0 }}
							animate={{ scale: 1, opacity: 1 }}
							exit={{ scale: 0.95, opacity: 0 }}
							onClick={(e) => e.stopPropagation()}
							className="bg-[#0a0a1f] rounded-2xl p-6 max-w-2xl w-full relative overflow-hidden border border-purple-500/20"
						>
							{/* Animated Background */}
							<div className="absolute inset-0 bg-grid-pattern opacity-5" />
							<div className="absolute top-0 left-0 w-full h-full">
								<div className="absolute top-0 left-0 w-64 h-64 bg-purple-500 rounded-full mix-blend-multiply filter blur-xl opacity-10 animate-blob" />
								<div className="absolute bottom-0 right-0 w-64 h-64 bg-blue-500 rounded-full mix-blend-multiply filter blur-xl opacity-10 animate-blob animation-delay-2000" />
							</div>

							{/* Close Button */}
							<button
								onClick={() => setShowFormatsModal(false)}
								className="absolute top-3 right-3 text-gray-400 hover:text-white transition-colors z-10"
							>
								<svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
									<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
								</svg>
							</button>

							{/* Modal Content */}
							<div className="relative z-10">
								<div className="text-center mb-6">
									<h3 className="text-2xl font-bold text-white mb-2">Supported Formats</h3>
									<p className="text-gray-400 text-sm">All file formats that VEEWER can handle</p>
								</div>

								<div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-2 max-h-[60vh] overflow-y-auto pr-2 custom-scrollbar">
									{supportedFormats.map((format: string) => (
										<motion.span
											key={format}
											whileHover={{ scale: 1.05 }}
											className="px-2 py-1 bg-white/5 border border-gray-700 rounded-lg hover:border-purple-500/50 transition-colors cursor-pointer text-xs text-gray-300"
											onClick={() => {
												setFileFormat(format);
												setShowFormatsModal(false);
												const isSupported = supportedFormats.includes(format.toLowerCase());
												if (isSupported) {
													setCheckResult('success');
													setShowConfetti(true);
												} else {
													setCheckResult('error');
												}
											}}
										>
											.{format}
										</motion.span>
									))}
								</div>
							</div>
						</motion.div>
					</motion.div>
				)}
			</AnimatePresence>

			{/* Demo Modal - En üst seviyede render edilecek */}
			<DemoModal 
				isOpen={isDemoModalOpen} 
				onClose={() => setIsDemoModalOpen(false)} 
			/>
		</div>
	);
};

export default Home;
