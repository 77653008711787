"use client"

import { useState } from 'react'
import { baseUrl } from '../config/axios.config'
import Footer from '../components/Footer/Footer'

type ModelSize = '1000' | '1300' | '1500'
type ModelColor = 'red' | 'blue' | 'black'

type UrlMapping = {
  [K in ModelColor]: {
    [S in ModelSize]: string
  }
}
// interface ComponentProps {
//   successToken: boolean;
// }

const Component = () => {
  // URL Constants
  const baseURL = baseUrl
  const red1 = `${baseURL}objects/content/dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6c3luYy00Njc1L1JlZDEuaWFt/view/93311599-51c3-4f7c-bc48-1559d6ed52ba/properties/showWatermark=False&setGroundShadow=true&setGroundReflection=true`;
  const red2 = `${baseURL}objects/content/dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6c3luYy00Njc1L1JlZDIuaWFt/view/93311599-51c3-4f7c-bc48-1559d6ed52ba/properties/showWatermark=False&setGroundShadow=true&setGroundReflection=true`;
  const red3 = `${baseURL}objects/content/dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6c3luYy00Njc1L1JlZDMuaWFt/view/93311599-51c3-4f7c-bc48-1559d6ed52ba/properties/showWatermark=False&setGroundShadow=true&setGroundReflection=true`;

  const blue1 = `${baseURL}objects/content/dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6c3luYy00Njc1L0JsdWUxLmlhbQ/view/93311599-51c3-4f7c-bc48-1559d6ed52ba/properties/showWatermark=False&setGroundShadow=true&setGroundReflection=true`;
  const blue2 = `${baseURL}objects/content/dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6c3luYy00Njc1L0JsdWUyLmlhbQ/view/93311599-51c3-4f7c-bc48-1559d6ed52ba/properties/showWatermark=False&setGroundShadow=true&setGroundReflection=true`;
  const blue3 = `${baseURL}objects/content/dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6c3luYy00Njc1L0JsdWUzLmlhbQ/view/93311599-51c3-4f7c-bc48-1559d6ed52ba/properties/showWatermark=False&setGroundShadow=true&setGroundReflection=true`;

  const black1 = `${baseURL}objects/content/dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6c3luYy00Njc1L0JsYWNrMS5pYW0/view/93311599-51c3-4f7c-bc48-1559d6ed52ba/properties/showWatermark=False&setGroundShadow=true&setGroundReflection=true`;
  const black2 = `${baseURL}objects/content/dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6c3luYy00Njc1L0JsYWNrMi5pYW0/view/93311599-51c3-4f7c-bc48-1559d6ed52ba/properties/showWatermark=False&setGroundShadow=true&setGroundReflection=true`;
  const black3 = `${baseURL}objects/content/dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6c3luYy00Njc1L0JsYWNrMy5pYW0/view/93311599-51c3-4f7c-bc48-1559d6ed52ba/properties/showWatermark=False&setGroundShadow=true&setGroundReflection=true`;

  // URL mapping object
  const urlMapping: UrlMapping = {
    red: {
      '1000': red1,
      '1300': red2,
      '1500': red3
    },
    blue: {
      '1000': blue1,
      '1300': blue2,
      '1500': blue3
    },
    black: {
      '1000': black1,
      '1300': black2,
      '1500': black3
    }
  }

  // State
  const [modelSize, setModelSize] = useState<ModelSize>('1000')
  const [modelColor, setModelColor] = useState<ModelColor>('red')
  const [iframeUrl, setIframeUrl] = useState(red1)

  const handleSizeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setModelSize(event.target.value as ModelSize)
  }

  const handleColorChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setModelColor(event.target.value as ModelColor)
  }

  const handleUpdate = () => {
    const newUrl = urlMapping[modelColor][modelSize]
    setIframeUrl(newUrl)
  }

  const handleReset = () => {
    setModelSize('1000')
    setModelColor('red')
    setIframeUrl(red1)
  }

  return (
    <>
    <div className="flex flex-col md:flex-row h-[calc(100vh-80px)] bg-gray-100">
      <div className="w-full md:w-64 p-4 bg-white shadow-md">
        <div className="space-y-4">
          <div>
            <label htmlFor="modelSize" className="block text-sm font-medium text-gray-700 mb-1">
              Model Size(W)
            </label>
            <select
              id="modelSize"
              value={modelSize}
              onChange={handleSizeChange}
              className="block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
            >
              <option value="1000">1000</option>
              <option value="1300">1300</option>
              <option value="1500">1500</option>
            </select>
          </div>

          <div>
            <label htmlFor="modelColor" className="block text-sm font-medium text-gray-700 mb-1">
              Model Color
            </label>
            <select
              id="modelColor"
              value={modelColor}
              onChange={handleColorChange}
              className="block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
            >
              <option value="red">Red</option>
              <option value="blue">Blue</option>
              <option value="black">Black</option>
            </select>
          </div>

          <div className="flex space-x-2">
            <button
              onClick={handleReset}
              className="flex-1 px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Reset
            </button>
            <button
              onClick={handleUpdate}
              className="flex-1 px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Update
            </button>
          </div>
        </div>
      </div>

      <div className="flex-1 p-4">
        {/* {successToken && ( */}
          <iframe
            src={iframeUrl}
            className="w-full h-full border-none bg-white"
            title="3D Model Viewer"
            loading="eager"
          />
        {/* )} */}
      </div>
    </div>
    <Footer />
    </>
  )
}

export default Component
