"use client"

import React, { useState, useEffect, useRef } from 'react'
import { baseUrl } from '../config/axios.config'

// Import all furniture thumbnails
import luxChairImg from '../assets/furniture/luxChair.png'
import ekenaArmChairImg from '../assets/furniture/ekenaArmChair.png'
import ekanaSofaImg from '../assets/furniture/ekanaSofa.png'
import sofaImg from '../assets/furniture/sofa.png'
import ikeaSofaImg from '../assets/furniture/ikeaSofa.png'
import bed48Img from '../assets/furniture/bed48.png'
import bed178Img from '../assets/furniture/bed178.png'
import woodbed160x200Img from '../assets/furniture/woodbed160x200.png'
import francuzImg from '../assets/furniture/francuz.png'
import ergonomicOfficeImg from '../assets/furniture/ergonomicOffice.png'
import gameChairImg from '../assets/furniture/gameChair.png'
import cadeiraImg from '../assets/furniture/cadeira.png'
import modernChair01Img from '../assets/furniture/modernChair01.png'
import woodenFoldableImg from '../assets/furniture/woodenFoldable.png'
import gamingChair3Img from '../assets/furniture/gamingChair3.png'
import metalChair10Img from '../assets/furniture/metalChair10.png'
import karmanImg from '../assets/furniture/karman.png'
import chairVar111Img from '../assets/furniture/chairVar111.png'
import bookShelfImg from '../assets/furniture/bookShelf.png'
import commode9Img from '../assets/furniture/commode9.png'
import bedsideTableImg from '../assets/furniture/bedsideTable.png'
import abyssDinningImg from '../assets/furniture/abyssDinning.png'
import ballImg from '../assets/furniture/ball.png'
import hourGlass22Img from '../assets/furniture/hourGlass22.png'
import trainMiniatureImg from '../assets/furniture/trainMiniature.png'
import antiqueScaleOfImg from '../assets/furniture/antiqueScaleOf.png'
import sofa4Img from '../assets/furniture/sofa4.png'
import sofa5Img from '../assets/furniture/sofa5.png'
import sofa3Img from '../assets/furniture/sofa3.png'
import maxSofaModel1Img from '../assets/furniture/max-sofa-model-1.png'
import sofa7Img from '../assets/furniture/sofa7.png'
import Footer from '../components/Footer/Footer'

// URN definitions
const luxChair = "dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6c3luYy00Njc1L2x1eCUyMGNoYWlyX0d1aWRfMDIwMDllMTktZTRjZS00YmFhLWE3MzItYzE0ZWZjNDA0ODRmLmlwdA";
const ekenaArmChair = "dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6c3luYy00Njc1L2VrZW5hJTIwYXJtY2hhaXJfR3VpZF85MTRiOGVjOC1mNmRjLTRmNjgtYWI1MS0yYzUwYWI3Nzk2OTguaXB0";
const ekanaSofa = "dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6c3luYy00Njc1L2VrZW5hJTIwc29mYV9HdWlkX2I4YzQ3ZDY3LTNlYjUtNDUyMC1hNWQzLWM1OTc2NTUyYjdhNC5pcHQ";
const sofa = "dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6c3luYy00Njc1L3NvZmFfYnlBRF9HdWlkXzc5MjY1YjE0LWZjNWItNDcyNi04YjYzLWM0M2NjMTYyODU3NC5pcHQ";
const ikeaSofa = "dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6c3luYy00Njc1L0lLRUElMjBDb3JuZXIlMjBTb2ZhX0d1aWRfODM4NzJjYzAtZmZiYy00ODQ5LTg3Y2YtNjBmZGIyYWFiN2Y3LmlwdA";
const bed48 = "dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6c3luYy00Njc1L2JlZC00OF9HdWlkX2E1N2Y5YTkxLWUxZWItNDFhZi04OWM5LWEwYzVmNjY4MmQwOC56aXA";
const bed178 = "dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6c3luYy00Njc1L2JlZC0xNzhfR3VpZF84ZDZlMWVkYy1iZTYwLTQ5NGMtYjUwMy00ODJjODU0NTEzMWEuemlw";
const woodbed160x200 = "dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6c3luYy00Njc1L3dvb2QtYmVkLTE2MHgyMDAtMS5zbmFwc2hvdC42X0d1aWRfM2Y0YmM3ZmUtYzA1YS00MTYyLTllMTQtNjg0ZDlkNzFmOWU4LnppcA";
const francuz = "dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6c3luYy00Njc1L0ZyYW5jdXpfR3VpZF9kMjEyZTc3OC1hYzM5LTQ1YTMtYWNiMy01MDAxZGZkZDA3YmQuaXB0";
const ergonomicOffice = "dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6c3luYy00Njc1L2VyZ29ub21pYy1vZmZpY2UtY2hhaXItMS5zbmFwc2hvdC42X0d1aWRfMzQ4Yzg4YmMtZmQ3Ny00MTczLTg4ZDctNmEwYTIyNjY1OWQ3LnppcA";
const gameChair = "dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6c3luYy00Njc1L0dhbWVyQ2hhaXJfR3VpZF8xNmU1Mzc0Zi1hYjRkLTRmNjctYTNlZC02M2EwZmU2OGFmYzguaXB0";
const cadeira = "dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6c3luYy00Njc1L0NhZGVpcmFfYnJhbmNvX0d1aWRfNWQ3MGRmMjItYjlhNi00YWJkLWEyZjUtNTM2MTkxY2Q4ZTZiLmlwdA";
const modernChair01 = "dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6c3luYy00Njc1L21vZGVybiUyMGNoYWlyJTIwMDElMjBtYXhfR3VpZF83NTFhYmRhNS01N2FjLTQ2MzgtYTM0MC0yYjJlZGU4ZjNlMjEubWF4";
const woodenFoldable = "dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6c3luYy00Njc1L3dvb2Rlbi1mb2xkYWJsZS1jaGFpci0xLnNuYXBzaG90LjJfR3VpZF9lMDRmNTg3Zi00MWE4LTRlYzQtOGNhOS1mMWZkNWUwOGNmYTMuemlw";
const gamingChair3 = "dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6c3luYy00Njc1L2dhbWluZy1jaGFpci0zLnNuYXBzaG90LjRfR3VpZF83NmU4NzI3MC1mOTBjLTQ2YWItODZkOS01MTVlYTAxMWI3MDkuemlw";
const metalChair10 = "dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6c3luYy00Njc1L21ldGFsLWNoYWlyLTEwLnNuYXBzaG90LjNfR3VpZF9kZTY2MzdjYS0wZTJkLTQ3ZDctOTUzZC1hZTUzYzBjMzI4NDIuemlw";
const karman = "dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6c3luYy00Njc1L0thcm1hbl8wMDRhX0d1aWRfMzkwNWRkZmYtMjYyMS00NGYyLWFiMGItZDAxNjI4ZTVlNzQ2LnhfYg";
const chairVar111 = "dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6c3luYy00Njc1L2NoYWlyLXZhcjEtMS0xLnNuYXBzaG90LjNfR3VpZF9kNjE4YzAwNi05OTRiLTQyYzctOWUxNC00MWNhNWYwMzQ0NGYuemlw";
const bookShelf = "dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6c3luYy00Njc1L2Jvb2tzaGVsZl9HdWlkX2FhNTU2YTk2LWJjMGMtNGNkZS1hZThlLTZkNzI5ZjMwMzI5OC56aXA";
const commode9 = "dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6c3luYy00Njc1L2NvbW1vZGUtOS5zbmFwc2hvdC44X0d1aWRfYmUzMWZhNGYtMmRmMy00MjNkLTgzOWQtYTY4N2Y4N2Y4YTQ0LnppcA"
const bedsideTable = "dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6c3luYy00Njc1L0JlZHNpZGUlMjBUYWJsZV9HdWlkXzNmZGQ5OTI0LTE5MDctNDc3Ny1hNDg2LWY3ZDU4ZjVlNGQxNS5pcHQ";
const abyssDining = "dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6c3luYy00Njc1L0FCWVNTJTIwRElOTklORyUyMFNFVFMlMjBCWSUyMENPX0d1aWRfNWQ4NDM2YTgtYzE2Zi00OWQ3LWJlZTUtYmFhMDM3ODk3N2FmLjNkbQ";
const ball = "dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6c3luYy00Njc1L0JBTExfR3VpZF9hOTdiOGZhZS1lMDUzLTQ4NzgtOTM5Yi0wOWQ4YWUyNzVjYzUuaXB0";
const hourGlass22 = "dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6c3luYy00Njc1L2hvdXJnbGFzcy0yMi5zbmFwc2hvdC42X0d1aWRfYWE5ZTI3NmItOTQ2YS00YzgwLWFhMWEtODdkNmQxZmQ1M2VlLnppcA";
const trainMiniature = "dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6c3luYy00Njc1L3RyYWluLW1pbmlhdHVyZS0xLnNuYXBzaG90LjI3X0d1aWRfODVhMmEzMGYtYzA4Yi00NTBmLTk2YzQtMmZlNmM0YzJiM2U2LnppcA";
const antiqueScaleOf = "dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6c3luYy00Njc1L2FudGlxdWUtc2NhbGUtb2YtanVzdGljZS0xLnNuYXBzaG90LjVfR3VpZF9iYzk1ODA0My01Y2FkLTQyOTYtODE2OS1hMjgzNTYyZGVhMmMuemlw";
const sofa4 = "dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6c3luYy00Njc1L1NvZmElMjBNb2RlbCUyMDRfR3VpZF82YmZhZDRlZS1hOWJkLTRhZTQtYmJkOC0wNTE4NDc0NWVmZmYubWF4";
const sofa5 = "dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6c3luYy00Njc1L1NvZmElMjBNb2RlbCUyMDVfR3VpZF84Y2VlNzIwMS02ZTRiLTRkZGEtYWExMC1lMWIyNjE0YTdhMWMubWF4";
const sofa3 = "dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6c3luYy00Njc1L1NvZmElMjBNb2RlbCUyMDNfR3VpZF9lY2U2Mjk0YS0yODIwLTQxOTItYTllNS1iNjM4NDRhYWY2MzUubWF4";
const maxSofaModel1= "dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6c3luYy00Njc1L01heCUyMFNvZmElMjBNb2RlbCUyMDFfR3VpZF9mNWFlZmQ1OC04ZTM5LTQ3ZjktYjAzOC1iMjUxOGMyZWYyZjEubWF4";
const sofa7 = "dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6c3luYy00Njc1L1NvZmElMjBNb2RlbCUyMDdfR3VpZF9hMTc1ZjNkZS05Y2E3LTQ1NjctODhlZS0yZjljOTllNzhjZmEubWF4";

interface Product {
  urn: string;
  title: string;
  image: string;
  category: string;
}

const ProductCard: React.FC<{ product: Product;}> = ({ product }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [showIframe, setShowIframe] = useState(false);
  const cardRef = useRef<HTMLDivElement>(null);
  const iframeRef = useRef<HTMLIFrameElement>(null);

  const iframeUrl = `${baseUrl}objects/content/${product.urn}/view/00dc8f6d-9174-4ee6-99e3-3b59d7ea58b9/properties/hideBottomToolBar=true&showWatermark=false&setGroundShadow=true&setGroundReflection=false&viewcube=true&homeViewWrapper=true&activeTools=toolbar-sectionTool,toolbar-panTool,toolbar-zoomTool,toolbar-propertiesTool,toolbar-orbitTools,toolbar-settingsTool,toolbar-modelStructureTool,toolbar-bimWalkTool,toolbar-cameraSubmenuTool,toolbar-explodeTool,toolbar-measurementSubmenuTool,toolbar-fullscreenTool,viewcube,homeViewWrapper`;

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
        if (!entry.isIntersecting) {
          setShowIframe(false);
        }
      },
      {
        threshold: 0.1,
        rootMargin: '50px'
      }
    );

    if (cardRef.current) {
      observer.observe(cardRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (isVisible) {
      timeoutId = setTimeout(() => {
        setShowIframe(true);
      }, 100);
    }
    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [isVisible]);

  const handleIframeError = () => {
    console.log('iframe loading error');
    setShowIframe(false);
  };

  return (
    <div className="bg-white rounded-xl shadow-md overflow-hidden border border-gray-100 group hover:shadow-lg transition-all duration-300 h-[280px]">
      <div ref={cardRef} className="relative h-[220px] group-hover:h-[220px] transition-all duration-300">
        {showIframe ? (
          <iframe
            ref={iframeRef}
            src={iframeUrl}
            title={product.title}
            className="absolute inset-0 w-full h-full z-10"
            frameBorder="0"
            loading="lazy"
            onError={handleIframeError}
            allowFullScreen
          ></iframe>
        ) : null}
        <img
          src={product.image}
          alt={product.title}
          className="absolute inset-0 w-full h-full object-cover z-20 pointer-events-none group-hover:opacity-0 transition-all duration-300"
          loading="lazy"
        />
        <a
          href={iframeUrl}
          target="_blank"
          rel="noopener noreferrer"
          className="absolute top-2 right-2 z-30 bg-white/90 hover:bg-white text-blue-600 p-2 rounded-lg shadow-sm transition-all duration-200"
          title="Open in new tab"
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
          </svg>
        </a>
      </div>
      <div className="p-3 bg-white h-[60px]">
        <h3 className="text-sm font-medium truncate text-gray-800">{product.title}</h3>
        <p className="text-xs text-gray-500 capitalize">{product.category}</p>
      </div>
    </div>
  );
};

const Examples=() => {
  const [selectedCategory, setSelectedCategory] = useState<string>('all');
  const [filteredProducts, setFilteredProducts] = useState<Product[]>([]);
  // Pagination için yeni state'ler
  const [currentPage, setCurrentPage] = useState(1);
  const productsPerPage = 8;

  const products: Product[] = [
    // Armchairs
    {
      urn: luxChair,
      title: "Lux Chair",
      image: luxChairImg,
      category: "armchair"
    },
    {
      urn: ekenaArmChair,
      title: "Ekena Armchair",
      image: ekenaArmChairImg,
      category: "armchair"
    },
    // Sofas
    {
      urn: ekanaSofa,
      title: "Ekana Sofa",
      image: ekanaSofaImg,
      category: "sofa"
    },
    {
      urn: sofa,
      title: "Sofa",
      image: sofaImg,
      category: "sofa"
    },
    {
      urn: ikeaSofa,
      title: "IKEA Corner Sofa",
      image: ikeaSofaImg,
      category: "sofa"
    },
    {
      urn: sofa4,
      title: "Sofa 4",
      image: sofa4Img,
      category: "sofa"
    },
    {
      urn: sofa5,
      title: "Sofa 5",
      image: sofa5Img,
      category: "sofa"
    },
    {
      urn: sofa3,
      title: "Sofa 3",
      image: sofa3Img,
      category: "sofa"
    },
    {
      urn: maxSofaModel1,
      title: "Max Sofa Model 1",
      image: maxSofaModel1Img,
      category: "sofa"
    },
    {
      urn: sofa7,
      title: "Sofa 7",
      image: sofa7Img,
      category: "sofa"
    },
    
    // Beds
    {
      urn: bed48,
      title: "Bed 48",
      image: bed48Img,
      category: "bed"
    },
    {
      urn: bed178,
      title: "Bed 178",
      image: bed178Img,
      category: "bed"
    },
    {
      urn: woodbed160x200,
      title: "Wood Bed 160x200",
      image: woodbed160x200Img,
      category: "bed"
    },
    {
      urn: francuz,
      title: "Francuz",
      image: francuzImg,
      category: "bed"
    },
    // Chairs
    {
      urn: ergonomicOffice,
      title: "Ergonomic Office Chair",
      image: ergonomicOfficeImg,
      category: "chair"
    },
    {
      urn: gameChair,
      title: "Game Chair",
      image: gameChairImg,
      category: "chair"
    },
    {
      urn: cadeira,
      title: "Cadeira",
      image: cadeiraImg,
      category: "chair"
    },
    {
      urn: modernChair01,
      title: "Modern Chair 01",
      image: modernChair01Img,
      category: "chair"
    },
    {
      urn: woodenFoldable,
      title: "Wooden Foldable Chair",
      image: woodenFoldableImg,
      category: "chair"
    },
    {
      urn: gamingChair3,
      title: "Gaming Chair 3",
      image: gamingChair3Img,
      category: "chair"
    },
    {
      urn: metalChair10,
      title: "Metal Chair 10",
      image: metalChair10Img,
      category: "chair"
    },
    {
      urn: karman,
      title: "Karman",
      image: karmanImg,
      category: "chair"
    },
    {
      urn: chairVar111,
      title: "Chair Var1-1-1",
      image: chairVar111Img,
      category: "chair"
    },
    // Other
    {
      urn: bookShelf,
      title: "Book Shelf",
      image: bookShelfImg,
      category: "other"
    },
    {
      urn: commode9,
      title: "Commode 9",
      image: commode9Img,
      category: "other"
    },
    {
      urn: bedsideTable,
      title: "Bedside Table",
      image: bedsideTableImg,
      category: "other"
    },
    // Dinning Sets
    {
      urn: abyssDining,
      title: "Abyss Dining Set",
      image: abyssDinningImg,
      category: "dining"
    },
    // Accessories
    {
      urn: ball,
      title: "Ball",
      image: ballImg,
      category: "accessories"
    },
    {
      urn: hourGlass22,
      title: "Hour Glass 22",
      image: hourGlass22Img,
      category: "accessories"
    },
    {
      urn: trainMiniature,
      title: "Train Miniature",
      image: trainMiniatureImg,
      category: "accessories"
    },
    {
      urn: antiqueScaleOf,
      title: "Antique Scale",
      image: antiqueScaleOfImg,
      category: "accessories"
    }
  ];

  const categories = [
    { id: 'all', label: 'All' },
    { id: 'armchair', label: 'Armchairs' },
    { id: 'sofa', label: 'Sofas' },
    { id: 'bed', label: 'Beds' },
    { id: 'chair', label: 'Chairs' },
    { id: 'other', label: 'Other' },
    { id: 'dining', label: 'Dining Sets' },
    { id: 'accessories', label: 'Accessories' },
  ];

  useEffect(() => {
    if (selectedCategory === 'all') {
      setFilteredProducts(products);
    } else {
      setFilteredProducts(products.filter(product => product.category === selectedCategory));
    }
    // Kategori değiştiğinde sayfa 1'e dön
    setCurrentPage(1);
  }, [selectedCategory]);

  // Mevcut sayfadaki ürünleri hesapla
  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = filteredProducts.slice(indexOfFirstProduct, indexOfLastProduct);
  const totalPages = Math.ceil(filteredProducts.length / productsPerPage);

  // Sayfa değiştirme fonksiyonu
  const paginate = (pageNumber: number) => {
    setCurrentPage(pageNumber);
    // Sayfayı yukarı kaydır
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <>
    <div className="max-w-screen-xl mt-[-80px] mx-auto h-full flex flex-col px-6">
      <h2 className="text-2xl font-bold mb-4 text-center text-gray-800">Furniture Gallery</h2>
      
      {/* Category Filter */}
      <div className="flex flex-wrap justify-center gap-2 mb-4">
        {categories.map(category => (
          <button
            key={category.id}
            onClick={() => setSelectedCategory(category.id)}
            className={`px-3 py-1.5 text-xs font-medium rounded-lg transition-all duration-200 ${
              selectedCategory === category.id
                ? 'bg-blue-600 text-white shadow-sm hover:bg-blue-700'
                : 'bg-gray-50 text-gray-600 hover:bg-gray-100'
            }`}
          >
            {category.label}
          </button>
        ))}
      </div>

      {/* Product Grid */}
      <div className="flex-1 ">
        <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-4 pb-16 auto-rows-[280px] max-w-6xl mx-auto">
          {currentProducts.map((product, index) => (
            <ProductCard 
              key={index} 
              product={product} 
            />
          ))}
        </div>
      </div>

      {/* Pagination */}
      {totalPages > 1 && (
        <div className="flex mb-10 left-0 right-0 flex justify-center items-center gap-1.5">
          <button
            onClick={() => paginate(currentPage - 1)}
            disabled={currentPage === 1}
            className={`px-3 py-1.5 text-xs font-medium rounded-lg transition-all duration-200 ${
              currentPage === 1
                ? 'bg-gray-50 text-gray-400 cursor-not-allowed'
                : 'bg-white text-gray-700 hover:bg-gray-50 border border-gray-200'
            }`}
          >
            Previous
          </button>
          
          {[...Array(totalPages)].map((_, index) => (
            <button
              key={index}
              onClick={() => paginate(index + 1)}
              className={`px-3 py-1.5 text-xs font-medium rounded-lg transition-all duration-200 ${
                currentPage === index + 1
                  ? 'bg-blue-600 text-white shadow-sm'
                  : 'bg-white text-gray-700 hover:bg-gray-50 border border-gray-200'
              }`}
            >
              {index + 1}
            </button>
          ))}

          <button
            onClick={() => paginate(currentPage + 1)}
            disabled={currentPage === totalPages}
            className={`px-3 py-1.5 text-xs font-medium rounded-lg transition-all duration-200 ${
              currentPage === totalPages
                ? 'bg-gray-50 text-gray-400 cursor-not-allowed'
                : 'bg-white text-gray-700 hover:bg-gray-50 border border-gray-200'
            }`}
          >
            Next
          </button>
        </div>
      )}
     
    </div>
    <Footer />
    </>
  );
}

export default Examples;