import { Subject, BehaviorSubject } from 'rxjs';

export enum UploadStage {
  QUEUED = 'QUEUED',
  PREPARING = 'PREPARING',
  UPLOADING = 'UPLOADING',
  PROCESSING = 'PROCESSING',
  MANIFEST_PROCESSING = 'MANIFEST_PROCESSING',
  COMPLETED = 'COMPLETED',
  ERROR = 'ERROR',
  PAUSED = 'PAUSED'
}

export interface UploadProgress {
  stage: UploadStage;
  progress?: number;
  bytesUploaded?: number;
  totalBytes?: number;
  message?: string;
  error?: string;
  currentOperation?: string;
  objectId?: string;
  location?: string;
  rootFileName?: string;
}

export interface RootPathResponse {
  progress: number;
  bytesRead: number;
  totalBytes: number;
  path: string;
  fileName: string;
  completed: boolean;
}

export interface ProcessUploadResponse {
  response?: {
    bucketKey?: string;
    objectId?: string;
    objectKey?: string;
    size?: number;
    contentType?: string;
    location?: string;
  };
  progress: number;
  startByte: number;
  dataWrited: number;
  rootFileName?: string;
}

export interface FinalizeResponse {
  isSuccess: boolean;
  urns: string[];
  uid: string;
  files: string[];
}

export interface ManifestResponse {
  progress: string;
}

export class UploadManager {
  private static instance: UploadManager;
  private progressSubject = new BehaviorSubject<Map<string, UploadProgress>>(new Map());
  private uploadQueue: string[] = [];
  private lastUpdateTime: Map<string, number> = new Map();
  private readonly UPDATE_THRESHOLD = 500; // ms
  
  static getInstance() {
    if (!UploadManager.instance) {
      UploadManager.instance = new UploadManager();
    }
    return UploadManager.instance;
  }

  getProgress() {
    return this.progressSubject.asObservable();
  }

  getCurrentProgress(): Map<string, UploadProgress> {
    return this.progressSubject.value;
  }

  updateProgress(fileName: string, progress: Partial<UploadProgress>) {
    console.log('Updating progress for:', fileName, progress);
    
    const currentProgress = this.progressSubject.value;
    const updatedProgress = new Map(currentProgress);
    
    const existing = updatedProgress.get(fileName) || { 
      stage: UploadStage.QUEUED,
      progress: 0,
      message: 'Ready to upload',
      currentOperation: 'Ready to upload'
    };
    
    const newProgress = {
      ...existing,
      ...progress,
      message: progress.message || existing.message,
      currentOperation: progress.currentOperation || existing.currentOperation,
      progress: progress.progress !== undefined ? progress.progress : existing.progress,
      bytesUploaded: progress.bytesUploaded || existing.bytesUploaded,
      totalBytes: progress.totalBytes || existing.totalBytes
    };

    console.log('New progress state:', newProgress);
    
    updatedProgress.set(fileName, newProgress);
    
    // Force a new reference to trigger updates
    this.progressSubject.next(new Map(updatedProgress));

    // Log the entire progress map after update
    console.log('All progress states:', Object.fromEntries(this.progressSubject.value));
  }

  handleRootPathResponse(response: RootPathResponse, fileName: string) {
    const currentTime = Date.now();
    const lastUpdate = this.lastUpdateTime.get(fileName) || 0;
    const percentComplete = Math.round((response.bytesRead / response.totalBytes) * 100);

    // Only update if:
    // 1. It's the first update (lastUpdate === 0)
    // 2. It's been more than UPDATE_THRESHOLD ms since last update
    // 3. The file is completed
    // 4. There's been a significant progress change (>5%)
    const lastProgress = this.progressSubject.value.get(fileName)?.progress || 0;
    const significantChange = Math.abs(percentComplete - lastProgress) >= 5;

    if (response.completed || 
        lastUpdate === 0 || 
        currentTime - lastUpdate >= this.UPDATE_THRESHOLD ||
        significantChange) {
      
      this.updateProgress(fileName, {
        stage: UploadStage.UPLOADING,
        progress: percentComplete,
        bytesUploaded: response.bytesRead,
        totalBytes: response.totalBytes,
        message: `Preparing upload: ${(response.bytesRead / (1024 * 1024)).toFixed(1)}MB / ${(response.totalBytes / (1024 * 1024)).toFixed(1)}MB`,
        currentOperation: response.completed ? 'Preparation complete' : 'Preparing file...'
      });

      this.lastUpdateTime.set(fileName, currentTime);
    }
  }

  handleProcessUploadResponse(response: ProcessUploadResponse, fileName: string) {
    if (!response.response || !response.response.size) {
      // Handle in-progress upload
      const percentComplete = response.progress;
      this.updateProgress(fileName, {
        stage: UploadStage.UPLOADING,
        progress: percentComplete,
        message: `Uploading: ${percentComplete}%`,
        currentOperation: 'Uploading file...'
      });
      return;
    }

    // Handle completed upload with response data
    this.updateProgress(fileName, {
      stage: UploadStage.UPLOADING,
      progress: response.progress,
      bytesUploaded: response.dataWrited,
      totalBytes: response.response.size,
      message: `Uploading: ${(response.dataWrited / (1024 * 1024)).toFixed(1)}MB / ${(response.response.size / (1024 * 1024)).toFixed(1)}MB`,
      currentOperation: response.progress === 100 ? 'Upload complete' : 'Uploading file...',
      objectId: response.response.objectId,
      location: response.response.location,
      rootFileName: response.rootFileName
    });
  }

  handleFinalizeResponse(response: FinalizeResponse, fileName: string) {
    if (response.isSuccess) {
      this.updateProgress(fileName, {
        stage: UploadStage.PROCESSING,
        progress: 100,
        message: 'Upload finalized successfully',
        currentOperation: 'Starting processing...'
      });
    } else {
      this.updateProgress(fileName, {
        stage: UploadStage.ERROR,
        error: 'Failed to finalize upload'
      });
    }
  }

  handleManifestResponse(response: ManifestResponse, fileName: string) {
    console.log('Manifest Response:', response);
    console.log('Progress Value:', response.progress);
    console.log('Original File Name:', fileName);

    // Clean up the file name by removing Guid and Copy parts
    const cleanFileName = (name: string): string => {
      let cleanName = name;
      const extension = name.substring(name.lastIndexOf('.'));
      const nameWithoutExtension = name.substring(0, name.lastIndexOf('.'));

      // First check for Copy pattern
      if (nameWithoutExtension.includes('Copy')) {
        const copyStartIndex = nameWithoutExtension.indexOf('C');
        const guidEndIndex = nameWithoutExtension.lastIndexOf('.');
        cleanName = nameWithoutExtension.substring(0, copyStartIndex - 3) + extension; // -3 for " - "
      }
      // If no Copy but has Guid
      else if (nameWithoutExtension.includes('Guid')) {
        const guidStartIndex = nameWithoutExtension.indexOf('_Guid');
        cleanName = nameWithoutExtension.substring(0, guidStartIndex) + extension;
      }
      // If neither Copy nor Guid exists, return original name
      
      console.log('Original name:', name);
      console.log('Cleaned name:', cleanName);
      return cleanName;
    };

    const progressValue = response.progress;
    const currentProgress = this.progressSubject.value;
    
    // Find the matching file in the progress map
    let matchingFileName = '';
    Array.from(currentProgress.entries()).some(([key]) => {
      if (cleanFileName(key) === cleanFileName(fileName)) {
        matchingFileName = key;
        return true;
      }
      return false;
    });

    // Use the matching file name or fall back to original
    const fileNameToUpdate = matchingFileName || fileName;
    console.log('File name to update:', fileNameToUpdate);

    // Handle "complete" status
    if (progressValue === "complete") {
      console.log('Status: Complete');
      this.updateProgress(fileNameToUpdate, {
        stage: UploadStage.COMPLETED,
        progress: 100,
        message: 'File successfully uploaded and processed',
        currentOperation: 'Complete'
      });
      return;
    }

    // Extract number from progress string if exists
    const numberMatch = progressValue.match(/\d+/);
    console.log('Number Match:', numberMatch);
    
    if (numberMatch) {
      const percentage = parseInt(numberMatch[0]);
      console.log('Extracted Percentage:', percentage);
      console.log('Updating manifest progress to:', percentage);
      
      this.updateProgress(fileNameToUpdate, {
        stage: UploadStage.MANIFEST_PROCESSING,
        progress: percentage,
        message: `Processing: ${percentage}%`,
        currentOperation: 'Processing manifest'
      });

      // Log the updated progress
      const finalProgress = this.progressSubject.value.get(fileNameToUpdate);
      console.log('Final progress state:', finalProgress);
      return;
    }

    // For any other status (when no number found)
    console.log('No number found in progress value');
    this.updateProgress(fileNameToUpdate, {
      stage: UploadStage.MANIFEST_PROCESSING,
      progress: 0,
      message: progressValue || 'Processing',
      currentOperation: 'Processing manifest'
    });
  }

  clearProgress(fileName: string) {
    const current = this.progressSubject.value;
    const updated = new Map(current);
    updated.delete(fileName);
    this.progressSubject.next(updated);
  }

  clearAll() {
    this.progressSubject.next(new Map());
    this.uploadQueue = [];
  }

  public removeProgress(fileName: string): void {
    const currentProgress = this.progressSubject.getValue();
    currentProgress.delete(fileName);
    this.progressSubject.next(new Map(currentProgress));
  }
} 