import React from 'react';
import { Container, Typography, Box } from '@mui/material';
import Footer from '../components/Footer/Footer';
import SecondHeader from '../components/Navbar/SecondHeader';

const TermsOfService = () => {
    return (
        <>

            <Container maxWidth="md" sx={{ mt: 16, mb: 4 }}>
                <Box mb={4}>
                    <Typography variant="h4" component="h1" gutterBottom>
                        Terms of Service
                    </Typography>
                    <Typography variant="h5" component="h2" gutterBottom>
                        1. Acceptance of Terms
                    </Typography>
                    <Typography variant="body1" paragraph>
                        By accessing and using Veewer's services, you agree to be bound by these Terms of Service and all applicable laws and regulations.
                    </Typography>
                    <Typography variant="h5" component="h2" gutterBottom>
                        2. Use of Service
                    </Typography>
                    <Typography variant="body1" paragraph>
                        You agree to use the service only for lawful purposes and in accordance with these Terms. You are responsible for maintaining the confidentiality of your account information.
                    </Typography>
                    <Typography variant="h5" component="h2" gutterBottom>
                        3. User Data
                    </Typography>
                    <Typography variant="body1" paragraph>
                        We respect your privacy and handle your data in accordance with our Privacy Policy. You retain all rights to your data uploaded to our platform.
                    </Typography>
                    <Typography variant="h5" component="h2" gutterBottom>
                        4. Service Modifications
                    </Typography>
                    <Typography variant="body1" paragraph>
                        We reserve the right to modify or discontinue the service at any time, with or without notice.
                    </Typography>
                    <Typography variant="h5" component="h2" gutterBottom>
                        5. Limitation of Liability
                    </Typography>
                    <Typography variant="body1" paragraph>
                        To the maximum extent permitted by law, Veewer shall not be liable for any indirect, incidental, special, consequential, or punitive damages.
                    </Typography>
                </Box>
            </Container>
            <Footer />
        </>
    );
};

export default TermsOfService; 