import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import logo from '../assets/Veewer_black_stripes_E.png';

const EmailConfirmed = () => {
    return (
        <div className="min-h-[80vh] flex flex-col items-center justify-center w-full px-4 sm:px-6 lg:px-8">
            <div className="flex flex-col items-center mb-8">
                <div className="text-center">
                    <div className="flex flex-col items-center">
                        <FontAwesomeIcon 
                            icon={faCheckCircle} 
                            className="text-green-500 text-5xl sm:text-6xl mb-4" 
                        />
                        <h1 className="text-2xl sm:text-3xl font-bold text-gray-900 mb-2">
                            Email Confirmed!
                        </h1>
                    </div>
                    <p className="text-sm sm:text-base text-gray-600 max-w-sm mt-4">
                        Your email has been successfully verified. You can now log in to your account 
                        and start exploring all the features we have to offer.
                    </p>
                </div>
            </div>

            <div className="w-full max-w-md">
                <div className="bg-white p-6 space-y-6">
                    <Link 
                        to="/login"
                        className="w-full h-10 px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium 
                                 text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 
                                 focus:ring-offset-2 focus:ring-blue-500 flex items-center justify-center"
                    >
                        Continue to Login
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default EmailConfirmed;
