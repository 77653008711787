import { AxiosInstance } from "axios"
import {http} from "../../config/axios.config"



export class BaseService{

    _controllerName:string
    _endpointName:string
    request:AxiosInstance
    constructor(controllerName:string,endpointName:string){
        this._controllerName=controllerName
        this._endpointName=endpointName
        this.request=http(this._controllerName,this._endpointName)
    }

    

}