import { GetContentRootPathRequestModel, GetContentRootPathResponseModel } from "../../models/file/get.content.root.path.model";
import { BasePostService } from "../base/base.post.service";
import { BasePutService } from "../base/base.put.service";


export class GetContentRootPathService extends BasePutService<GetContentRootPathRequestModel,GetContentRootPathResponseModel>{
    constructor(controllerName:string="files", endpoint:string="rootPath"){
        super(controllerName, endpoint)
    }

    async GetContentRoot(request: GetContentRootPathRequestModel) {
        const formData = new FormData();
        formData.append("file", request.file);
        formData.append("rootFileName", request.rootFileName || '');
        formData.append("bucketName",request.bucketName || '')
        formData.append("bytesRead",request.bytesRead.toString())
        formData.append("totalBytes",request.totalBytes.toString())
        formData.append("guid",request.guid)

        return await this.PutWithFormDataAsync(formData);
    }
}