import { BrowserRouter, Route, Routes } from "react-router-dom"
import { useState, useEffect } from "react"
import Home from "../pages/Home"
import ObjectDetail from "../pages/object/ObjectDetail"
import ObjectList from "../pages/object/ObjectList"
import DashboardHome from "../pages/dashboard/DashboardHome"
import Register from "../pages/Register"
import LoginSignUp from "../pages/LoginSignUp"
import Upload from "../pages/oneDrive/Upload"
import EmbedCode from "../pages/oneDrive/EmbedCode"
import Privacy from "../pages/Privacy"
import GoogleDriveUpload from "../pages/googleDrive/GoogleDriveUpload"
import GoogleDriveFolderList from "../pages/googleDrive/GoogleDriveFolderList"
import BoxUpload from "../pages/box/BoxUpload"
import BoxFolderList from "../pages/box/BoxFolderList"
import BucketList from "../pages/bucket/BucketList"
import Payment from "../pages/payment/Payment"
import UserProfile from "../pages/user/UserProfile"
import EmailConfirmed from "../pages/EmailConfirmed"
import CombinedComponent from "../pages/oneDrive/Upload"
import ForgotPassword from "../pages/ForgotPassword"
import VerifyPhone from "../components/VerifyPhone/VerifyPhone"
import ResetPassword from "../pages/ResetPassword"
import PhoneVerification from "../pages/PhoneVerification"
import SecondHeader from "../components/Navbar/SecondHeader"
import Examples from "../pages/Examples"
import TokenRenewalHandler from "../pages/TokenRenewalHandler"
import AWSCombinedList from "../pages/aws/AWSCombinedList"
import Catalog from "../pages/Configurator"
import UsingArea  from "../components/UsingArea"
import Component from "../pages/VeewerManual"
import Pricing from "../pages/Pricing"
import AdminLogin from "../pages/AdminLogin"
import WebhookCreator from "../components/WebhookCreator"
import Configurator from "../pages/Configurator"
import { CreateTokenForSampleModelsService } from '../services/sample/create.token.for.sample.models.service'
import ProductExplorer from '../pages/ProductExplorer'
import Contact from "../pages/Contact"
import TermsOfService from "../pages/TermsOfService"




const MainRoutes=()=>{
    // const [successToken, setSuccessToken] = useState(false);

    // useEffect(() => {
    //     const tokenService = new CreateTokenForSampleModelsService()
    //     tokenService.GetAsync().then((response) => {
    //         if (response.isSuccess) {
    //             setSuccessToken(true)
    //         }
    //     })
    // }, [])

    return (
       <BrowserRouter>
        <SecondHeader setIsBox={undefined} />
        <TokenRenewalHandler/>
        <div className="mt-20 h-full">
        <Routes>
            <Route path="/" element={<Home/>}/>
            <Route path="/register" element={<Register/>}/>
            <Route path="/embed" element={<EmbedCode/>}/>
            <Route path="/login" element={<LoginSignUp/>}/>
            <Route path="/storage" element={<ObjectList/>}/>
            <Route path="/tutorials" element={<Component/>}/>
            <Route path="/webhook" element={<WebhookCreator/>} />
            <Route path="/Pricing" element={<Pricing/>}/>
            <Route path="/object" element={<ObjectDetail/>}/>
            <Route path="/oneDrive/models" element={<CombinedComponent/>}/>
            <Route path="/dashboard" element={<DashboardHome/>}/>
            <Route path="/privacy" element={<Privacy/>}/>
            <Route path="/products" element={<Examples />} />
            <Route path="/configurator" element={<Configurator />} />
            <Route path="/googleDrive/upload" element={<GoogleDriveUpload/>}/>
            <Route path="/googleDrive/files" element={<GoogleDriveFolderList/>}/>
            <Route path="/box/upload" element={<BoxUpload/>}/>
            <Route path="/box/files" element={<BoxFolderList/>}/>
            <Route path="/aws/models" element={<AWSCombinedList/>}/>
            <Route path='/storage' element={<BucketList/>}/>
            <Route path="/contact" element={<Contact/>}/>
            <Route path="/payment" element={<Payment />} />
            <Route path="/profile" element={<UserProfile/>}/>
            <Route path="/emailConfirmed" element={<EmailConfirmed/>}/>
            <Route path='/forgotPassword' element={<ForgotPassword/>}/>
            <Route path="/resetPassword" element={<ResetPassword/>}/>
            <Route path='/verifyPhone' element={<PhoneVerification/>}/>
            <Route path="/admin/login" element={<AdminLogin/>}/>
            <Route path="/ekana-sofa" element={<ProductExplorer />} />
            <Route path="/terms-of-service" element={<TermsOfService/>}/>
            {/* <Route path="/googleDrive/embed" element={<GoogleDriveEmbedCode/>}/> */}
         </Routes>
        </div>
       </BrowserRouter>
    )
}

export default MainRoutes