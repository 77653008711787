import React, { useEffect, useState } from 'react';
import { RootState, useAppDispatch, useAppSelector } from '../../store';
import Swal from 'sweetalert2';
import CardItem from '../../components/CardItem';
import IAM from '../../assets/IAM.png';
import IPT from '../../assets/IPT.png';
import ZIP from '../../assets/zip.svg';
import RVT from '../../assets/RVT.jpg';
import DWG from '../../assets/DWG.jpg';
import FBX from '../../assets/FBX.png';
import DWFX from '../../assets/DWFX.png';
import IDW from '../../assets/IDW.png';
import '../../styles/onedrive/upload.css';
import { fetchAllAWSFiles } from '../../store/features/aws/get.all.aws.files.slice';
import { fetchAllAWSFolders } from '../../store/features/aws/get.all.aws.folders.slice';
import CircleLoading from '../../components/Loading/CircleLoading';
import MultipleAWSUploadModal from '../../components/MultipleUploadModal/MultipleAWSUploadModal';
import { SelectableZipFiles } from '../object/ObjectList';
import { fetchAWSIAMFiles } from '../../store/features/aws/get.iam.files.slice';
import { OneDriveFileData } from '../oneDrive/Upload';
import { ProcessingUploadModel, ProcessUploadIterationResponseModel } from '../../models/object/uploadedObject/uploadV2/processing.upload.model';
import { ProcessUploadChunkService } from '../../services/aws/v2/process.aws.upload.chunk.service';
import { SaveAWSFilePathRootService } from '../../services/aws/v2/save.aws.file.path.service';
import { GetAWSFormFileService } from '../../services/aws/v2/get.aws.file.form.service';
import { CheckManifestAWSService } from '../../services/aws/check.manifest.aws.service';
import { FinalizeAWSUploadModelsService } from '../../services/aws/v2/finalize.aws.upload.model.service';

export interface AWSData {
  name: string;
  size: number;
}

const CombinedAWSComponent: React.FC = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showTranslationModal, setShowTranslationModal] = useState<boolean>(false);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [secondSelectedFiles, setSecondSelectedFiles] = useState<AWSData[]>([]);
  const [fileLimit, setFileLimit] = useState(false);
  const [progress, setProgress] = useState(0);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [zipFile, setZipFile] = useState<SelectableZipFiles | undefined>();
  const [zipOption, setOpenZipOption] = useState(false);
  const dispatch = useAppDispatch();
  const awsFiles = useAppSelector((state: RootState) => state.getAllAWSFilesSlice);
  const bucket = sessionStorage.getItem('b') as string;
  const iamFilesSelector = useAppSelector((s: RootState) => s.getAWSIAMFilesSlice)
  const [step, setStep] = useState("")
  const [startToGetChunkStatus, setStartToGetChunkStatus] = useState(false)
  const [chunks, setChunks] = useState<ProcessingUploadModel[]>([])
  const [processUpload, setProcessToUpload] = useState(false)
  const [steppedFileName, setSteppedFileName] = useState("")
  const [manifestStatus, setManifestStatus] = useState("")
  const [waitingToManifest, setWaitingForManifest] = useState(false)
  const [afterToUploadError, setAfterUploadError] = useState("")
  const [isFinishedToLoading, setIsFinishedToLoading] = useState(false)
  const [isContinueProgress, setIsContinueProgress] = useState(false)
  const [isProcessing, setIsProcessing] = useState(false)
  const [selectedFile, setSelectedFile] = useState({ name: "", size: "" });
  const [rootFile, setRootFile] = useState("")

  useEffect(() => {
    dispatch(fetchAllAWSFiles(bucket));
    dispatch(fetchAllAWSFolders(bucket));
  }, [dispatch, bucket]);

  const handleUploadFiles = (chosenFiles: File[]) => {
    const uploaded = [...selectedFiles];
    let limitedExceeded = false;

    chosenFiles.map((file) => {
      if (uploaded.findIndex((f) => f.name === file.name) === -1) {
        if (file.name.endsWith('.zip')) {
          setOpenZipOption(true);
        }
        uploaded.push(file);
      }
    });

    if (uploaded.length > 40) {
      Swal.fire({
        title: 'Maximum file upload limit exceeded',
        icon: 'error',
      });
      limitedExceeded = true;
    }

    if (!limitedExceeded) setSelectedFiles(uploaded);
    setFileLimit(uploaded.length >= 40);
  };

  const handleFileEvent = (e: React.ChangeEvent<HTMLInputElement>) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    handleUploadFiles(chosenFiles);
  };

  const handleCloseModal = () => {
    setShowTranslationModal(false)
    setShowModal(false);
    setSelectedFiles([]);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const renderFileIcon = (fileExtension: string) => {
    switch (fileExtension) {
      case 'zip':
        return ZIP;
      case 'iam':
        return IAM;
      case 'ipt':
        return IPT;
      case 'rvt':
        return RVT;
      case 'dwg':
        return DWG;
      case 'fbx':
        return FBX;
      case 'dwfx':
        return DWFX;
      case 'idw':
        return IDW;
      default:
        return "";
    }
  };

  const handleFileDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const items = event.dataTransfer.items;
    let containsDirectory = false;

    for (let i = 0; i < items.length; i++) {
      const item = items[i].webkitGetAsEntry();
      if (item && item.isDirectory) {
        containsDirectory = true;
        break;
      }
    }

    if (containsDirectory) {
      alert('You cannot drag folders. Please drag only files.');
    } else {
      const files = Array.prototype.slice.call(event.dataTransfer.files);
      handleUploadFiles(files);
    }
  };

  const handleTranslateFile = (file: OneDriveFileData) => {
    setSecondSelectedFiles([file]);
    setShowTranslationModal(true);
  };

  function generateGUID(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = (Math.random() * 16) | 0,
        v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  const getFile = async (fileName: string, bucketName: string, folderName: string) => {
    const service = new GetAWSFormFileService()
    const response = await service.GetFileWithPostRequestAsync({
      fileName,
      bucketName,
      folderName
    })
    return response
  }

  const getChunksAsync = async (file: OneDriveFileData): Promise<ProcessingUploadModel | null> => {

    if (file !== undefined) {
      setStep("Preparing to upload...")
      const fileFormResponse = await getFile(file.name, bucket, `${bucket}`)
      console.log(fileFormResponse)
      if (fileFormResponse !== undefined || fileFormResponse !== null) {
        const guid = generateGUID()
        setStartToGetChunkStatus(true)
        const service = new SaveAWSFilePathRootService()

        const firstChunkSize = 81920;
        let progress = 0;
        let bytesRead = 0;
        const totalBytes = fileFormResponse.size
        let firstFileName = ""
        let firstPath = ""
        let completed = false

        while (completed === false) {
          const startByte = bytesRead;
          const endByte = Math.min(startByte + firstChunkSize, totalBytes);
          if (startByte >= totalBytes) {
            console.error("Start byte exceeds total bytes. Breaking the loop.");
            break;
          }
          const chunkBlob = fileFormResponse.slice(startByte, endByte);
          if (chunkBlob.size === 0) {
            console.error("Chunk blob size is zero. Breaking the loop.");
            break;
          }
          const chunkFile = new File([chunkBlob], fileFormResponse.name, { type: fileFormResponse.type });
          const response = await service.GetContentRoot({
            rootFileName: file.rootFileName || "",
            file: chunkFile,
            bucketName: bucket,
            totalBytes: totalBytes,
            bytesRead: bytesRead,
            guid: guid
          });
          console.log(response)
          bytesRead = response.bytesRead;
          progress = response.progress;
          console.log(progress)
          setStep(`${progress}% Preparing to upload...`)
          setProgress(progress)

          completed = response.completed;
          firstFileName = response.fileName
          firstPath = response.path

        }




        const fileName = fileFormResponse.name.replace(/[^a-zA-Z0-9]/g, '');
        const cleanFileName = fileName.substring(0, 50);
        const sessionId = `SI${cleanFileName}${Math.floor(Math.random() * 100000)}`;

        const chunkSize = 5 * 1024 * 1024; // 5 MB
        const length = fileFormResponse.size;
        const chunkCount = Math.round(0.5 + (length / chunkSize));

        console.log(file)

        const val: ProcessingUploadModel = {
          dataLength: length,
          chunkCount: chunkCount,
          dataWrited: 0,
          startByte: 0,
          chunkIndex: 0,
          sessionId: sessionId,
          saveFileName: firstFileName,
          saveFilePath: firstPath,// Backend'de belirlenecek
          rootFileName: file.rootFileName !== null || file.rootFileName !== "" ? file.rootFileName : "",
          bucketKey: bucket
        }
        chunks.push(val)
        return val
      }

      else {
        return null;
      }
    } else {
      return null
    }

  };


  const processToChunksAsync = async (chunk: ProcessingUploadModel) => {
    setProcessToUpload(true)
    try {
      let iterationParameter: ProcessUploadIterationResponseModel = {
        startByte: chunk.startByte,
        response: undefined,
        progress: 0,
        dataWrited: 0
      }

      for (let i = 0; i < chunk.chunkCount; i++) {
        const service = new ProcessUploadChunkService()
        setSteppedFileName(chunk.saveFileName as string)
        if (i === chunk.chunkIndex) {
          console.log(chunk)
          iterationParameter = await service.PostAsync(chunk)
          console.log(iterationParameter)
        }
        else {
          const newChunk: ProcessingUploadModel = {
            startByte: iterationParameter.startByte,
            saveFileName: chunk.saveFileName,
            chunkIndex: chunk.chunkIndex,
            chunkCount: chunk.chunkCount,
            dataWrited: iterationParameter.dataWrited,
            sessionId: chunk.sessionId,
            saveFilePath: chunk.saveFilePath,
            bucketKey: chunk.bucketKey,
            dataLength: chunk.dataLength,
            rootFileName: chunk.rootFileName
          }
          console.log(newChunk)
          iterationParameter = await service.PostAsync(newChunk)
          console.log(iterationParameter)
        }
        setProgress(iterationParameter.progress)
        setStep(`${iterationParameter.progress}% Uploading...`)
        if (iterationParameter.progress === 100) {
          break;
        }
      }

      if (iterationParameter.progress === 100) {
        return iterationParameter
      }
    } catch (error: any) {
      console.error("Error:", error);
      setAfterUploadError(error.message !== undefined ? error.message : error.statusText)
      // break;
    }
  }

  const finalizeUploadAsync = async (list: ProcessUploadIterationResponseModel, mainFileName: string) => {
    setProcessToUpload(false)
    const finalizeService = new FinalizeAWSUploadModelsService()
    const finalizeResponse = await finalizeService.PostAsync({
      list: [list],
      mainFileName: mainFileName
    })
    return finalizeResponse
  }



  // Manifest kontrolünü yapan fonksiyon
  const checkManifestStatus = async (
    urns: string[],
    files: string[],
    uid: string,
    mainFileName: string
  ) => {

    setStep("Translate starting...")
    setManifestStatus("Translate starting...")
    const manifestService = new CheckManifestAWSService()
    let isRequestInProgress = false; // İstek durumu flag'i
    let requestTimeoutId: NodeJS.Timeout | null = null; // İstek zaman aşımı için timeout ID'si
    const REQUEST_TIMEOUT = 60000; // 60 saniye
    const INTERVAL_DELAY = 10000; // 10 saniye

    const intervalId = setInterval(async () => {
      if (!urns || urns.length === 0 || !uid) {
        console.log("Gerekli veriler mevcut değil.");
        clearInterval(intervalId); // Gerekli veriler yoksa interval'i durdur
        return;
      }
      if (isRequestInProgress) {
        console.log("İstek zaten devam ediyor, bekleniyor...");
        return;
      }
      isRequestInProgress = true;
      requestTimeoutId = setTimeout(() => {
        console.log("İstek 1 dakikadan uzun sürdü, yeni istek yapılabilir.");
        isRequestInProgress = false;
      }, REQUEST_TIMEOUT);

      try {
        const response = await manifestService.GetByStringManyParamsAsync([urns[0], files[0], uid]);
        if (response.progress === "complete") {
          setProcessToUpload(false)
          setStep("Finishing...")
          setManifestStatus("Finishing...");
          //dispatch(resetCreateState())
          //setSelectedFiles([])
          setIsFinishedToLoading(true)
          clearInterval(intervalId); // Interval'i durdur
          if (requestTimeoutId) {
            clearTimeout(requestTimeoutId);
          }
        } else {
          setStep(response.progress);
          setManifestStatus(response.progress);
        }
        setIsProcessing(false)
        setWaitingForManifest(true)
      } catch (error: any) {
        console.error("Error:", error);
        setAfterUploadError(error.message !== undefined ? error.message : error.statusText)
      } finally {
        isRequestInProgress = false;

        if (requestTimeoutId) {
          clearTimeout(requestTimeoutId);
          requestTimeoutId = null;
        }
      }
    }, INTERVAL_DELAY);
  };


  const processQueue = async (index: number) => {
    if (secondSelectedFiles.length > 0) {
      setWaitingForManifest(false);
      setIsProcessing(true);
      while (index < secondSelectedFiles.length) {
        const currentFile = secondSelectedFiles[index];
        console.log(currentFile)
        if (currentFile !== undefined) {
          const chunks = await getChunksAsync({
            id: "",
            name: secondSelectedFiles[index].name,
            size: secondSelectedFiles[index].size,
            rootFileName: rootFile
          });
          console.log(chunks);
          if (chunks !== null && chunks !== undefined) {
            var processChunkResponse = await processToChunksAsync(chunks as ProcessingUploadModel);
            console.log(processChunkResponse);
            const { urns, files, uid } = await finalizeUploadAsync(processChunkResponse as ProcessUploadIterationResponseModel, currentFile.name);
            await checkManifestStatus(urns, files, uid, currentFile.name);
          }
        }
        index++;
      }

      setIsProcessing(false);
    }
  };

  return (
    <>
      {
        awsFiles.loading ? (
          <CircleLoading text={'Trying to open models...'} />
        ) : (
          <>
            {(
              <>
                <div className='layout-grid-folder-list  mx-[20px] !p-8'>
                  {awsFiles.data &&
                    awsFiles.data.files &&
                    awsFiles.data.files.length > 0 && awsFiles.data.files.map((file) => {
                      const fileExtension = file.name.split('.').pop();
                      const fileIcon = renderFileIcon(fileExtension as string);
                      return (
                        <CardItem
                          isActive={true}
                          imageName={
                            fileIcon as string
                          }
                          title={file.name}
                          buttonNames={[]}
                          buttonEvents={[]}
                          onDelete={() => { }}
                          onClickToCard={() => {

                            if (file.name.includes(".zip")) {
                              dispatch(fetchAWSIAMFiles({
                                bucketName: bucket,
                                fileName: file.name
                              }))
                              let oneDriveData: OneDriveFileData = {
                                id: "",
                                name: file.name,
                                size: file.size as number,
                                rootFileName: ""
                              }
                              handleTranslateFile(oneDriveData);
                            }
                            if (showTranslationModal !== true) {
                              setShowTranslationModal(true);
                            }
                            setSecondSelectedFiles((prevSelectedFiles) => {
                              const condition = prevSelectedFiles.find(
                                (selectedfile) => selectedfile.name === file.name
                              ) === undefined;
                              console.log(condition)
                              if (condition) {
                                return [...prevSelectedFiles, {
                                  name: file.name,
                                  size: file.size!,
                                }];
                              }
                              return prevSelectedFiles;
                            });
                          }}
                        />
                      )

                    })}
                </div>
              </>
            )}
            {showTranslationModal ? (
              <MultipleAWSUploadModal
                fileList={secondSelectedFiles}
                setFileList={setSecondSelectedFiles}
                show={showTranslationModal}
                onHide={() => setShowTranslationModal(false)}
                bucket={bucket}
                iamFileList={iamFilesSelector.data ? iamFilesSelector.data : undefined}
                zipOption={zipOption}
                setOpenZipOption={setOpenZipOption}
                zipFile={zipFile}
                setZipFile={setZipFile}
                processQueue={processQueue}
                step={step}
                processToUpload={processUpload}
                manifestStatus={manifestStatus}
                waitingToManifest={waitingToManifest}
                isContinueToProgress={isContinueProgress}
                setIsContinueToProgress={setIsContinueProgress}
                isFinishedToLoading={isFinishedToLoading}
                rootFile={rootFile}
                setRootFile={setRootFile}

              />
            ) : (
              <></>
            )}
          </>
        )

      }
    </>
  )
}

export default CombinedAWSComponent