import {http} from "../../config/axios.config";
import { BaseModel } from "../../models/base/base.model";
import { BaseService } from "./base.service";



export class BasePostService<TRequest extends BaseModel,TResponse extends BaseModel> extends BaseService{
    async PostAsync(requestBody:TRequest):Promise<TResponse>{
        const request=http(this._controllerName,this._endpointName)
   
        const response=await request.post<TResponse>("",requestBody)

        return response.data
           
             
        
    }  

    async PostWithNoDataAsync():Promise<TResponse>{
        const request=http(this._controllerName,this._endpointName)
   
        const response=await request.post<TResponse>("")

        return response.data
           
             
        
    }  
}