import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BaseState } from "../../../commons/base.state";
import { GetAllUsersService } from "../../../services/user/get.all.users.service";
import { GetAllUsersModel } from "../../../models/user/get.all.users.model";





interface GetAllUsersState extends BaseState<GetAllUsersModel>{

}

let initialState:GetAllUsersState={
    data:null,
    loading:false,
    error:null
}

// interface DeleteUserPayload {
//     userName:string
//   }

export const fetchAllUsers=createAsyncThunk("fetch/AllUsers",async()=>{

    const service=new GetAllUsersService()

    const response=await service.GetAsync()

    return response
})

export const getAllUsersSlice=createSlice({
    name:"getAllUsersSlice",
    initialState,
    reducers:{
        // updateWithDeleteObject: (state, action: PayloadAction<DeleteUserPayload>) => {
        //     if (state.data) {
        //       // Silinen öğeyi listeden çıkar
        //       state.data.users = state.data.users.filter(
        //         (item) => item !== action.payload.userName
        //       );
        //     }
        //   },
    },
    extraReducers(builder) {
        builder
        .addCase(fetchAllUsers.pending,(state,action)=>{
            state.loading=true
        })
        .addCase(fetchAllUsers.fulfilled,(state,action)=>{
            state.loading=false
            state.data=action.payload
        })
        .addCase(fetchAllUsers.rejected,(state,action:PayloadAction<any>)=>{
            state.loading=false
            state.error=action.payload
        })
    },
})

export default getAllUsersSlice.reducer