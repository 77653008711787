'use client'

import { useState, useEffect } from 'react'

interface CircleLoadingProps {
  text: string
  fileName?: string
}

export default function CircleLoading({ text, fileName = "" }: CircleLoadingProps) {
  const [progress, setProgress] = useState(0)
  const [remainingText, setRemainingText] = useState('')
  const [displayFileName, setDisplayFileName] = useState('')
  const [fileExtension, setFileExtension] = useState('')

  useEffect(() => {
    const percentageMatch = text.match(/(\d+)%/)
    if (percentageMatch) {
      setProgress(parseInt(percentageMatch[1], 10))
      const remainingTextMatch = text.match(/\d+%(.*)/);
      setRemainingText(remainingTextMatch ? remainingTextMatch[1].trim() : '')
    } else {
      setProgress(0)
      setRemainingText('')
    }

    if (fileName) {
      const underscoreIndex = fileName.indexOf('_')
      const dotIndex = fileName.lastIndexOf('.')
      let name = underscoreIndex !== -1 ? fileName.slice(0, underscoreIndex) : fileName.slice(0, dotIndex)
      name = name.length > 15 ? name.slice(0, 15) + '...' : name
      setDisplayFileName(name)
      setFileExtension(fileName.slice(dotIndex))
    }
  }, [text, fileName])

  const showProgressBar = text.includes('%')

  const waveKeyframes = `
    @keyframes wave {
      0% { transform: translateX(-100%) }
      50% { transform: translateX(-50%) }
      100% { transform: translateX(0) }
    }
  `

  const waveStyle = {
    animation: 'wave 2s linear infinite',
    background: 'linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.3), transparent)',
    width: '200%',
    height: '100%',
  }

  return (
    <div className="flex flex-col items-center justify-center w-full max-w-md gap-4">
      <style>{waveKeyframes}</style>
      {fileName && (
        <div className="w-full bg-gray-100 p-2 rounded text-center">
          <span className="text-lg">
            {displayFileName}
            <span className="text-gray-500 ml-1">{fileExtension}</span>
          </span>
        </div>
      )}
      {showProgressBar ? (
        <div className="w-full">
          {remainingText && (
            <span className="block text-sm text-gray-600 mb-2">
              {remainingText.includes("complete") ? "Translation completes..." : remainingText}
            </span>
          )}
          <div className="w-full bg-gray-200 rounded-full h-8 overflow-hidden relative">
            <div 
              className="h-full transition-all duration-300 ease-in-out relative overflow-hidden bg-blue-600"
              style={{ width: `${progress}%` }}
            >
              <div className="absolute inset-0" style={waveStyle}></div>
              <div className="absolute inset-0 flex items-center justify-end px-3">
                <span className="text-xs font-medium text-white">
                  {progress}%
                </span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex items-center justify-center space-x-2">
          <div className="animate-spin rounded-full h-10 w-10 border-t-2 border-b-2 border-blue-500" />
          <span className="text-lg text-center">{text}</span>
        </div>
      )}
    </div>
  )
}

