import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Modal } from 'react-bootstrap';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CardItem from '../../components/CardItem';
import ConfirmModal from '../../components/Modals/ConfirmDeleteModal';
import MultipleUploadModal from '../../components/MultipleUploadModal/MultipleUploadModal';
import TextAreaModal from '../../components/Modals/TextAreaModal';
import Unauthorized from '../../components/Unauthorized';
import { GetSubUploadedObjectResponseModel, GetUploadedObjectResponseModel } from '../../models/object/uploadedObject/get.uploadedObject.response.model';
import { GetUploadedObjectHtmlContentService } from '../../services/uploadedObject/get.uploadedObject.html.content.service';
import { GetUploadedObjectModelViewService } from '../../services/uploadedObject/get.uploadedObject.model.view.service';
import { RootState, useAppDispatch, useAppSelector } from '../../store';
import { getUploadedObjectByUserId, getUploadedObjects, updateWithDeleteObject } from '../../store/features/object/get.list.uploadedObject.slice';
import IAM from '../../assets/IAM.png';
import IPT from '../../assets/IPT.png';
import ZIP from '../../assets/zip.svg';
import DWFX from '../../assets/DWFX.png'
import DWG from '../../assets/DWG.jpg';
import FBX from '../../assets/FBX.png';
import MODEL from '../../assets/MODEL.png';
import MAX from '../../assets/MAX.png';
import STL from '../../assets/STL.png'
import STP from '../../assets/STP.png'
import SLDASM from '../../assets/SLDASM.png'
import RVT from '../../assets/RVT.jpg'
import DWF from '../../assets/DWF.png'
import F3D from '../../assets/f3d.png'
import GLB from '../../assets/GLB.png'
import OBJ from '../../assets/obj.png'
import PSM from '../../assets/psm.png'
import STEP from "../../assets/STEP.png"
import IDW from "../../assets/IDW.png"
import UNDEFINEDIMAGE from "../../assets/undefined.png"
import { baseUrl } from '../../config/axios.config';
import '../../styles/objectList/objectList.css';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { LoginResponse } from '../../models/auth/login.model';
import { deleteUploadedObject } from '../../store/features/object/delete.object.slice';
import { UploadedFile } from '../../models/object/uploadedObject/put.uploadedObject.request.model';
import JSZip from 'jszip';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, List, ListItem, ListItemText, Typography } from '@mui/material';
import CircleLoading from '../../components/Loading/CircleLoading';
import { checkCreditUploadedObject, resetCreditError } from '../../store/features/object/check.credit.slice';
import { checkCreditUploadedObjectWhenUpload } from '../../store/features/object/check.credit.when.upload.slice';
import ChangeNameModal from '../../components/Modals/ChangeNameModal';
import { updateUploadedObjectName } from '../../store/features/object/update.uploaded.object.name.slice';
import { DeleteModelsService } from '../../services/file/delete.models.service';
import { CheckManifestUploadedObjectService } from '../../services/uploadedObject/check.manifest.uploadeObject.service';
import { ProcessingUploadModel, ProcessUploadIterationResponseModel } from '../../models/object/uploadedObject/uploadV2/processing.upload.model';
import { FinalizeUploadModelsService } from '../../services/uploadedObject/uploadV2/finalize.upload.models.service';
import { ProcessingUploadModelService } from '../../services/uploadedObject/uploadV2/processing.upload.model.service';
import { GetContentRootPathService } from '../../services/file/get.content.root.path.service';
import { resetCreateState } from '../../store/features/object/create.uploadedObject.slice';
import path from 'path-browserify';
import { SearchAndFilter } from '../../components/SearchAndFilter';
import { UploadManager, UploadStage, UploadProgress } from '../../services/upload/UploadManager';
import LittleCircleLoading from '../../components/Loading/LittleCircleLoading';
import Footer from '../../components/Footer/Footer';


export interface SelectableZipFiles {
  mainZipFile: File
  subFiles: SelectableSubFiles[]

}

export interface SelectableSubFiles {
  fileName: string
  fileSize: number,
  file: File
}

export interface StatusValues {
  fileName: string,
  status: string,
}

const ObjectList: React.FC = () => {
  const [uploadModal, setUploadModal] = useState(false);
  const [filterModal, setFilterModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [htmlContent, setHtmlContent] = useState('');
  const [webSite, setWebSite] = useState('');
  const [selectedName, setSelectedName] = useState('');
  const [selectedObjectId, setSelectedObjectId] = useState('');
  const [textAreaModal, setTextAreaModal] = useState(false);
  const [fileLimit, setFileLimit] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<UploadedFile[]>([]);
  const [selectedSearchValue, setSelectedSearchValue] = useState('');
  const [selectedDeleteValue, setSelectedDeleteValue] = useState('');
  const [zipFile, setZipFile] = useState<SelectableZipFiles | undefined>(undefined)
  const [openZipOption, setOpenZipOption] = useState(false);
  const [extractZipFileStatus, setExtractZipFileStatus] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const objectListSelectorByUserId = useAppSelector((s: RootState) => s.getUploadedObjectByUserId)
  const selectedObject = useAppSelector((s: RootState) => s.getUploadedObject);
  const selectedObjectByBucket = useAppSelector((s: RootState) => s.getUploadedObjectByBucketAndName);
  const deleteSelectedObject = useAppSelector((s: RootState) => s.deleteObjectSlice)

  const bucketKey = sessionStorage.getItem('b') as string;
  const loginInfo = sessionStorage.getItem('login') as string;
  const jsonLoginInfo = JSON.parse(loginInfo) as LoginResponse
  const [openSupportedFormats, setOpenSupportedFormats] = useState(false);
  
  const supportedFormats = [
    "3DM", "3DS", "A", "ASM", "AXM", "BRD", "CATPART", "CATPRODUCT", "CGR", "COLLABORATION", "DAE", "DDX", "DDZ", "DGK",
    "DGN", "DLV3", "DMT", "DWF", "DWFX", "DWG*", "DWT", "DXF", "EMODEL", "EXP", "F3D", "FBRD", "FBX", "FSCH", "G", "GBXML",
    "GLB", "GLTF", "IAM", "IDW", "IFC", "IGE", "IGES", "IGS", "IPT", "IWM", "JT", "MAX", "MODEL", "MPF", "MSR", "NEU",
    "NEW", "NWC", "NWD", "OBJ", "OSB", "PAR", "PMLPRJ", "PMLPRJZ", "PRT", "PSM", "PSMODEL", "RVM", "RVT", "SAB", "SAT",
    "SCH", "SESSION", "SKP", "SLDASM", "SLDPRT", "SMB", "SMT", "STE", "STEP", "STL", "STLA", "STLB", "STP", "STPZ", "VPB",
    "VUE", "WIRE", "X_B", "X_T", "XAS", "XPR"
  ];
  const actualSupportedFormats = [
    "3DM", "3DS", "A", "ASM", "AXM", "BRD", "CATPART", "CATPRODUCT", "CGR", "COLLABORATION", "DAE", "DDX", "DDZ", "DGK",
    "DGN", "DLV3", "DMT", "DWF", "DWFX", "DWG", "DWT", "DXF", "EMODEL", "EXP", "F3D", "FBRD", "FBX", "FSCH", "G", "GBXML",
    "GLB", "GLTF", "ZIP", "IDW", "IFC", "IGE", "IGES", "IGS", "IPT", "IWM", "JT", "MAX", "MODEL", "MPF", "MSR", "NEU",
    "NEW", "NWC", "NWD", "OBJ", "OSB", "PAR", "PMLPRJ", "PMLPRJZ", "PRT", "PSM", "PSMODEL", "RVM", "RVT", "SAB", "SAT",
    "SCH", "SESSION", "SKP", "SLDPRT", "SMB", "SMT", "STE", "STEP", "STL", "STLA", "STLB", "STP", "STPZ", "VPB",
    "VUE", "WIRE", "X_B", "X_T", "XAS", "XPR"
  ];
  const [invalidFileErrorMessage, setInvalidFileErrorMessage] = useState("")
  const checkFileExtension = (file: File): boolean => {
    const fileExtension = file.name.split('.').pop()?.toUpperCase();
    return fileExtension ? actualSupportedFormats.includes(fileExtension) : false;
  };

  const MAX_COUNT = 15;
  const UploadFilesSwal = withReactContent(Swal);
  const [creditWarnings, setCreditWarnings] = useState("")
  const [openChangeModal, setOpenChangeModal] = useState(false)
  const [waitingForManifest, setWaitingForManifest] = useState(true); // Manifest bekleme durumu
  const [currentUploadIndex, setCurrentUploadIndex] = useState(0); //
  const [isFinishedToLoading, setIsFinishedToLoading] = useState(false);
  const [startToGetChunkStatus, setStartToGetChunkStatus] = useState(false);
  const [processToUpload, setProcessToUpload] = useState(false);
  const [steppedFileName, setSteppedFileName] = useState("")
  const [afterUploadError, setAfterUploadError] = useState("");
  const [isProcessing, setIsProcessing] = useState(false); // Mevcut işlem kontrolü
  const startUploadModelsSelector = useAppSelector((s: RootState) => s.startUploadModelsSlice)
  const [chunks, setChunks] = useState<ProcessingUploadModel[]>([])
  const [manifestStatus, setManifestStatus] = useState("");
  const [progress, setProgress] = useState(0);
  const [step, setStep] = useState("");
  const [isStarted, setIsStarted] = useState(false);
  const [processedFiles, setProcessedFiles] = useState<UploadedFile[]>([]);
  const [isProcessingFile, setIsProcessingFile] = useState(false);
  const [statusValues, setStatusValues] = useState<StatusValues[]>([]);
  const [isProcessingCancelled, setIsProcessingCancelled] = useState(false);
  const processingLock = useRef(new Set<string>());
  const processingQueue = useRef<UploadedFile[]>([]);
  const [isProcessingQueue, setIsProcessingQueue] = useState(false);
  const [pausedFiles, setPausedFiles] = useState<UploadedFile[]>([]);
  const [isPaused, setIsPaused] = useState(false);

  const handleCloseModal = async () => {
    const uploadManager = UploadManager.getInstance();
    const allProgress = uploadManager.getCurrentProgress();
    const hasActiveUploads = Array.from(allProgress.values()).some(p => 
      p.stage !== UploadStage.COMPLETED && 
      p.stage !== UploadStage.ERROR && 
      p.stage !== UploadStage.PAUSED
    );
    
    // Eğer aktif yüklemeler varsa ve modal kapatma isteği geliyorsa
    if (hasActiveUploads) {
      const shouldClose = window.confirm("Files are still being processed. Are you sure you want to close?");
      if (!shouldClose) {
        return;
      }
    }

    // İşlemleri iptal et ve tüm state'leri sıfırla
    setIsProcessingCancelled(true);
    setIsProcessingFile(false);
    setIsStarted(false);
    setIsProcessing(false);
    setProcessToUpload(false);
    
    // Bekleyen tüm işlemleri temizle
    uploadManager.clearAll();
    
    // Modal state'lerini sıfırla
    setUploadModal(false);
    setSelectedFiles([]);
    setStep("");
    setCreditWarnings("");
    setWaitingForManifest(true);
    setCurrentUploadIndex(0);
    setIsFinishedToLoading(false);
    setSteppedFileName("");
    setAfterUploadError("");
    setProgress(0);
    setStatusValues([]);
    setManifestStatus("");
    setStartToGetChunkStatus(false);
    setProcessedFiles([]);
    
    // Eğer tüm dosyalar tamamlandıysa listeyi güncelle
    const allCompleted = Array.from(allProgress.values()).every(p => p.stage === UploadStage.COMPLETED);
    if (allCompleted) {
      await dispatch(getUploadedObjectByUserId(jsonLoginInfo.uid));
    }
  };

  // Browser'ın beforeunload event listener'ını ekle
  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      const uploadManager = UploadManager.getInstance();
      const allProgress = uploadManager.getCurrentProgress();
      const hasActiveUploads = Array.from(allProgress.values()).some(p => 
        p.stage !== UploadStage.COMPLETED && 
        p.stage !== UploadStage.ERROR && 
        p.stage !== UploadStage.PAUSED
      );

      if (hasActiveUploads) {
        e.preventDefault();
        e.returnValue = '';
        return '';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, []);

  function generateGUID(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = (Math.random() * 16) | 0,
        v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  const processZipFile = async (file: File) => {
    const zip = new JSZip();
    const zipContent = await zip.loadAsync(file);
    let subFiles: SelectableSubFiles[] = [];
    setExtractZipFileStatus(true)
    for (const relativePath of Object.keys(zipContent.files)) {
      const f = zipContent.files[relativePath];
      if (!f.dir) {
        const fileName = f.name.split('/').pop() as string;
        const fileSizeInKB = ((f as any)._data.uncompressedSize / 1024).toFixed(2);

        const fileBlob = await f.async("blob");
        const file = new File([fileBlob], fileName, { type: fileBlob.type });

        if (true) {
          if (file.name.includes("\\")) {
            var splittedFileName = file.name.split("\\");
            var actualFileName = splittedFileName[splittedFileName.length - 1]
            subFiles.push({
              file,
              fileName: actualFileName,
              fileSize: Number(fileSizeInKB)
            });
          } else {
            subFiles.push({
              file,
              fileName,
              fileSize: Number(fileSizeInKB)
            });
          }

        }
      }
    }
    setZipFile(() => {
      return {
        mainZipFile: file,
        subFiles
      };
    });
    setExtractZipFileStatus(false)
    return zipFile;
  };

  useEffect(() => {
    if (!isStarted || isProcessingCancelled) return;

    const processUnprocessedFiles = async () => {
      if (isProcessingFile) return;

      const uploadManagerInstance = UploadManager.getInstance();
      const currentProgress = uploadManagerInstance.getCurrentProgress();

      // İşlenmemiş dosyaları filtrele ve sıralı işle
      const unprocessedFiles = selectedFiles.filter(file => {
        const progress = currentProgress.get(file.file.name);
        // Dosya henüz işlenmemişse veya QUEUED durumundaysa
        return !progress || progress.stage === UploadStage.QUEUED;
      });

      if (unprocessedFiles.length > 0) {
        setIsProcessingFile(true);
        
        try {
          // İlk dosyayı işle
          const nextFile = unprocessedFiles[0];
          const currentFileProgress = currentProgress.get(nextFile.file.name);
            
          // Dosya zaten işleniyorsa veya hata aldıysa atla
          if (!(currentFileProgress?.stage === UploadStage.ERROR || 
              currentFileProgress?.stage === UploadStage.PAUSED ||
              currentFileProgress?.stage === UploadStage.COMPLETED)) {
            await processFiles([nextFile]);
          }
        } catch (error) {
          console.error('Error processing files:', error);
        } finally {
          setIsProcessingFile(false);
        }
      }
    };

    processUnprocessedFiles();
  }, [isStarted, selectedFiles, isProcessingCancelled, isProcessingFile]);

  const processFiles = async (files: UploadedFile[]) => {
    if (isProcessingCancelled || !isStarted || isProcessingFile) return;

    const uploadManager = UploadManager.getInstance();
    
    // First, validate all files
    const validFiles = files.filter(file => {
      const isValid = checkFileExtension(file.file);
      if (!isValid) {
        uploadManager.updateProgress(file.file.name, {
          stage: UploadStage.ERROR,
          error: `Invalid file format: ${file.file.name}`,
          message: 'Unsupported file format'
        });
        console.error(`Invalid file format: ${file.file.name}`);
        return false;
      }
      return true;
    });

    try {
      // Process files sequentially
      for (const file of validFiles) {
        if (isProcessingCancelled || !isStarted) {
          break;
        }

        const fileExtension = file.file.name.split('.').pop()?.toUpperCase() || '';
        
        // Check credits
        uploadManager.updateProgress(file.file.name, {
          stage: UploadStage.PREPARING,
          message: 'Checking available credits...'
        });

        try {
          const creditCheckResponse = await fetch(
            `${baseUrl}objects/creditStatus/${jsonLoginInfo.uid}/${fileExtension}`,
            {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json'
              }
            }
          );

          if (!creditCheckResponse.ok) {
            const errorData = await creditCheckResponse.json().catch(() => ({}));
            uploadManager.updateProgress(file.file.name, {
              stage: UploadStage.ERROR,
              error: errorData.message || 'Failed to check credits',
              message: 'Credit check failed'
            });
            continue;
          }

          const creditStatus = await creditCheckResponse.json();
          if (!creditStatus.status) {
            uploadManager.updateProgress(file.file.name, {
              stage: UploadStage.PAUSED,
              error: creditStatus.message || 'Insufficient credits',
              message: 'Upload paused - Insufficient credits'
            });
            
            setPausedFiles(prev => [...prev, file]);
            setAfterUploadError(creditStatus.message || 'Insufficient credits');
            continue;
          }

          // Process file if credit check passed
          uploadManager.updateProgress(file.file.name, {
            stage: UploadStage.PREPARING,
            message: 'Credit check successful, starting upload...'
          });

          await handleFileProcessing(file);
          setProcessedFiles(prev => [...prev, file]);

        } catch (error) {
          console.error(`Error processing file ${file.file.name}:`, error);
          uploadManager.updateProgress(file.file.name, {
            stage: UploadStage.ERROR,
            error: error instanceof Error ? error.message : "Processing failed",
            message: 'Failed to process file'
          });
          continue;
        }
      }

      // Tüm dosyalar işlendiyse durumu güncelle
      const allProgress = uploadManager.getCurrentProgress();
      const remainingFiles = Array.from(allProgress.values()).filter(p => 
        p.stage !== UploadStage.COMPLETED && 
        p.stage !== UploadStage.ERROR && 
        p.stage !== UploadStage.PAUSED
      );

      if (remainingFiles.length === 0) {
        setIsStarted(false);
        setIsPaused(false);
      }

    } catch (error) {
      console.error('Error in processFiles:', error);
    }
  };

  const handleFileInput = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    const files: File[] = Array.from(e.target.files || []);
    const uploadManager = UploadManager.getInstance();
    let newFilesAdded = false;

    for (const file of files) {
      if (file.name.endsWith('.zip')) {
        await processZipFile(file);
        setOpenZipOption(true);
      } else {
        const newFile = { file, rootFileName: "" };
        setSelectedFiles(prev => [...prev, newFile]);
        newFilesAdded = true;
        
        // Yeni dosyayı kuyruğa al
        uploadManager.updateProgress(file.name, {
          stage: UploadStage.QUEUED,
          message: 'Ready to upload',
          currentOperation: 'Waiting to start'
        });
      }
    }
    e.target.value = '';

    // Eğer yeni dosya eklendiyse
    if (newFilesAdded) {
      // İşlem başlatılmamışsa başlat
      if (!isStarted) {
        setIsStarted(true);
      }
      // İşlem devam ediyorsa veya yeni başlatıldıysa
      setIsProcessingFile(false);
    }
  };

  const handleFileInputWithDrop = async (e: React.DragEvent<HTMLDivElement>): Promise<void> => {
    e.preventDefault();
    const files: File[] = Array.from(e.dataTransfer.files || []);
    for (const file of files) {
      if (file.name.endsWith('.zip')) {
        await processZipFile(file);
        setOpenZipOption(true);
      } else {
        const newFile = { file, rootFileName: "" };
        setSelectedFiles(prev => [...prev, newFile]);
      }
    }
    e.dataTransfer.clearData();
  };

  const processNextInQueue = async () => {
    if (processingQueue.current.length === 0 || isProcessingQueue) {
      setIsProcessingQueue(false);
      return;
    }

    setIsProcessingQueue(true);
    const nextFile = processingQueue.current[0];
    
    try {
      await handleFileProcessing(nextFile);
    } catch (error) {
      console.error('Error processing file:', error);
    }

    processingQueue.current.shift();
    setIsProcessingQueue(false);
    
    // Process next file if queue is not empty
    if (processingQueue.current.length > 0) {
      await processNextInQueue();
    }
  };

  const handleFileProcessing = async (file: UploadedFile) => {
    const uploadManager = UploadManager.getInstance();

    try {
      // 2. Prepare file chunks
      uploadManager.updateProgress(file.file.name, {
        stage: UploadStage.PREPARING,
        message: 'Preparing file for upload...',
        progress: 0
      });

      const guid = generateGUID();
      const chunks = await getChunksAsync(file, guid);
      if (!chunks) {
        uploadManager.updateProgress(file.file.name, {
          stage: UploadStage.ERROR,
          error: `Failed to prepare chunks for file: ${file.file.name}`
        });
        throw new Error(`Failed to prepare chunks for file: ${file.file.name}`);
      }
      
      // 4. Upload chunks
      uploadManager.updateProgress(file.file.name, {
        stage: UploadStage.UPLOADING,
        message: 'Starting upload...',
        progress: 0
      });

      const uploadResult = await processToChunksAsync(chunks);
      if (!uploadResult || uploadResult.progress < 100) {
        uploadManager.updateProgress(file.file.name, {
          stage: UploadStage.ERROR,
          error: `Failed to upload file: ${file.file.name}`
        });
        throw new Error(`Failed to upload file: ${file.file.name}`);
      }

      // 5. Finalize upload
      uploadManager.updateProgress(file.file.name, {
        stage: UploadStage.PROCESSING,
        message: 'Starting finalization...',
        progress: 0
      });

      try {
        const finalizeService = new FinalizeUploadModelsService();
        const finalizeResponse = await finalizeService.PostAsync([uploadResult]);
        
        if (!finalizeResponse) {
          uploadManager.updateProgress(file.file.name, {
            stage: UploadStage.ERROR,
            error: `Failed to finalize upload for file: ${file.file.name}`
          });
          throw new Error(`Failed to finalize upload for file: ${file.file.name}`);
        }

        uploadManager.updateProgress(file.file.name, {
          stage: UploadStage.MANIFEST_PROCESSING,
          message: 'Upload finalized successfully, starting manifest processing...',
          progress: 0
        });

        // 6. Start and monitor manifest processing
        const manifestService = new CheckManifestUploadedObjectService();
        const { urns, files, uid } = finalizeResponse;
        
        try {
          await checkManifestStatus(urns, files, uid);
          uploadManager.updateProgress(file.file.name, {
            stage: UploadStage.COMPLETED,
            message: 'File successfully processed',
            progress: 100
          });
        } catch (error) {
          console.error('Manifest processing error:', error);
          uploadManager.updateProgress(file.file.name, {
            stage: UploadStage.ERROR,
            error: error instanceof Error ? error.message : 'Failed to process manifest'
          });
          throw error;
        }

      } catch (error) {
        console.error('Finalize error:', error);
        uploadManager.updateProgress(file.file.name, {
          stage: UploadStage.ERROR,
          error: error instanceof Error ? error.message : 'Failed to finalize upload'
        });
        throw error;
      }

    } catch (error) {
      console.error(`Error processing file: ${file.file.name}`, error);
      uploadManager.updateProgress(file.file.name, {
        stage: UploadStage.ERROR,
        error: error instanceof Error ? error.message : "Unknown error occurred"
      });
      setAfterUploadError(
        error instanceof Error ? error.message : "Unknown error occurred."
      );
      throw error;
    }
  };
  


  const getChunksAsync = async (file: UploadedFile, guid: string): Promise<ProcessingUploadModel | null> => {
    if (file !== undefined) {
      const uploadManager = UploadManager.getInstance();
      const service = new GetContentRootPathService();
      const firstChunkSize = 81920;
      let bytesRead = 0;
      const totalBytes = file.file.size;
      let firstFileName = "";
      let firstPath = "";
      let completed = false;

      while (completed === false) {
        const startByte = bytesRead;
        const endByte = Math.min(startByte + firstChunkSize, totalBytes);
        if (startByte >= totalBytes) {
          console.error("Start byte exceeds total bytes. Breaking the loop.");
          break;
        }
        const chunkBlob = file.file.slice(startByte, endByte);
        if (chunkBlob.size === 0) {
          console.error("Chunk blob size is zero. Breaking the loop.");
          break;
        }
        const chunkFile = new File([chunkBlob], file.file.name, { type: file.file.type });
       
        const response = await service.GetContentRoot({
          rootFileName: file.rootFileName || "",
          file: chunkFile,
          bucketName: bucketKey,
          totalBytes: totalBytes,
          bytesRead: bytesRead,
          guid: guid
        });

        // Update progress using the rootPath response handler
        uploadManager.handleRootPathResponse(response, file.file.name);

        bytesRead = response.bytesRead;
        completed = response.completed;
        firstFileName = response.fileName;
        firstPath = response.path;
      }

      const fileName = file.file.name.replace(/[^a-zA-Z0-9]/g, '');
      const cleanFileName = fileName.substring(0, 50);
      const sessionId = `SI${cleanFileName}${Math.floor(Math.random() * 100000)}`;

      const chunkSize = 5 * 1024 * 1024; // 5 MB
      const length = file.file.size;
      const chunkCount = Math.round(0.5 + (length / chunkSize));
      
      return {
        dataLength: length,
        chunkCount: chunkCount,
        dataWrited: 0,
        startByte: 0,
        chunkIndex: 0,
        sessionId: sessionId,
        saveFileName: firstFileName,
        saveFilePath: firstPath,
        rootFileName: file.rootFileName || "",
        bucketKey: bucketKey
      };
    }
    return null;
  };

  const processToChunksAsync = async (chunk: ProcessingUploadModel) => {
    const uploadManager = UploadManager.getInstance();
    try {
      let iterationParameter: ProcessUploadIterationResponseModel = {
        startByte: chunk.startByte,
        response: undefined,
        progress: 0,
        dataWrited: 0
      };

      for (let i = 0; i < chunk.chunkCount; i++) {
        const service = new ProcessingUploadModelService();
        if (i === chunk.chunkIndex) {
          iterationParameter = await service.PostAsync(chunk);
        } else {
          const newChunk: ProcessingUploadModel = {
            startByte: iterationParameter.startByte,
            saveFileName: chunk.saveFileName,
            chunkIndex: chunk.chunkIndex,
            chunkCount: chunk.chunkCount,
            dataWrited: iterationParameter.dataWrited,
            sessionId: chunk.sessionId,
            saveFilePath: chunk.saveFilePath,
            bucketKey: chunk.bucketKey,
            dataLength: chunk.dataLength,
            rootFileName: chunk.rootFileName
          };
          iterationParameter = await service.PostAsync(newChunk);
        }

        // Update progress using the processUpload response handler
        uploadManager.handleProcessUploadResponse(iterationParameter, chunk.saveFileName || '');

        if (iterationParameter.progress === 100) {
          break;
        }
      }

      if (iterationParameter.progress === 100) {
        return iterationParameter;
      }
    } catch (error: any) {
      console.error("Error:", error);
      uploadManager.updateProgress(chunk.saveFileName || '', {
        stage: UploadStage.ERROR,
        error: error.message || error.statusText
      });
      throw error;
    }
  };

  const finalizeUploadAsync = async (list: ProcessUploadIterationResponseModel) => {
    const uploadManager = UploadManager.getInstance();
    try {
      // Add delay before finalization
      await new Promise(resolve => setTimeout(resolve, 3000));

      const finalizeService = new FinalizeUploadModelsService();
      const finalizeResponse = await finalizeService.PostAsync([list]);
      
      // Update progress using the finalize response handler
      if (list.response?.objectKey) {
        uploadManager.handleFinalizeResponse(finalizeResponse, list.response.objectKey);
      }

      // Add delay after finalization before starting manifest check
      await new Promise(resolve => setTimeout(resolve, 5000));
      
      return finalizeResponse;
    } catch (error: any) {
      console.error("Error:", error);
      if (list.response?.objectKey) {
        uploadManager.updateProgress(list.response.objectKey, {
          stage: UploadStage.ERROR,
          error: error.message || "Failed to finalize upload"
        });
      }
      throw error;
    }
  };

  const checkManifestStatus = async (
    urns: string[],
    files: string[],
    uid: string,
  ) => {
    const uploadManager = UploadManager.getInstance();
    
    const getOriginalFileName = (fileName: string) => {
      const cleanName = fileName.replace(/[ -]+Copy\(\d+\)_Guid_[a-f0-9-]+/i, '');
      return cleanName.replace(/_Copy_\d+/g, '')
                     .replace(/_Guid_[a-f0-9-]+/g, '')
                     .replace(/\(\d+\)/g, '')
                     .trim();
    };

    const originalFileName = getOriginalFileName(files[0]);
    const manifestService = new CheckManifestUploadedObjectService();
    let isRequestInProgress = false;
    let requestTimeoutId: NodeJS.Timeout | null = null;
    const REQUEST_TIMEOUT = 60000; // 60 seconds for each request
    const INTERVAL_DELAY = 30000; // Increased to 30 seconds between checks
    let retryCount = 0;
    const MAX_RETRIES = 5; // Increased max retries

    console.log('Starting checkManifestStatus with:', { urns, files, uid });
    console.log('Original file name:', originalFileName);
    console.log('Modified file name:', files[0]);

    // Initial delay before starting manifest checks
    await new Promise(resolve => setTimeout(resolve, 10000)); // Increased initial delay to 10 seconds

    return new Promise((resolve, reject) => {
      const intervalId = setInterval(async () => {
        if (!urns || urns.length === 0 || !uid) {
          console.log('Missing required data:', { urns, uid });
          clearInterval(intervalId);
          reject(new Error("Required data is missing"));
          return;
        }

        if (isRequestInProgress) {
          console.log('Request already in progress, skipping this interval');
          return;
        }

        isRequestInProgress = true;
        requestTimeoutId = setTimeout(() => {
          console.log('Individual request timeout reached');
          isRequestInProgress = false;
        }, REQUEST_TIMEOUT);

        try {
          console.log(`Making manifest request attempt ${retryCount + 1} with:`, [urns[0], files[0], uid]);
          const response = await manifestService.GetByStringManyParamsAsync([urns[0], files[0], uid]);
          console.log('Manifest API Response:', response);
          
          // Reset retry count on successful response
          retryCount = 0;
          
          uploadManager.handleManifestResponse(response, originalFileName);
          
          if (response.progress === "complete") {
            console.log('Manifest processing complete');
            clearInterval(intervalId);
            if (requestTimeoutId) clearTimeout(requestTimeoutId);
            resolve(response);
            return;
          }

        } catch (error: any) {
          console.error("Manifest Error:", error);
          retryCount++;
          
          // Handle specific error cases
          if (error.statusCode === 409) {
            console.log(`Conflict error encountered. Retry attempt ${retryCount} of ${MAX_RETRIES}`);
            // Add exponential backoff for retries
            await new Promise(resolve => setTimeout(resolve, Math.min(1000 * Math.pow(2, retryCount), 30000)));
          }
          
          if (retryCount >= MAX_RETRIES) {
            console.log('Max retries reached, stopping manifest checks');
            clearInterval(intervalId);
            if (requestTimeoutId) clearTimeout(requestTimeoutId);
            uploadManager.updateProgress(originalFileName, {
              stage: UploadStage.ERROR,
              error: "Failed to process manifest after multiple attempts"
            });
            reject(new Error("Max retry attempts reached for manifest processing"));
            return;
          }

          uploadManager.updateProgress(originalFileName, {
            stage: UploadStage.MANIFEST_PROCESSING,
            message: `Retrying manifest check (${retryCount}/${MAX_RETRIES})`,
            error: error.message
          });

        } finally {
          isRequestInProgress = false;
          if (requestTimeoutId) {
            clearTimeout(requestTimeoutId);
            requestTimeoutId = null;
          }
        }
      }, INTERVAL_DELAY);

      // No overall timeout - will continue checking until complete or error
    });
  };  

  useEffect(() => {
    dispatch(getUploadedObjectByUserId(jsonLoginInfo.uid))
  }, [dispatch, jsonLoginInfo.uid])

  const openObjectDetail = (urn: string) => {
    navigate('/object', {
      state: {
        urn
      },
    });
  };

  const getIconsOrThumbnail = (object: GetSubUploadedObjectResponseModel) => {
    const fileType = object.name.split(".")[1]
    if (object.thumbnail === "") {
      switch (fileType) {
        case "iam":
          return IAM

        case "ipt":
          return IPT

        case "dwfx":
          return DWFX

        case "dwg":
          return DWG

        case "fbx":
          return FBX

        case "zip":
          return ZIP

        case "rvt":
          return RVT

        case "dwf":
          return DWF

        case "sldasm":
          return SLDASM

        case "stl":
          return STL
        case 'IDW':
          return IDW;
        case 'idw':
          return IDW
        case "STL":
          return STL

        case "stp":
          return STP

        case "STP":
          return STP

        case "f3d":
          return F3D

        case "max":
          return MAX

        case "model":
          return MODEL

        case "glb":
          return GLB

        case "obj":
          return OBJ

        case "step":
          return STEP

        case "STEP":
          return STEP


        default:
          return UNDEFINEDIMAGE

      }
    }
    else {
      return `data:image/jpeg;base64,${object.thumbnail}`
    }
  }

  const handleUploadClick = () => {
    const uploadManager = UploadManager.getInstance();
    const allProgress = uploadManager.getCurrentProgress();
    const allCompleted = Array.from(allProgress.values()).every(p => p.stage === UploadStage.COMPLETED);
    
    if (allCompleted) {
      uploadManager.clearAll();
    }
    
    if (isPaused && pausedFiles.length > 0) {
      // Devam etme durumu
      setSelectedFiles(pausedFiles);
      setIsStarted(true);
      setIsPaused(false);
    } else {
      setUploadModal(true);
    }
  };

  // Upload button text based on state
  const getUploadButtonText = () => {
    if (isPaused) {
      return 'Continue Upload';
    }
    if (allFilesCompleted()) {
      return 'Upload More Files';
    }
    return 'Upload Files';
  };

  // Check if all files are completed
  const allFilesCompleted = () => {
    const uploadManager = UploadManager.getInstance();
    const allProgress = uploadManager.getCurrentProgress();
    return allProgress.size > 0 && Array.from(allProgress.values()).every(p => p.stage === UploadStage.COMPLETED);
  };

  return loginInfo && jsonLoginInfo.isAuthenticated ? (
    <div className="h-[calc(100vh-80px)] min-h-full z-50 overflow-y-auto">
      <div className="p-6">
        {objectListSelectorByUserId.loading ? (
          <div className='flex flex-col items-center justify-center min-h-[calc(100vh-80px)]  space-y-6'>
            <div className="w-24 h-24 relative">
              <div className="absolute inset-0 border-4 border-blue-100 rounded-full animate-pulse"></div>
              <div className="absolute inset-0 border-4 border-blue-500 rounded-full animate-spin" 
                   style={{ borderRightColor: 'transparent', animationDuration: '1s' }}></div>
            </div>
            <div className="text-center">
              <h3 className="text-2xl font-semibold text-gray-800 mb-2">Welcome Back!</h3>
              <p className="text-gray-500 text-base">Loading your models, please wait...</p>
              <div className="mt-2 text-sm text-gray-400">This may take a few moments</div>
            </div>
          </div>
        ) : (
          <>
            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 2xl:grid-cols-9 gap-4 ">
              <div 
                onClick={handleUploadClick}
                className={`relative bg-gradient-to-br ${isPaused ? 'from-yellow-50 to-white' : allFilesCompleted() ? 'from-green-50 to-white' : 'from-blue-50 to-white'} xs:max-w-[165px] max-w-[180px] h-[240px] rounded-xl border-2 border-dashed ${isPaused ? 'border-yellow-200 hover:border-yellow-400' : allFilesCompleted() ? 'border-green-200 hover:border-green-400' : 'border-blue-200 hover:border-blue-400'} transition-all duration-300 cursor-pointer group flex flex-col items-center justify-center p-6`}
              >
                <div className={`absolute top-0 left-0 w-full h-full ${isPaused ? 'bg-yellow-500' : allFilesCompleted() ? 'bg-green-500' : 'bg-blue-500'} opacity-0 group-hover:opacity-5 transition-opacity duration-300 rounded-xl`} />
                
                <div className={`w-20 h-20 ${isPaused ? 'bg-yellow-100' : allFilesCompleted() ? 'bg-green-100' : 'bg-blue-100'} rounded-full flex items-center justify-center mb-4 group-hover:scale-110 transition-transform duration-300`}>
                  <CloudUploadIcon 
                    className={isPaused ? "text-yellow-500" : allFilesCompleted() ? "text-green-500" : "text-blue-500"}
                    sx={{ fontSize: 40 }}
                  />
                </div>
                
                <h3 className="text-lg font-semibold text-gray-900 mb-2">
                  {getUploadButtonText()}
                </h3>
                <p className="text-sm text-gray-500 text-center">
                  {isPaused ? 'Click to continue uploading' : allFilesCompleted() ? 'All files processed successfully' : 'Click to upload your 3D models'}
                </p>
                
                <div className="absolute bottom-6 left-0 right-0 flex justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                  <span className={`${isPaused ? 'bg-yellow-600' : allFilesCompleted() ? 'bg-green-600' : 'bg-blue-600'} text-white px-4 py-2 rounded-full text-sm font-medium`}>
                    {getUploadButtonText()}
                  </span>
                </div>
              </div>

              {/* Mevcut Dosyalar */}
              {objectListSelectorByUserId.data && objectListSelectorByUserId.data.length > 0 && 
                objectListSelectorByUserId.data.map((object) => (
                  <CardItem
                    key={object.name}
                    isActive={true}
                    imageName={getIconsOrThumbnail(object)}
                    title={object.name}
                    text=''
                    buttonNames={['Detail', '</>']}
                    buttonEvents={[
                      () => openObjectDetail(object.urn),
                      async () => {
                        setHtmlContent(object.iframeButtonCSS)
                        setWebSite(object.webSite !== null || object.webSite !== undefined ? object.webSite : "")
                        setSelectedName(object.name)
                        setTextAreaModal(true);
                      }
                    ]}
                    onDelete={() => {
                      setSelectedDeleteValue(object.name);
                      setConfirmModal(true);
                    }}
                    onClickToCard={() => {
                      window.open(`${baseUrl}objects/content/${object.urn}/view/${jsonLoginInfo.uid}/properties/`)
                    }}
                  />
                ))
              }
            </div>

            {/* Modallar */}
            {uploadModal && (
              <MultipleUploadModal
                handleCloseModal={handleCloseModal}
                setIsDragDrop={setUploadModal}
                selectedFiles={selectedFiles}
                setSelectedFiles={setSelectedFiles}
                setZipFile={setZipFile}
                zipFile={zipFile}
                processZipFile={processZipFile}
                openZipOption={openZipOption}
                setOpenZipOption={setOpenZipOption}
                extractZipStatus={extractZipFileStatus}
                setExtractZipStatus={setExtractZipFileStatus}
                creditStatus={creditWarnings}
                setCreditStatus={setCreditWarnings}
                setManifestStatus={setManifestStatus}
                setSteppedFileName={setSteppedFileName}
                setStep={setStep}
                invalidFileMessage={invalidFileErrorMessage}
                setInvalidFileMessage={setInvalidFileErrorMessage}
                isFinishedToLoading={isFinishedToLoading}
                setIsFinishedToLoading={setIsFinishedToLoading}
                afterUploadError={afterUploadError}
                setAfterUploadError={setAfterUploadError}
                startToGetChunkStatus={startToGetChunkStatus}
                setStartToGetChunkStatus={setStartToGetChunkStatus}
                processToUpload={processToUpload}
                setProcessToUpload={setProcessToUpload}
                isProcessing={isProcessing}
                setIsProcessing={setIsProcessing}
                waitingForManifest={waitingForManifest}
                setWaitingForManifest={setWaitingForManifest}
                step={step}
                steppedFileName={steppedFileName}
                manifestStatus={manifestStatus}
                currentUploadIndex={currentUploadIndex}
                isStarted={isStarted}
                setIsStarted={setIsStarted}
                statusValues={statusValues}
                setStatusValues={setStatusValues}
              />
            )}

            {
              openSupportedFormats && (
                <Dialog
                  open={openSupportedFormats}
                  onClose={() => setOpenSupportedFormats(false)}
                  aria-labelledby="file-format-dialog-title"
                  maxWidth="md"
                  fullWidth
                >
                  <DialogTitle id="file-format-dialog-title">Supported 2D and 3D file formats</DialogTitle>
                  <DialogContent>
                    <Typography variant="body2" gutterBottom>
                      Veewer can display the following file formats:
                    </Typography>
                    <Grid container spacing={2}>
                      {[0, 1, 2].map((column) => (
                        <Grid item xs={4} key={column}>
                          <List dense>
                            {supportedFormats.slice(column * 20, (column + 1) * 20).map((format) => (
                              <ListItem key={format}>
                                <ListItemText primary={format} />
                              </ListItem>
                            ))}
                          </List>
                        </Grid>
                      ))}
                    </Grid>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => setOpenSupportedFormats(false)}>Close</Button>
                  </DialogActions>
                </Dialog>
              )
            }

            {filterModal && <></>}

            {confirmModal && (
              <ConfirmModal
                word="delete the file"
                ifYes={deleteSelectedObject.loading}
                onYes={() => {
                  dispatch(deleteUploadedObject({
                    bucketKey,
                    objectKey: selectedDeleteValue
                  })).then((action) => {
                    if (action.meta.requestStatus === "fulfilled") {
                      setConfirmModal(false);
                      dispatch(updateWithDeleteObject({
                        bucketKey,
                        objectKey: selectedDeleteValue,
                      }));
                    }
                  });
                }}
                onNo={async () => {
                  setConfirmModal(false);
                }}
                show={confirmModal}
                onHide={setConfirmModal}
              />
            )}

            {textAreaModal && (
              <TextAreaModal
                show={textAreaModal}
                onHide={setTextAreaModal}
                incomingValue={htmlContent}
                secondOptionalValue={webSite}
                thirdOptionalValue={selectedName}
              />
            )}

            {
              openChangeModal && (
                <ChangeNameModal
                  show={openChangeModal}
                  onHide={() => setOpenChangeModal(false)}
                  currentName={selectedName}
                  objectId={selectedObjectId}
                />
              )
            }
          </>
        )}
      </div>
      <div className="bottom-0 w-full">
      <Footer />
      </div>
    </div>

  ) : (
    <Unauthorized />
  );
};

export default ObjectList;


