import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import '../../styles/login/login.css';
import { RootState, useAppDispatch, useAppSelector } from '../../store';
import { fetchPostAuth, resetError } from '../../store/features/auth/auth.post.slice';
import Swal from 'sweetalert2';
import LoadingScreen from '../../utils/LoadingScreen';
import useWindowDimensions from '../../utils/useWindowDimensions';

export default function Login() {
  const navigate = useNavigate();
  const [userNameOrEmail, setUserNameOrEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState('');
  const [inputType,setInputType]=useState('') // State for email validation error

  const dispatch = useAppDispatch();
  const postAuthSelector = useAppSelector((x: RootState) => x.postAuth);

  const getGoogleTokenSelector = useAppSelector((x: RootState) => x.googleAuthSlice);
  const getMicrosoftTokenSelector = useAppSelector((x: RootState) => x.microsoftAuthSlice);
  const [showPassword, setShowPassword] = useState(false);
  const { height, width } = useWindowDimensions();

  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  useEffect(() => {
    if (postAuthSelector.data != null && postAuthSelector.loading === false &&
      window.location.href.indexOf('google') === -1 && window.location.href.indexOf('microsoft') === -1) {
      const data = JSON.stringify({
        isAuthenticated: postAuthSelector.data.isAuthenticated,
        expiresAt: postAuthSelector.data.expiresAt,
        isAdmin: postAuthSelector.data.isAdmin,
        isUser: postAuthSelector.data.isUser,
        isOwner: postAuthSelector.data.isOwner,
        uid: postAuthSelector.data.uid,
      });
      sessionStorage.setItem('login', data);
      localStorage.setItem(`isAnyOpenSession-${userNameOrEmail}`,"true")
      navigate('/');
    }
  }, [
    postAuthSelector.data,
    postAuthSelector.error,
    postAuthSelector.loading,
    navigate,
  ]);

  useEffect(() => {
    if (getGoogleTokenSelector.data != null && getGoogleTokenSelector.loading === false &&
      window.location.href.indexOf('google') !== -1 && window.location.href.indexOf('microsoft') === -1) {
      const data = JSON.stringify({
        isAuthenticated: getGoogleTokenSelector.data.isAuthenticated,
        expiresAt: getGoogleTokenSelector.data.expiresAt,
        uid: getGoogleTokenSelector.data.uid,
        microsoft_auth: false,
        google_auth: getGoogleTokenSelector.data.google_auth,
        isAdmin: getGoogleTokenSelector.data.isAdmin,
        isUser: getGoogleTokenSelector.data.isUser,
        isOwner: getGoogleTokenSelector.data.isOwner,
      });
      sessionStorage.setItem('login', data);
      navigate('/');
    }
  }, [
    getGoogleTokenSelector.data,
    getGoogleTokenSelector.error,
    getGoogleTokenSelector.loading,
    navigate,
  ]);

  useEffect(() => {
    if (getMicrosoftTokenSelector.data != null && getMicrosoftTokenSelector.loading === false &&
      window.location.href.indexOf('google') === -1 && window.location.href.indexOf('microsoft') !== -1) {
      const data = JSON.stringify({
        isAuthenticated: getMicrosoftTokenSelector.data.isAuthenticated,
        expiresAt: getMicrosoftTokenSelector.data.expiresAt,
        isAdmin: getMicrosoftTokenSelector.data.isAdmin,
        isUser: getMicrosoftTokenSelector.data.isUser,
        isOwner: getMicrosoftTokenSelector.data.isOwner,
        uid: getMicrosoftTokenSelector.data.uid,
        microsoft_auth: getMicrosoftTokenSelector.data.microsoft_auth,
        google_auth: false,
      });
      
      sessionStorage.setItem('login', data);
      navigate('/');
    }
  }, [
    getMicrosoftTokenSelector.data,
    getMicrosoftTokenSelector.error,
    getMicrosoftTokenSelector.loading,
    navigate,
  ]);

  useEffect(() => {
    if (
      postAuthSelector.error !== null &&
      postAuthSelector.data === null &&
      postAuthSelector.loading === false
    ) {
      const error = postAuthSelector.error;

      if (error.error !== undefined || error.error !== null) {
        Swal.fire('Login failed', error.error, 'error');
      } else {
        Swal.fire('Login failed', error.message, 'error');
      }
      dispatch(resetError())
    }
  }, [
    postAuthSelector.data,
    postAuthSelector.error,
    postAuthSelector.loading,
    Swal,
  ]);

  const validateEmail = (email:string) => {
    
    if (!emailRegex.test(email)) {
      setEmailError('Invalid email address');
    } else {
      setEmailError('');
    }
  };

  return (
    <div className="w-full">
      {postAuthSelector.loading ? (
        <div className="flex items-center justify-center min-h-[400px]">
          <LoadingScreen isLoginMobile={width < 1050} />
        </div>
      ) : (
        <form className='py-4'>
          <div className='group-flex space-y-4'>
            <div className='form-group min-h-[70px]'>
              <label className="block text-sm font-medium text-gray-700 mb-1">Username or Email</label>
              <input
                type={inputType}
                value={userNameOrEmail}
                onChange={(e) => {
                  setUserNameOrEmail(e.target.value);
                  if(userNameOrEmail.includes("@") || userNameOrEmail.includes(".com")){
                    setInputType('email');
                    
                  }
                  else{
                    setInputType('text')
                  }   
                }}
                onBlur={()=>{
                  if(inputType === 'email'){
                    validateEmail(userNameOrEmail);
                  }
                }}
                className={`w-full h-10 px-3 py-2 border rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 ${
                  emailError ? 'border-red-500' : 'border-gray-300'
                }`}
                autoComplete={inputType==="email"?"email":"username"}
              />
              {emailError && <span className='text-red-500 text-xs sm:text-sm mt-1'>{emailError}</span>}
            </div>

            <div className='form-group relative min-h-[70px]'>
              <label className="block text-sm font-medium text-gray-700 mb-1">Password</label>
              <div className="relative">
                <input
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  type={showPassword ? 'text' : 'password'}
                  className="w-full h-10 px-3 py-2 border rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 border-gray-300"
                />
                <button
                  type="button"
                  className="absolute inset-y-0 right-0 pr-3 flex items-center"
                  onClick={() => setShowPassword(prev => !prev)}
                >
                  <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} className="text-gray-400" />
                </button>
              </div>
            </div>
          </div>

          <div className='space-y-4 mt-6'>
            <button
              disabled={userNameOrEmail === '' || password === '' || emailError !== ''}
              onClick={(e) => {
                e.preventDefault();
                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                const isEmail = emailRegex.test(userNameOrEmail);

                if(emailError !== ''){
                  Swal.fire({
                    title: 'Error',
                    text: emailError,
                    icon: 'error',
                    showCloseButton: true,
                    willClose:()=>{
                      setEmailError('')
                      setUserNameOrEmail('')
                      setPassword('')
                      setInputType('')  

                    }
                  });           
                }
                else
                dispatch(
                  fetchPostAuth({
                    userName: isEmail ? '' : userNameOrEmail,
                    email: isEmail ? userNameOrEmail : '',
                    password: password,
                    isAnyOpenSession:(localStorage.getItem(`isAnyOpenSession-${userNameOrEmail}`)==="true" ||
                     localStorage.getItem(`isAnyOpenSession-${userNameOrEmail}`)!==null) && (
                      sessionStorage.getItem("login")===null || sessionStorage.getItem("login")===undefined)?true:false
                  })
                );
              }}
              className={`w-full bg-gradient-to-r from-[#16254c] to-[#1e3a8a] text-white py-3 rounded-xl hover:opacity-90 transition-all duration-300 font-medium shadow-lg`}
            >
              Login
            </button>

            <div className='text-center'>
              <Link to='/forgotPassword' className='text-[#16254c] hover:text-[#1e3a8a] transition-colors duration-300'>
                Forgot Password?
              </Link>
            </div>
          </div>
        </form>
      )}
    </div>
  );
}

