import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import VeewerLogo from '../../assets/Veewer siyah @4x.png';
import { LogoutService } from '../../services/auth/logout.service';
import { LoginResponse } from '../../models/auth/login.model';
import { AdminLoginResponseModel } from '../../models/admin/admin.login.model';
import { GetAuthUrlService } from '../../services/oneDrive/auth/get.auth.url.service';
import OneDriveNav from '../OneDrive/OneDriveNav';
import AWSNav from '../AWS/AWSNav';
import { GetAWSAuthorizationUrlService } from '../../services/auth/aws/aws.auth.url.service';
import BoxNav from '../Box/BoxNav';
import { GetBoxAuthorizationUrlService } from '../../services/auth/box/box.auth.url.service';

interface HeaderProps {
  setIsBox: React.Dispatch<React.SetStateAction<boolean>> | undefined;
}

const SecondHeader: React.FC<HeaderProps> = ({ setIsBox }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);
  const [cloudMenuOpen, setCloudMenuOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [size, setSize] = useState({
    width: 0,
    height: 0,
  });
  const [examplesMenuOpen, setExamplesMenuOpen] = useState(false);
  const loginInfo = sessionStorage.getItem('login') as string;
  const jsonLoginInfo = JSON.parse(loginInfo) as LoginResponse;
  const adminLoginInfo = sessionStorage.getItem('adminLogin') as string;
  const jsonAdminLoginInfo = JSON.parse(adminLoginInfo) as AdminLoginResponseModel;

  useEffect(() => {
    const handleResize = () => {
      setSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const controlNavbar = () => {
      const currentScrollY = window.scrollY;
      if (currentScrollY === 0) {
        setIsVisible(true);
      } else if (currentScrollY > lastScrollY && currentScrollY > 100) { // scrolling down
        setIsVisible(false);
      } else { // scrolling up
        setIsVisible(true);
      }
      setLastScrollY(currentScrollY);
    };

    window.addEventListener('scroll', controlNavbar);
    return () => window.removeEventListener('scroll', controlNavbar);
  }, [lastScrollY]);

  useEffect(() => {
    if (size.width > 1000 && menuOpen) {
      setMenuOpen(false);
    }
  }, [size.width, menuOpen]);

  const menuToggleHandler = () => {
    setMenuOpen((p) => !p);
  };

  function removeSessionToExternalAuth(key: string) {
    if (sessionStorage.getItem(key) !== null) {
      sessionStorage.removeItem(key);
    }
  }

  const navigateHandler = (url: string) => {
    console.log(url);
    if (cloudMenuOpen) {
      closeDropDownMenu(); // cloudMenuOpen değerini false yapar
    }
    if (url === 'toggle') {
      menuToggleHandler();
    } else if (url.length > 0 && location.pathname !== url) {
      navigate(url);
      setMenuOpen(false); // Mobil menüyü kapat
    }
  };

  const handleCloudMenuToggle = () => {
    setExamplesMenuOpen(false); // Close examples menu when cloud menu is toggled
    setCloudMenuOpen(!cloudMenuOpen);
  };

  const closeDropDownMenu = () =>{
    setCloudMenuOpen(false);
  }

  const handleLogout = async () => {
    const logOutService = new LogoutService();
    const response = await logOutService.GetByStringParamAsync(jsonLoginInfo.uid);
    if (response !== null) {
      removeSessionToExternalAuth("microsoft-auth");
      removeSessionToExternalAuth("google-auth");
      removeSessionToExternalAuth("box-auth");
      sessionStorage.removeItem('login');
      sessionStorage.removeItem('b');
      window.location.replace('/');
    }
  };

  const handleExamplesMenuToggle = () => {
    setCloudMenuOpen(false); // Close cloud menu when examples menu is toggled
    setExamplesMenuOpen(!examplesMenuOpen);
  };

  const NavLink = ({ to, label }: { to: string; label: string }) => (
    <a
      href={to}
      onClick={(e) => {
        e.preventDefault();
        navigateHandler(to);
      }}
      className={`text-sm font-medium ${
        location.pathname === to 
          ? 'text-[#16254c] relative after:absolute after:bottom-0 after:left-0 after:w-full after:h-0.5 after:bg-gradient-to-r after:from-[#16254c] after:to-[#1e3a8a]' 
          : 'text-gray-700 hover:text-[#1e3a8a]'
      } transition-colors duration-300`}
    >
      {label}
    </a>
  );

  // Add click outside handler
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (!target.closest('.dropdown-container')) {
        setCloudMenuOpen(false);
        setExamplesMenuOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <header className={`bg-white ${location.pathname !== "/products" ? "fixed" : ""} top-0 left-0 right-0 z-50 p-1 z-99  transition-transform duration-300 ${!isVisible ? '-translate-y-full' : 'translate-y-0'}`}>
      <div className=" px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16 items-center">
          <div className="flex-shrink-0">
            <img
              src={VeewerLogo}
              onClick={() => navigateHandler("/")}
              alt='logo'
              className='h-8 w-auto cursor-pointer'
            />
          </div>
          <nav className="hidden md:flex space-x-8 !bg-white">
            <NavLink to="/" label="Home" />
            {jsonLoginInfo?.isAuthenticated && (
              <>
                <NavLink to="/storage" label="Storage" />
                <NavLink to="/profile" label="Profile" />
                <div className="relative dropdown-container">
                  <button
                    onClick={handleCloudMenuToggle}
                    className="text-sm font-medium text-gray-700 hover:text-gray-900"
                  >
                    Connect to Cloud
                  </button>
                  {cloudMenuOpen && (
                    <div className="absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                      <div className="py-1" role="menu" aria-orientation="vertical">
                        {sessionStorage.getItem('microsoft-auth') !== null ? (
                          <div className="px-4 py-2 text-sm text-gray-700">
                            <OneDriveNav handleCloudMenuToggle={handleCloudMenuToggle} setMenuOpen={setMenuOpen}/>
                          </div>
                        ) : (
                          <a
                            href="#"
                            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                            onClick={async () => {
                              sessionStorage.setItem("clickOneDrive", "true");
                              sessionStorage.setItem("clickBox", "false");
                              sessionStorage.setItem("clickGoogleDrive", "false");
                              const authUrlService = new GetAuthUrlService();
                              const response = await authUrlService.GetAsync();
                              window.location.assign(response.url);
                            }}
                          >
                            OneDrive
                          </a>
                        )}
                        {sessionStorage.getItem('box-auth') !== null ? (
                          <div className="px-4 py-2 text-sm text-gray-700">
                            <BoxNav handleCloudMenuToggle={handleCloudMenuToggle} setMenuOpen ={setMenuOpen}/>
                          </div>
                        ) : (
                          <a
                            href="#"
                            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                            onClick={async () => {
                              sessionStorage.setItem("clickBox", "true");
                              const authUrlService = new GetBoxAuthorizationUrlService();
                              const response = await authUrlService.GetAsync();
                              window.location.assign(response.url);
                            }}
                          >
                            Box
                          </a>
                        )}
                        {/* {sessionStorage.getItem('aws-auth') !== null ? (
                          <div className="px-4 py-2 text-sm text-gray-700">
                            <AWSNav />
                          </div>
                        ) : (
                          <a
                            href="#"
                            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                            onClick={async () => {
                              sessionStorage.setItem("clickAWS", "true");
                              const authUrlService = new GetAWSAuthorizationUrlService();
                              const response = await authUrlService.GetAsync();
                              window.location.assign(response.url);
                            }}
                          >
                            AWS
                          </a>
                        )} */}
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}
            <div className="relative dropdown-container">
              <button
                onClick={handleExamplesMenuToggle}
                className="text-sm font-medium text-gray-700 hover:text-gray-900"
              >
                Examples
              </button>
              {examplesMenuOpen && (
                <div className="absolute left-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                  <div className="py-1" role="menu" aria-orientation="vertical">
                    <a
                      href="/products"
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-[#16254c]/5 hover:text-[#16254c] transition-colors duration-300"
                      onClick={(e) => {
                        e.preventDefault();
                        navigateHandler("/products");
                        setExamplesMenuOpen(false);
                      }}
                    >
                      Products
                    </a>
                    <a
                      href="/configurator"
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-[#16254c]/5 hover:text-[#16254c] transition-colors duration-300"
                      onClick={(e) => {
                        e.preventDefault();
                        navigateHandler("/configurator");
                        setExamplesMenuOpen(false);
                      }}
                    >
                      Configurator
                    </a>
                    <a
                      href="/ekana-sofa"
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-[#16254c]/5 hover:text-[#16254c] transition-colors duration-300"
                      onClick={(e) => {
                        e.preventDefault();
                        navigateHandler("/ekana-sofa");
                        setExamplesMenuOpen(false);
                      }}
                    >
                      Product Details
                    </a>
                  </div>
                </div>
              )}
            </div>
            <NavLink to="/tutorials" label="Tutorials" />
            <NavLink to="/privacy" label="Privacy" />
            {loginInfo && jsonLoginInfo?.isAuthenticated ? (
              <button
                onClick={handleLogout}
                className="text-sm font-medium text-gray-700 hover:text-gray-900"
              >
                Logout
              </button>
            ) : (
              <NavLink to="/login" label="Sign In/Sign Up" />
            )}
          </nav>
          <div className="md:hidden">
            <button
              type="button"
              className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none"
              onClick={(e) => {
                menuToggleHandler();
                e.currentTarget.blur(); // Remove focus after click
              }}
            >
              <span className="sr-only">Open main menu</span>
              {!menuOpen ? (
                <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                </svg>
              ) : (
                <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              )}
            </button>
          </div>
        </div>
      </div>
      {menuOpen && (
        <div className="md:hidden fixed top-[65px] left-0 right-0 bg-white shadow-lg">
          <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
            <a 
              href="/" 
              className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-[#16254c] hover:bg-[#16254c]/5 transition-colors duration-300"
              onClick={(e) => { 
                e.preventDefault(); 
                navigateHandler("/"); 
                setMenuOpen(false);
                closeDropDownMenu();
              }}
            >
              Home
            </a>
            {loginInfo && jsonLoginInfo.isAuthenticated && (
              <>
                <a href="/storage" className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50" onClick={(e) => { e.preventDefault(); navigateHandler("/storage"); setMenuOpen(false); closeDropDownMenu(); }}>Storage</a>
                <a href="/profile" className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50" onClick={(e) => { e.preventDefault(); navigateHandler("/profile"); setMenuOpen(false); closeDropDownMenu(); }}>Profile</a>
                <div className="relative">
                  <button
                    className="w-full text-left px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleCloudMenuToggle();
                    }}
                  >
                    Connect to Cloud
                  </button>
                  {cloudMenuOpen && (
                    <div className="pl-4 bg-gray-50">
                      {sessionStorage.getItem('microsoft-auth') !== null ? (
                        <div className="px-3 py-2 text-sm text-gray-700">
                          <OneDriveNav handleCloudMenuToggle={handleCloudMenuToggle} setMenuOpen={setMenuOpen}/>
                        </div>
                      ) : (
                        <a
                          href="#"
                          className="block px-3 py-2 rounded-md text-sm font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
                          onClick={async (e) => {
                            e.stopPropagation();
                            sessionStorage.setItem("clickOneDrive", "true");
                            sessionStorage.setItem("clickBox", "false");
                            sessionStorage.setItem("clickGoogleDrive", "false");
                            const authUrlService = new GetAuthUrlService();
                            const response = await authUrlService.GetAsync();
                            window.location.assign(response.url);
                          }}
                        >
                          OneDrive
                        </a>
                      )}
                      {sessionStorage.getItem('box-auth') !== null ? (
                        <div className="px-3 py-2 text-sm text-gray-700">
                          <BoxNav handleCloudMenuToggle={handleCloudMenuToggle} setMenuOpen={setMenuOpen}/>
                        </div>
                      ) : (
                        <a
                          href="#"
                          className="block px-3 py-2 rounded-md text-sm font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
                          onClick={async (e) => {
                            e.stopPropagation();
                            sessionStorage.setItem("clickBox", "true");
                            const authUrlService = new GetBoxAuthorizationUrlService();
                            const response = await authUrlService.GetAsync();
                            window.location.assign(response.url);
                          }}
                        >
                          Box
                        </a>
                      )}
                    </div>
                  )}
                </div>
              </>
            )}
            <div className="relative">
              <button
                className="w-full text-left block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
                onClick={(e) => {
                  e.stopPropagation();
                  handleExamplesMenuToggle();
                }}
              >
                Examples
              </button>
              {examplesMenuOpen && (
                <div className="pl-4 bg-gray-50">
                  <a
                    href="/products"
                    className="block px-3 py-2 rounded-md text-sm font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
                    onClick={(e) => {
                      e.preventDefault();
                      navigateHandler("/products");
                      setExamplesMenuOpen(false);
                      setMenuOpen(false);
                      closeDropDownMenu();
                    }}
                  >
                    Products
                  </a>
                  <a
                    href="/configurator"
                    className="block px-3 py-2 rounded-md text-sm font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
                    onClick={(e) => {
                      e.preventDefault();
                      navigateHandler("/configurator");
                      setExamplesMenuOpen(false);
                      setMenuOpen(false);
                      closeDropDownMenu();
                    }}
                  >
                    Configurator
                  </a>
                  <a
                    href="/ekana-sofa"
                    className="block px-3 py-2 rounded-md text-sm font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
                    onClick={(e) => {
                      e.preventDefault();
                      navigateHandler("/ekana-sofa");
                      setExamplesMenuOpen(false);
                      setMenuOpen(false);
                      closeDropDownMenu();
                    }}
                  >
                    Product Explorer
                  </a>
                </div>
              )}
            </div>
            <a href="/tutorials" className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50" onClick={(e) => { e.preventDefault(); navigateHandler("/tutorials"); setMenuOpen(false); closeDropDownMenu(); }}>Tutorials</a>
            <a href="/privacy" className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50" onClick={(e) => { e.preventDefault(); navigateHandler("/privacy"); setMenuOpen(false); closeDropDownMenu(); }}>Privacy</a>
            
            {loginInfo && jsonLoginInfo.isAuthenticated ?
                <button
                  onClick={() => { handleLogout(); setMenuOpen(false); closeDropDownMenu(); }}
                  className="w-full text-left block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
                >
                  Logout
                </button>:
                 <a href="/login" className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50" onClick={(e) => { e.preventDefault(); navigateHandler("/login"); setMenuOpen(false); closeDropDownMenu(); }}>Sign In/Sign Up</a>
            }
            
          </div>
        </div>
      )}
    </header>
  );
}

export default SecondHeader;

