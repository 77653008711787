import React, { useState, useEffect, useRef } from 'react';
import { baseUrl } from '../config/axios.config';
import { motion, AnimatePresence } from 'framer-motion';

// QR kod importları
import canvasGrayGlossyQR from '../assets/qrCodes/canvas-gray-glossy-black.png';
import canvasGrayMapleQR from '../assets/qrCodes/canvas-gray-silver-maple-natural.png';
import canvasGrayCardboardQR from '../assets/qrCodes/canvas-gray-cardboard.png';
import canvasGrayPlywoodQR from '../assets/qrCodes/canvas-gray-plywood-weathered.png';

import tweedSilverGlossyQR from '../assets/qrCodes/tweed-glossy-black.png';
import tweedSilverMapleQR from '../assets/qrCodes/tweed-silver-maple-natural.png';
import tweedSilverCardboardQR from '../assets/qrCodes/tweed-cardboard.png';
import tweedSilverPlywoodQR from '../assets/qrCodes/tweed-plywood-weathered.png';

import stripesGlossyQR from '../assets/qrCodes/stripes-black-blue-gray-glossy-black.png';
import stripesMapleQR from '../assets/qrCodes/stripes-black-blue-gray-silver-maple-natural.png';
import stripesCardboardQR from '../assets/qrCodes/stripes-black-blue-gray-cardboard.png';
import stripesPlywoodQR from '../assets/qrCodes/stripes-black-blue-gray-plywood-weathered.png';

import canvasBrownGlossyQR from '../assets/qrCodes/canvas-brown-glossy-black.png';
import canvasBrownMapleQR from '../assets/qrCodes/canvas-brown-silver-maple-natural.png';
import canvasBrownCardboardQR from '../assets/qrCodes/canvas-brown-cardboard.png';
import canvasBrownPlywoodQR from '../assets/qrCodes/canvas-brown-plywood-weathered.png';
import Footer from '../components/Footer/Footer';

type CushionType = 'Canvas Gray' | 'Tweed Silver' | 'Stripes Black Blue' | 'Canvas Brown';
type LegType = 'Glossy Black' | 'Silver Maple Natural' | 'Cardboard' | 'Plywood Weathered';

type ModelUrnsType = {
  [key in CushionType]: {
    [key in LegType]: string;
  };
};

type CushionMaterial = {
  name: CushionType;
  hex: string;
  texture: string;
};

type LegMaterial = {
  name: LegType;
  price: string;
};

const ProductExplorer: React.FC = () => {
  const [selectedCushion, setSelectedCushion] = useState<CushionType>('Canvas Brown');
  const [selectedLeg, setSelectedLeg] = useState<LegType>('Cardboard');
  const [showHint, setShowHint] = useState(false);
  const hintTimeoutRef = useRef<NodeJS.Timeout>();
  const [isModelLoaded, setIsModelLoaded] = useState(false);

  useEffect(() => {
    if (isModelLoaded && !showHint) {
      setShowHint(true);
      hintTimeoutRef.current = setTimeout(() => {
        setShowHint(false);
      }, 3000);
    }

    return () => {
      if (hintTimeoutRef.current) {
        clearTimeout(hintTimeoutRef.current);
      }
    };
  }, [isModelLoaded]);

  const handleIframeLoad = () => {
    setIsModelLoaded(true);
  };

  const cushionMaterials: CushionMaterial[] = [
    { name: 'Canvas Brown', hex: '#7f5e40', texture: 'Canvas' },
    { name: 'Canvas Gray', hex: '#b7baba', texture: 'Canvas' },
    { name: 'Tweed Silver', hex: '#1d1a15', texture: 'Tweed' },
    { name: 'Stripes Black Blue', hex: '#a3bac9', texture: 'Stripes' },
  ];

  const legMaterials: LegMaterial[] = [
    { name: 'Cardboard', price: '+$0' },
    { name: 'Glossy Black', price: '+$199' },
    { name: 'Plywood Weathered', price: '+$299' },
    { name: 'Silver Maple Natural', price: '+$399' }
  ];

  const features = [
    { title: 'Dimensions', value: '121cm x 76cm x 78cm', icon: '📏' },
    { title: 'Seating', value: '1-2 People', icon: '👥' },
    { title: 'Frame', value: 'Solid Wood', icon: '🪑' },
    { title: 'Warranty', value: '5 Years', icon: '✨' }
  ];

  // URL mapping object for all combinations
  const modelUrns: ModelUrnsType = {
    'Canvas Gray': {
      'Glossy Black': 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6c3luYy00Njc1L2VrZW5hJTIwc29mYSUyMGNhbnZhcyUyMGdyYXktZ2xvc3N5JTIwYmxhY2tfR3VpZF81ZjM2NGY2OC0wZjFiLTRlNWItYTFhZi1jNTUyZjAxMzUzZDAuaXB0',
      'Silver Maple Natural': 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6c3luYy00Njc1L2VrZW5hJTIwc29mYSUyMGNhbnZhcyUyMGdyYXktc2lsdmVyJTIwbWFwbGUlMjBuYXR1cmFsX0d1aWRfZWNmYjczOTUtNDkyMy00NGJiLWI3NjItZWU0NWJjMGM3MGQ1LmlwdA',
      'Cardboard': 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6c3luYy00Njc1L2VrZW5hJTIwc29mYSUyMGNhbnZhcyUyMGdyYXktY2FyZGJvYXJkX0d1aWRfYTdkNGVkYTctMmEzYS00ZjlkLWEyM2ItNGFiNWI4YWY1NDliLmlwdA',
      'Plywood Weathered': 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6c3luYy00Njc1L2VrZW5hJTIwc29mYSUyMGNhbnZhcyUyMGdyYXktcGx5d29vZCUyMHdlYXRoZXJlZF9HdWlkX2JlZTg0ODg0LTAxNjgtNGRmMi05YjRmLWY3YjdmNDIwNWJlYS5pcHQ'
    },
    'Tweed Silver': {
      'Silver Maple Natural': 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6c3luYy00Njc1L2VrZW5hJTIwc29mYSUyMHR3ZWVkLXNpbHZlciUyMG1hcGxlJTIwbmF0dXJhbF9HdWlkX2RhNGFkMzg5LTM2ODItNDczOC05ODkwLTAyNDEyNGE0ZWQyNy5pcHQ',
      'Glossy Black': 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6c3luYy00Njc1L2VrZW5hJTIwc29mYSUyMHR3ZWVkLWdsb3NzeSUyMGJsYWNrX0d1aWRfNzRjY2E2NjEtMGY3NC00MGNlLWJhYzAtMzdkYjE1ZTMxZGM2LmlwdA',
      'Cardboard': 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6c3luYy00Njc1L2VrZW5hJTIwc29mYSUyMHR3ZWVkLWNhcmRib2FyZF9HdWlkX2Y1Nzk1NTQ3LWU5ZjctNGNjMS05ZmE1LTY1MDkyNDlmZDJhNC5pcHQ',
      'Plywood Weathered': 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6c3luYy00Njc1L2VrZW5hJTIwc29mYSUyMHR3ZWVkLXBseXdvb2QlMjB3ZWF0aGVyZWRfR3VpZF9iNjEyODc5ZS1kNDFhLTQzNTktODkzZi1lNDQyMDA5N2ZlZGUuaXB0'
    },
    'Stripes Black Blue': {
      'Silver Maple Natural': 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6c3luYy00Njc1L2VrZW5hJTIwc29mYSUyMHN0cmlwZXMlMjBibGFjayUyMGJsdWUlMjBncmF5LXNpbHZlciUyMG1hcGxlJTIwbmF0dXJhbF9HdWlkXzBmNWQyMjRkLThhMWItNGNlNS1hMmExLTExZjViMjJhODAwOS5pcHQ',
      'Glossy Black': 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6c3luYy00Njc1L2VrZW5hJTIwc29mYSUyMHN0cmlwZXMlMjBibGFjayUyMGJsdWUlMjBncmF5LWdsb3NzeSUyMGJsYWNrX0d1aWRfMDU2OTkxNWItZWI5ZS00YjM2LTliNmMtYzUzMzIxOTIxMmQ2LmlwdA',
      'Cardboard': 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6c3luYy00Njc1L2VrZW5hJTIwc29mYSUyMHN0cmlwZXMlMjBibGFjayUyMGJsdWUlMjBncmF5LWNhcmRib2FyZF9HdWlkXzU5YTE2MDY0LTg4MjUtNGI2My04NGRiLTI3MTM3MGYzZDIwZS5pcHQ',
      'Plywood Weathered': 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6c3luYy00Njc1L2VrZW5hJTIwc29mYSUyMHN0cmlwZXMlMjBibGFjayUyMGJsdWUlMjBncmF5LXBseXdvb2QlMjB3ZWF0aGVyZWRfR3VpZF9lZjE3Y2RjYS1jYmEwLTQzYWQtODljZi1kYTFhNTQwMTEwOWQuaXB0'
    },
    'Canvas Brown': {
      'Silver Maple Natural': 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6c3luYy00Njc1L2VrZW5hJTIwc29mYSUyMGNhbnZhcyUyMGJyb3duLXNpbHZlciUyMG1hcGxlJTIwbmF0dXJhbF9HdWlkX2IyOWI1ZTE0LTg0MTUtNDg5My05ODVkLTJmNzk2MDNlODExZi5pcHQ',
      'Glossy Black': 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6c3luYy00Njc1L2VrZW5hJTIwc29mYSUyMGNhbnZhcyUyMGJyb3duLWdsb3NzeSUyMGJsYWNrX0d1aWRfMWM0MGI4ZDgtODBmMS00ZDFlLTk5OTMtMDFlMjNhZjQ4MWNmLmlwdA',
      'Cardboard': 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6c3luYy00Njc1L2VrZW5hJTIwc29mYSUyMGNhbnZhcyUyMGJyb3duLWNhcmRib2FyZF9HdWlkX2EyMDdkNDg1LWU4NzctNGFkOS1iNjFjLWI3ZjhjMjk4ZjU2Zi5pcHQ',
      'Plywood Weathered': 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6c3luYy00Njc1L2VrZW5hJTIwc29mYSUyMGNhbnZhcyUyMGJyb3duLXBseXdvb2QlMjB3ZWF0aGVyZWRfR3VpZF9jNDM4MTY0MC0wNDNjLTRlNDAtYjgzNi1hODYwOGE2ZjM0NjAuaXB0'
    }
  };

  // QR kodları için mapping
  const qrCodes: ModelUrnsType = {
    'Canvas Gray': {
      'Glossy Black': canvasGrayGlossyQR,
      'Silver Maple Natural': canvasGrayMapleQR,
      'Cardboard': canvasGrayCardboardQR,
      'Plywood Weathered': canvasGrayPlywoodQR
    },
    'Tweed Silver': {
      'Glossy Black': tweedSilverGlossyQR,
      'Silver Maple Natural': tweedSilverMapleQR,
      'Cardboard': tweedSilverCardboardQR,
      'Plywood Weathered': tweedSilverPlywoodQR
    },
    'Stripes Black Blue': {
      'Glossy Black': stripesGlossyQR,
      'Silver Maple Natural': stripesMapleQR,
      'Cardboard': stripesCardboardQR,
      'Plywood Weathered': stripesPlywoodQR
    },
    'Canvas Brown': {
      'Glossy Black': canvasBrownGlossyQR,
      'Silver Maple Natural': canvasBrownMapleQR,
      'Cardboard': canvasBrownCardboardQR,
      'Plywood Weathered': canvasBrownPlywoodQR
    }
  };

  // Mevcut QR kodunu al
  const getCurrentQR = () => {
    return qrCodes[selectedCushion]?.[selectedLeg] || canvasBrownCardboardQR; // default QR
  };

  // Get the current URN based on selections
  const getCurrentUrn = () => {
    return modelUrns[selectedCushion]?.[selectedLeg] || 
      'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6c3luYy00Njc1L2VrZW5hJTIwc29mYV9HdWlkX2I4YzQ3ZDY3LTNlYjUtNDUyMC1hNWQzLWM1OTc2NTUyYjdhNC5pcHQ';
  };

  // Generate viewer URL with current URN
  const viewerUrl = `${baseUrl}objects/content/${getCurrentUrn()}/view/00dc8f6d-9174-4ee6-99e3-3b59d7ea58b9/properties/backgroundColor=fcfcfd&isAnimated=false&hideBottomToolBar=true&activeTools=viewcube,homeViewWrappe&lightPreset=1&showWatermark=false&setGroundShadow=true&setGroundReflection=false`;

  return (
    <div className="min-h-screen flex flex-col">
      <div className="flex-1 bg-[#F8F7F4]">
        <div className="container max-w-[1440px] mx-auto px-4 sm:px-6 lg:px-12 py-4 sm:py-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-x-16 mb-4">
            {/* Left Column - 3D Viewer */}
            <div className="h-[40vh] md:h-auto flex items-center">
              <div className="relative w-full h-full rounded-2xl md:rounded-[2rem] overflow-hidden bg-white shadow-[0_8px_40px_rgb(0,0,0,0.08)]">
                <AnimatePresence>
                  {showHint && (
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.5 }}
                      className="absolute inset-0 flex items-center justify-center pointer-events-none z-20"
                    >
                      <motion.div
                        initial={{ scale: 0.9, opacity: 0 }}
                        animate={{ scale: 1, opacity: 1 }}
                        exit={{ scale: 0.9, opacity: 0 }}
                        transition={{ duration: 0.5 }}
                        className="bg-black/70 backdrop-blur-sm rounded-xl p-4 lg:p-8 text-white text-center max-w-md mx-4 border border-white/10"
                      >
                        <motion.div 
                          animate={{ rotate: [0, -10, 10, -10, 0] }}
                          transition={{ duration: 2 }}
                          className="text-2xl lg:text-4xl mb-4 flex justify-center gap-6"
                        >
                          <span>🖱️</span>
                          <span>🔄</span>
                          <span>🔍</span>
                        </motion.div>
                        <p className="text-lg lg:text-xl font-medium mb-2 lg:mb-3">Interact with the 3D Model!</p>
                        <p className="text-sm lg:text-base opacity-90">
                          • Drag to rotate<br/>
                          • Scroll to zoom in/out<br/>
                          • Right-click to pan
                        </p>
                      </motion.div>
                    </motion.div>
                  )}
                </AnimatePresence>
                <iframe
                  title="Ekana Sofa 3D Model Viewer"
                  src={viewerUrl}
                  className="w-full h-full border-0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  onLoad={handleIframeLoad}
                />
              </div>
            </div>

            {/* Right Column - Product Details */}
            <div className="flex flex-col h-auto">
              <div className="flex-1 bg-white rounded-2xl md:rounded-[2rem] p-4 md:p-8 shadow-[0_8px_40px_rgb(0,0,0,0.03)]">
                <div className="h-full flex flex-col">
                  <div className="space-y-3 md:space-y-4">
                    <div className="flex justify-between items-start">
                      <div>
                        <span className="inline-block px-3 py-1 bg-amber-100 text-amber-800 text-[10px] md:text-xs font-medium rounded-full">Premium Collection</span>
                        <h1 className="mt-2 text-2xl md:text-3xl font-bold text-gray-900">Ekana Sofa</h1>
                        <div className="mt-1 flex items-baseline">
                          <span className="text-xl md:text-2xl font-light text-gray-900">$2,499</span>
                          <span className="ml-2 md:ml-3 text-[10px] md:text-xs text-gray-500">or $208/mo</span>
                        </div>
                      </div>

                      {/* QR Code Section */}
                      <div className="flex flex-col items-center">
                        <a 
                          href={`https://ar-sooty-three.vercel.app/?model=${selectedCushion.toLowerCase().replace(/ /g, '-')}-${selectedLeg.toLowerCase().replace(/ /g, '-')}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="group"
                        >
                          {/* Desktop QR Code */}
                          <div className="hidden md:block w-28 h-28 bg-gradient-to-r from-amber-50 to-orange-50 rounded-lg p-2 shadow-sm">
                            <div className="w-full h-full bg-white rounded-md flex items-center justify-center">
                              <img 
                                src={getCurrentQR()} 
                                alt="AR View QR Code" 
                                className="w-full h-full object-contain"
                              />
                            </div>
                          </div>
                          {/* Mobile/Tablet AR Button */}
                          <div className="md:hidden">
                            <div className="w-24 h-24 bg-gradient-to-br from-amber-500 to-amber-600 rounded-lg flex flex-col items-center justify-center gap-2 transition-all duration-200 active:scale-95 hover:shadow-lg">
                              <svg className="w-8 h-8 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 9l9-7 9 7v11a2 2 0 01-2 2H5a2 2 0 01-2-2V9z" />
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 22V12h6v10" />
                              </svg>
                              <span className="text-white text-sm font-medium">View in AR</span>
                            </div>
                          </div>
                          <p className="mt-1 text-[10px] text-center text-gray-500 hidden md:block">
                            Scan QR for AR View
                          </p>
                        </a>
                      </div>
                    </div>

                    <p className="text-sm md:text-base text-gray-600 leading-relaxed border-b border-gray-100 pb-3">
                      Experience unparalleled comfort with the Ekana Sofa. Meticulously crafted with premium materials, 
                      making it the perfect centerpiece for your living space.
                    </p>

                    {/* Cushion Material Selector */}
                    <div className="space-y-2">
                      <h3 className="text-xs md:text-sm font-medium text-gray-900">Select Cushion Material</h3>
                      <div className="flex items-start space-x-3 md:space-x-4">
                        {cushionMaterials.map((material) => (
                          <button
                            key={material.name}
                            className={`group flex flex-col items-center ${
                              selectedCushion === material.name ? 'relative' : ''
                            }`}
                            onClick={() => setSelectedCushion(material.name as CushionType)}
                          >
                            <span
                              className={`h-8 w-8 md:h-10 md:w-10 rounded-lg md:rounded-xl border-2 transition-transform group-hover:scale-110 duration-200 ${
                                selectedCushion === material.name ? 'border-amber-500 shadow-md' : 'border-transparent'
                              }`}
                              style={{ backgroundColor: material.hex }}
                            />
                            <span className="mt-1 text-[8px] md:text-[10px] font-medium text-gray-700">{material.name}</span>
                            <span className="text-[8px] md:text-[10px] text-gray-500">{material.texture}</span>
                          </button>
                        ))}
                      </div>
                    </div>

                    {/* Leg Material Selector */}
                    <div className="space-y-2">
                      <h3 className="text-xs md:text-sm font-medium text-gray-900">Select Leg Material</h3>
                      <div className="grid grid-cols-2 gap-2 md:gap-3">
                        {legMaterials.map((material) => (
                          <button
                            key={material.name}
                            className={`p-2 md:p-3 text-left rounded-lg md:rounded-xl transition-all duration-200 ${
                              selectedLeg === material.name
                                ? 'bg-amber-50 border-2 border-amber-500'
                                : 'bg-gray-50 hover:bg-gray-100 border-2 border-transparent'
                            }`}
                            onClick={() => setSelectedLeg(material.name as LegType)}
                          >
                            <span className="block text-xs md:text-sm font-medium text-gray-900">{material.name}</span>
                            <span className="text-xs md:text-sm text-amber-600">{material.price}</span>
                          </button>
                        ))}
                      </div>
                    </div>

                    {/* Features */}
                    <div className="grid grid-cols-2 gap-3 md:gap-4">
                      {features.map((feature) => (
                        <div key={feature.title} className="flex items-start space-x-2">
                          <span className="text-base md:text-lg">{feature.icon}</span>
                          <div>
                            <dt className="text-[10px] md:text-xs font-medium uppercase text-gray-500">{feature.title}</dt>
                            <dd className="text-xs md:text-sm font-medium text-gray-900">{feature.value}</dd>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  {/* Bottom Section */}
                  <div className="mt-auto space-y-2 md:space-y-3 pt-3 md:pt-4">
                    <div className="flex space-x-2 md:space-x-3">
                      <button
                        type="button"
                        className="flex-1 bg-amber-500 rounded-lg md:rounded-xl py-2.5 md:py-3 px-4 md:px-6 flex items-center justify-center text-xs md:text-sm font-medium text-white hover:bg-amber-600 transition-colors shadow-lg shadow-amber-500/20"
                      >
                        Add to Cart
                      </button>
                      <button
                        type="button"
                        className="w-10 h-10 md:w-12 md:h-12 flex items-center justify-center rounded-lg md:rounded-xl border-2 border-gray-200 hover:border-amber-500 transition-colors"
                      >
                        <svg className="w-4 h-4 md:w-5 md:h-5 text-gray-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
                        </svg>
                      </button>
                    </div>

                    <div className="flex items-center justify-between">
                      <div className="flex items-center space-x-1">
                        <svg className="h-3 w-3 md:h-4 md:w-4 text-amber-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                        </svg>
                        <span className="text-xs md:text-sm text-gray-600">Free White Glove Delivery</span>
                      </div>
                      <div className="flex items-center space-x-1">
                        <svg className="h-3 w-3 md:h-4 md:w-4 text-amber-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                        </svg>
                        <span className="text-xs md:text-sm text-gray-600">30-Day Home Trial</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-auto">
        <Footer />
      </div>
    </div>
  );
};

export default ProductExplorer;