
import { GetAllBoxFilesModel, GetBoxFileModel } from "../../models/box/get.all.box.files.model";
import { BaseGetService } from "../base/base.get.service";



export class GetAllBoxFilesService extends BaseGetService<GetAllBoxFilesModel>{
    constructor(controllerName:string="box",endpoint:string="fileList"){
        super(controllerName,endpoint)
    }
    
}

export class GetBoxFileService extends BaseGetService<GetBoxFileModel>{
    constructor(controllerName:string="box",endpoint:string="file"){
        super(controllerName,endpoint)
    }
    
}