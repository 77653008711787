import React, { createContext, useContext, useState } from 'react';

interface UploadStatus {
  rootPathResponse?: {
    progress: number;
    bytesRead: number;
    totalBytes: number;
    path: string;
    fileName: string;
    completed: boolean;
  };
  finalizeResponse?: {
    isSuccess: boolean;
    urns: string[];
    uid: string;
    files: string[];
  };
  manifestResponse?: {
    progress: string;
  };
}

interface UploadStatusContextType {
  uploadStatuses: Map<string, UploadStatus>;
  updateUploadStatus: (fileName: string, status: Partial<UploadStatus>) => void;
  clearUploadStatus: (fileName: string) => void;
  clearAllStatuses: () => void;
}

const UploadStatusContext = createContext<UploadStatusContextType | undefined>(undefined);

export const UploadStatusProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [uploadStatuses, setUploadStatuses] = useState<Map<string, UploadStatus>>(new Map());

  const updateUploadStatus = (fileName: string, status: Partial<UploadStatus>) => {
    setUploadStatuses(prev => {
      const newMap = new Map(prev);
      const currentStatus = newMap.get(fileName) || {};
      newMap.set(fileName, { ...currentStatus, ...status });
      return newMap;
    });
  };

  const clearUploadStatus = (fileName: string) => {
    setUploadStatuses(prev => {
      const newMap = new Map(prev);
      newMap.delete(fileName);
      return newMap;
    });
  };

  const clearAllStatuses = () => {
    setUploadStatuses(new Map());
  };

  return (
    <UploadStatusContext.Provider value={{ 
      uploadStatuses, 
      updateUploadStatus, 
      clearUploadStatus,
      clearAllStatuses 
    }}>
      {children}
    </UploadStatusContext.Provider>
  );
};

export const useUploadStatus = () => {
  const context = useContext(UploadStatusContext);
  if (!context) {
    throw new Error('useUploadStatus must be used within an UploadStatusProvider');
  }
  return context;
}; 