import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import SecondHeader from '../components/Navbar/SecondHeader';
import Footer from '../components/Footer/Footer';
import '../styles/resetPassword/resetPassword.css';
import { RootState, useAppDispatch, useAppSelector } from '../store';
import { fetchResetPassword, resetResetPasswordState } from '../store/features/resetPassword/reset.password.slice';
import EmbedCodeLoading from '../components/Loading/EmbedCodeLoading/EmbedCodeLoading';

export default function ResetPassword() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState({
    content:"",
    color:""
  });
  const [error, setError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordErrors, setPasswordErrors] = useState<string[]>([]);
  const [confirmBlurred, setConfirmBlurred] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const resetPasswordState = useAppSelector((state: RootState) => state.resetPasswordSlice);

  const passwordRules = [
    {
      text: 'Minimum 6 characters',
      state: /.{6,}/,
      errorMessage: 'Password must be at least 6 characters',
    },
    {
      text: 'A non-alphanumeric character',
      state: /.*[^a-zA-Z\d].*/,
      errorMessage: 'Password must be at least 1 non-alphanumeric character',
    },
    {
      text: 'A lowercase letter',
      state: /[a-z]/,
      errorMessage: 'Password must contain at least 1 lowercase letter',
    },
    {
      text: 'An uppercase letter',
      state: /[A-Z]/,
      errorMessage: 'Password must contain at least 1 uppercase letter',
    },
    {
      text: 'A number',
      state: /\d/,
      errorMessage: 'Password must contain at least 1 number',
    },
  ];

  const validateEmail = (email: string) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const validateForm = () => {
    if (!email || !password || !confirmPassword) return false;
    if (!validateEmail(email)) return false;

    const failedRules = passwordRules.filter((rule) => !rule.state.test(password));
    if (failedRules.length > 0 || password !== confirmPassword) return false;

    return true;
  };

  useEffect(() => {
    setIsButtonDisabled(!validateForm());
  }, [email, password, confirmPassword]);

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setEmail(value);

    if (!validateEmail(value)) {
      setEmailError('Invalid email format');
    } else {
      setEmailError('');
    }

    setIsButtonDisabled(true); // Disable button when email changes
  };

  const handleResetPassword = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(''); // Reset error message
    setPasswordErrors([]); // Reset password errors

    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    const failedRules = passwordRules.filter((rule) => !rule.state.test(password));
    if (failedRules.length > 0) {
      setPasswordErrors(failedRules.map((rule) => rule.errorMessage));
      return;
    }

    let token = queryParams.get('token') as string;
    token = token?.replace(/\+/g, '__plus__');
    dispatch(fetchResetPassword({ email, password, confirmPassword, token }));
  };

  useEffect(() => {
    if (confirmBlurred) {
      if (password !== confirmPassword) {
        setError('Passwords do not match');
      } else {
        setError('');
      }
    }
  }, [password, confirmPassword, confirmBlurred]);

  useEffect(() => {
    if (resetPasswordState.data) {
      setMessage({
        content:resetPasswordState.data.message,
        color:"green"
      });
      if (resetPasswordState.data.message.includes('Password has been reset successfully')) {
        setTimeout(() =>{
          dispatch(resetResetPasswordState())
          navigate('/login')
        }, 3000);
      }
    }
    else{
      if(resetPasswordState.error && resetPasswordState.error.error){
        setMessage({
          content:resetPasswordState.error.error,
          color:"green"
        });
      }
    }
  }, [resetPasswordState.data, navigate]);

  const handleDemoClick = () => {
    // Demo modal açma işlemi
  };

  return (
    <>
      <div className="reset-password-page">
        <div className="reset-password-container">
          <h2>Reset Password</h2>
          <form onSubmit={handleResetPassword}>
            <div className="form-group">
              <label>Email</label>
              <input
                type="email"
                value={email}
                onChange={handleEmailChange}
                required
              />
              {emailError && <p className="error-message" style={{ color: 'red' }}>{emailError}</p>}
            </div>
            <div className="form-group">
              <label>New Password</label>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              {passwordErrors.length > 0 && (
                <ul className="password-errors">
                  {passwordErrors.map((err, index) => (
                    <li key={index} className="error-message" style={{ color: 'red' }}>{err}</li>
                  ))}
                </ul>
              )}
            </div>
            <div className="form-group">
              <label>Confirm New Password</label>
              <input
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                onBlur={() => setConfirmBlurred(true)}
                required
              />
            </div>
            {error && <p className="error-message" style={{ color: 'red' }}>{error}</p>}
            <button type="submit" disabled={resetPasswordState.loading || isButtonDisabled}>
              {resetPasswordState.loading ? <EmbedCodeLoading /> : 'Reset Password'}
            </button>
          </form>
          {message && <p style={{
            color:message.color
          }}>{message.content}</p>}
        </div>
      </div>
      <Footer onDemoClick={handleDemoClick} />
    </>
  );
}

