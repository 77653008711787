'use client'

import React, { useState, useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import Swal from 'sweetalert2'
import {
  ContentCopy,
  Save,
  Refresh,
  SettingsOutlined,
  Visibility,
  VisibilityOff,
  ColorLens,
  Landscape,
  ThreeDRotation,
  Fullscreen,
  ZoomIn,
  Person,
  PanTool,
  Architecture,
  CameraAlt,
  Explore,
  Polyline,
  Home,
  Share,
  ViewInAr,
  Cameraswitch
} from '@mui/icons-material'

import { UpdateModelIframeCSSButtonService } from '../../services/uploadedObject/update.iframeCSSButton.service'
import { useAppDispatch } from '../../store'
import { GetEmbedCodeInfoService } from '../../services/uploadedObject/get.embedCodeInfo.service'
import { getUploadedObjects } from '../../store/features/object/get.list.uploadedObject.slice'

interface ToolIcon {
  name: string
  css: string
  icon: React.ReactNode
  active: boolean
}

const lightPresetMap = {
  "Simple Grey": 0,
  "Sharp Highlights": 1,
  "Dark Sky": 2,
  "Grey Room": 3,
  "Photo Booth": 4,
  "Tranquility": 5,
  "Infinity Pool": 6,
  "Simple White": 7,
  "Riverbank": 8,
  "Contrast": 9,
  "Rim Highlights": 10,
  "Cool Light": 11,
  "Warm Light": 12,
  "Soft Light": 13,
  "Grid Light": 14
}

export default function EmbedCode() {
  const location = useLocation()
  const initialEmbedCode = location.state?.embedCode || ''
  const initialWebSite = location.state?.webSite || ''
  const name = location.state?.name || ''
  const [newEmbedCode, setNewEmbedCode] = useState<string>(initialEmbedCode)
  const [width, setWidth] = useState<string>("100%")
  const [height, setHeight] = useState<string>("600px")
  const [showIframe, setShowIframe] = useState<boolean>(true)
  const iframeRef = useRef<HTMLIFrameElement | null>(null)
  const [toolIcons, setToolIcons] = useState<ToolIcon[]>([
    { name: 'Section Analysis', css: 'toolbar-sectionTool', icon: <Cameraswitch />, active: false },
    { name: 'Pan', css: 'toolbar-panTool', icon: <PanTool />, active: false },
    { name: 'Zoom', css: 'toolbar-zoomTool', icon: <ZoomIn />, active: false },
    { name: 'Properties', css: 'toolbar-propertiesTool', icon: <SettingsOutlined />, active: false },
    { name: 'Orbit', css: 'toolbar-orbitTools', icon: <ThreeDRotation />, active: false },
    { name: 'Settings', css: 'toolbar-settingsTool', icon: <SettingsOutlined />, active: false },
    { name: 'Model Browser', css: 'toolbar-modelStructureTool', icon: <Polyline />, active: false },
    { name: 'First Person', css: 'toolbar-bimWalkTool', icon: <Person />, active: false },
    { name: 'Cam Instructions', css: 'toolbar-cameraSubmenuTool', icon: <CameraAlt />, active: false },
    { name: 'Explode Model', css: 'toolbar-explodeTool', icon: <ThreeDRotation />, active: false },
    { name: 'Measure', css: 'toolbar-measurementSubmenuTool', icon: <Landscape />, active: false },
    { name: 'Full Screen', css: 'toolbar-fullscreenTool', icon: <Fullscreen />, active: false },
    { name: 'ViewCube', css: 'viewcube', icon: <ViewInAr />, active: false },
    { name: 'ViewHome', css: 'homeViewWrapper', icon: <Home />, active: false },
  ])
  const [website, setWebsite] = useState<string>("")
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const dispatch = useAppDispatch()
  const b = sessionStorage.getItem('b') as string
  const [lightPreset, setLightPreset] = useState<string>("Simple Grey")
  const [backgroundColor, setBackgroundColor] = useState<string>("#FFFFFF")
  const [groundShadow, setGroundShadow] = useState<boolean>(false)
  const [groundReflection, setGroundReflection] = useState<boolean>(false)
  const [showSettings, setShowSettings] = useState<boolean>(true)

  useEffect(() => {
    const service = new GetEmbedCodeInfoService()
    const srcMatch = initialEmbedCode.match(/src=['"]([^'"]*)['"]/);
    if (srcMatch) {
      const srcValue = srcMatch[1]
      const splittedSrc = srcValue.split("/")
      
      const urn = splittedSrc[7]

      service.GetByStringManyParamsAsync([urn,name]).then((data) => {
        setNewEmbedCode(data.iframeCssButton)
        setWebsite(data.webSite)
        
        // Parse the embed code and set initial states
        const embedCodeSrc = data.iframeCssButton.match(/src=['"]([^'"]*)['"]/)?.[1] || ''
        const [, propertiesString] = embedCodeSrc.split('/properties/')
        const properties = new URLSearchParams(propertiesString || '')

        // Set width and height
        const widthMatch = data.iframeCssButton.match(/width=['"](\d+px)['"]/);
        const heightMatch = data.iframeCssButton.match(/height=['"](\d+px)['"]/);
        if (widthMatch) setWidth(widthMatch[1])
        if (heightMatch) setHeight(heightMatch[1])
  
        // Set light preset
        const lightPresetValue = properties.get('lightPreset')
        if (lightPresetValue) {
          const presetNumber = parseInt(lightPresetValue)
          const presetName = Object.keys(lightPresetMap).find(
            (key) => lightPresetMap[key as keyof typeof lightPresetMap] === presetNumber
          ) || "Simple Grey"
          setLightPreset(presetName)
        }
  
        // Set background color
        const backgroundColorValue = properties.get('backgroundColor')
        if (backgroundColorValue) {
          setBackgroundColor(`#${backgroundColorValue}`)
        }

        // Set ground shadow
        setGroundShadow(properties.get('setGroundShadow') === 'true')

        // Set ground reflection
        setGroundReflection(properties.get('setGroundReflection') === 'true')

        // Set tool icons
        const activeToolsString = properties.get('activeTools') || ''
        const activeTools = activeToolsString.split(',')
        setToolIcons((prevIcons) =>
          prevIcons.map((icon) => ({
            ...icon,
            active: activeTools.includes(icon.css),
          }))
        )
      })
    }
  }, [initialEmbedCode, name])
  
  const updateProperty = (propertyName: string, value: string) => {
    setNewEmbedCode(prevCode => {
      const srcMatch = prevCode.match(/src=['"]([^'"]*)['"]/);
      if (srcMatch) {
        let srcValue = srcMatch[1]
        const [baseUrl, propertiesString] = srcValue.split('/properties/')
        const properties = new URLSearchParams(propertiesString || '')
        
        if (value) {
          properties.set(propertyName, value)
        } else {
          properties.delete(propertyName)
        }

        const newSrc = `${baseUrl}/properties/${properties.toString()}`
        return prevCode.replace(/src=['"]([^'"]*)['"]/,  `src='${newSrc}'`)
      }
      return prevCode
    })
  }

  const updateToolIcons = (newToolIcons: ToolIcon[]) => {
    const activeTools = newToolIcons.filter(icon => icon.active).map(icon => icon.css).join(',')
    updateProperty('activeTools', activeTools)
  }

  const handleToggleIcon = (index: number) => {
    setToolIcons(prevIcons => {
      const newIcons = prevIcons.map((icon, i) => 
        i === index ? { ...icon, active: !icon.active } : icon
      )
      updateToolIcons(newIcons)
      return newIcons
    })
  }

  function extractContent() {
    const startIndex = newEmbedCode.indexOf("content/") + "content/".length;
    const endIndex = newEmbedCode.indexOf("'>", startIndex);
    
    if (startIndex !== -1 && endIndex !== -1) {
      navigator.clipboard.writeText(newEmbedCode.substring(startIndex, endIndex)).then(() => {
        Swal.fire({
          title: 'Copied!',
          text: 'SharePoint Urn code copied to clipboard',
          icon: 'success',
          timer: 1500,
          showConfirmButton: false
        })
      })
      .catch(() => {
        Swal.fire({
          title: 'Error',
          text: 'Failed to copy embed code',
          icon: 'error'
        })
      });
    }
    return null;
  }
  
  const handleCopy = () => {
    navigator.clipboard.writeText(newEmbedCode)
      .then(() => {
        Swal.fire({
          title: 'Copied!',
          text: 'Embed code copied to clipboard',
          icon: 'success',
          timer: 1500,
          showConfirmButton: false
        })
      })
      .catch(() => {
        Swal.fire({
          title: 'Error',
          text: 'Failed to copy SharePoint Urn code',
          icon: 'error'
        })
      })
  }

  const handleBackgroundColorChange = (color: React.ChangeEvent<HTMLInputElement>) => {
    const newColor = color.target.value.replace('#', '')
    setBackgroundColor(`#${newColor}`)
    updateProperty('backgroundColor', newColor)
  }
  
  const handleSavingSettings = async () => {
    setIsLoading(true)
    const service = new UpdateModelIframeCSSButtonService()
    const srcMatch = newEmbedCode.match(/src=['"]([^'"]*)['"]/);
    if (srcMatch) {
      const srcValue = srcMatch[1]
      const splittedSrc = srcValue.split("/")
      const urn = splittedSrc[7]
      const uid = splittedSrc[9]
      try {
        const data = await service.PostAsync({
          iframeCSSButton: newEmbedCode,
          webSite: website,
          urn: urn,
          uid: uid,
          name: name
        })
        if (data.isSuccess) {
          const service = new GetEmbedCodeInfoService()
          const updatedResponse = await service.GetByStringManyParamsAsync([urn,name])
          setNewEmbedCode(updatedResponse.iframeCssButton)
          setWebsite(updatedResponse.webSite)
          dispatch(
            getUploadedObjects({
              bucketKey: b
            })
          )
          Swal.fire({
            title: 'Success!',
            text: 'Settings saved successfully',
            icon: 'success',
            timer: 1500,
            showConfirmButton: false
          })
        }
      } catch (error) {
        console.log(error)
        Swal.fire({
          title: 'Error',
          text: 'Failed to save settings',
          icon: 'error'
        })
      } finally {
        setIsLoading(false)
      }
    } else {
      console.log("src not found")
      setIsLoading(false)
      Swal.fire({
        title: 'Error',
        text: 'Source URL not found in embed code',
        icon: 'error'
      })
    }
  }

  const handleUpdateIframe = () => {
    setShowIframe(true)
    if (iframeRef.current) {
      iframeRef.current.src = iframeRef.current.src
    }
  }

  const handleWidthChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newWidth = e.target.value
    setWidth(newWidth)
    setNewEmbedCode(prevCode => 
      prevCode.replace(/width=['"][\d.]+(?:px|%)?['"]/, `width='${newWidth}'`)
    )
    if (iframeRef.current) {
      iframeRef.current.style.width = newWidth
    }
  }

  const handleHeightChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newHeight = e.target.value
    setHeight(newHeight)
    setNewEmbedCode(prevCode => 
      prevCode.replace(/height=['"][\d.]+(?:px|%)?['"]/, `height='${newHeight}'`)
    )
    if (iframeRef.current) {
      iframeRef.current.style.height = newHeight
    }
  }

  const handleLightPresetChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value as keyof typeof lightPresetMap
    setLightPreset(value)
    updateProperty('lightPreset', lightPresetMap[value].toString())
  }

  const handleGroundShadowChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.checked
    setGroundShadow(newValue)
    updateProperty('setGroundShadow', newValue ? 'true' : '')
  }

  const handleGroundReflectionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.checked
    setGroundReflection(newValue)
    updateProperty('setGroundReflection', newValue ? 'true' : '')
  }

  return (
    <div className="bg-gray-100 h-auto overflow-hidden">
      {isLoading && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-white"></div>
        </div>
      )}
      <div className="mx-auto bg-white shadow-lg overflow-hidden h-auto">
        <div className="p-6 h-full flex flex-col">
          <h1 className="text-3xl font-bold text-center mb-6 text-gray-800">VEEWER Embed Code Editor</h1>
          <div className="flex space-x-2">
                <button
                  onClick={extractContent}
                  disabled={isLoading}
                  className="p-2 rounded-md hover:bg-gray-200 transition duration-200 ease-in-out"
                  title="SharePoint"
                >
                  <Share className="w-5 h-5 text-purple-600" />
                </button>
                <button
                  onClick={handleSavingSettings}
                  disabled={isLoading}
                  className="p-2 rounded-md hover:bg-gray-200 transition duration-200 ease-in-out"
                  title="Save"
                >
                  <Save className="w-5 h-5 text-green-600" />
                </button>
                <button
                  onClick={handleCopy}
                  disabled={isLoading}
                  className="p-2 rounded-md hover:bg-gray-200 transition duration-200 ease-in-out"
                  title="Copy"
                >
                  <ContentCopy className="w-5 h-5 text-blue-600" />
                </button>
                <button
                  onClick={() => setShowSettings(!showSettings)}
                  className="p-2 rounded-md hover:bg-gray-200 transition duration-200 ease-in-out"
                  title={showSettings ? 'Hide Settings' : 'Show Settings'}
                >
                  <SettingsOutlined className="w-5 h-5 text-gray-600" />
                </button>
              </div>
          <div className="flex flex-col lg:flex-row gap-6 flex-grow overflow-hidden">
            <div className="lg:w-1/3 space-y-4 overflow-y-auto">
              <div className="relative">
                <input
                  className="w-full p-2 pr-10 border border-gray-300 rounded-md"
                  placeholder="Enter website URL"
                  value={website}
                  onChange={(e) => setWebsite(e.target.value)}
                  disabled={isLoading}
                />
              </div>

              <div className="relative">
                <textarea
                  className="w-full p-3 pr-10 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent transition"
                  value={newEmbedCode}
                  onChange={(e) => setNewEmbedCode(e.target.value)}
                  rows={4}
                  disabled={isLoading}
                  placeholder="IFrame Embed Code"
                />
              </div>



              {showSettings && (
                <div className="space-y-4 bg-gray-50 p-4 rounded-md">
                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <label htmlFor="width" className="block text-sm font-medium text-gray-700 mb-1">Width</label>
                      <input
                        id="width"
                        className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent transition"
                        value={width}
                        onChange={handleWidthChange}
                        disabled={isLoading}
                      />
                    </div>
                    <div>
                      <label htmlFor="height" className="block text-sm font-medium text-gray-700 mb-1">Height</label>
                      <input
                        id="height"
                        className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent transition"
                        value={height}
                        onChange={handleHeightChange}
                        disabled={isLoading}
                      />
                    </div>
                  </div>

                  <div>
                    <label htmlFor="light-preset" className="block text-sm font-medium text-gray-700 mb-1">Light Preset</label>
                    <select
                      id="light-preset"
                      value={lightPreset}
                      onChange={handleLightPresetChange}
                      disabled={isLoading}
                      className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent transition"
                    >
                      {Object.keys(lightPresetMap).map((preset) => (
                        <option key={preset} value={preset}>
                          {preset}
                        </option>
                      ))}
                    </select>
                  </div>
                  <label htmlFor="backgroundColor" className="block text-sm font-medium text-gray-700">View Properties</label>
                 <div className='flex items-center space-x-5 mt-0'>
                 <div>
                   
                    <div className="flex items-center space-x-2">
                      <input
                        type="color"
                        id="backgroundColor"
                        value={backgroundColor}
                        onChange={handleBackgroundColorChange}
                        className="w-8 h-8 p-0 border-0 rounded-md cursor-pointer"
                      />
                      <span className="text-sm text-gray-600">{backgroundColor}</span>
                    </div>
                  </div>
                 <div className="flex items-center space-x-5">
                    <div className="flex items-center">
                      <input
                        type="checkbox"
                        id="groundShadow"
                        checked={groundShadow}
                        onChange={handleGroundShadowChange}
                        className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                      />
                      <label htmlFor="groundShadow" className="ml-2 block text-sm text-gray-900">
                        Ground Shadow
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        type="checkbox"
                        id="groundReflection"
                        checked={groundReflection}
                        onChange={handleGroundReflectionChange}
                        className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                      />
                      <label htmlFor="groundReflection" className="ml-2 block text-sm text-gray-900">
                        Ground Reflection
                      </label>
                    </div>
                  </div>
                  </div>
                 


                  <div>
                    <h3 className="text-lg font-semibold mb-2 text-gray-800">Tools</h3>
                    <div className="grid grid-cols-6 sm:grid-cols-7 gap-2">
                      {toolIcons.map((tool, index) => (
                        <button
                          key={index}
                          className={`p-2 rounded-lg transition-all flex flex-col items-center justify-center text-xs ${
                            tool.active ? 'bg-blue-100 text-blue-700' : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                          }`}
                          onClick={() => handleToggleIcon(index)}
                          title={tool.name}
                          disabled={isLoading}
                        >
                          {tool.icon}
                          <span className="mt-1">{tool.name}</span>
                        </button>
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="max-h-[84vh] lg:w-2/3 flex-grow overflow-hidden">
              {showIframe ? (
                <div className="bg-gray-50 p-4 rounded-lg relative h-full">
                  <div className="flex items-center mb-2">
                    <h2 className="text-xl font-semibold text-gray-800">Iframe Preview</h2>
                    <button
                      onClick={handleUpdateIframe}
                      disabled={isLoading}
                      className="p-2 rounded-md hover:bg-gray-200 transition duration-200 ease-in-out"
                      title="Refresh"
                    >
                      <Refresh className="w-5 h-5 text-gray-600" />
                    </button>
                  </div>
                  <div className="relative rounded-lg overflow-hidden">
                    <iframe
                      ref={iframeRef}
                      src={newEmbedCode.match(/src=['"]([^'"]*)['"]/)?.[1] || website}
                      style={{ width, height }}
                    />
                    {(!newEmbedCode.match(/src=['"]([^'"]*)['"]/)?.[1] && !website) && (
                      <div className="absolute inset-0 flex items-center justify-center bg-gray-200 text-gray-500">
                        No URL provided
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <div className="bg-gray-50 p-4 rounded-lg flex items-center justify-center h-full">
                  <button
                    onClick={() => setShowIframe(true)}
                    className="bg-blue-500 text-white px-6 py-3 rounded-md hover:bg-blue-600 transition duration-200 ease-in-out flex items-center space-x-2 text-lg"
                  >
                    <Visibility />
                    <span>Show Iframe</span>
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}