import { GetAWSFormFileRequestModel, GetAWSFormFileResponseModel } from "../../../models/aws/v2/get.form.file.aws.model";
import { BasePostService } from "../../base/base.post.service";
import { GetAWSFileService } from "../get.aws.file.service";



export class GetAWSFormFileService extends BasePostService<GetAWSFormFileRequestModel,GetAWSFormFileResponseModel>{
    constructor(controllerName:string="aws", endpointName:string="getFileForm"){
        super(controllerName, endpointName)
    }

    async GetFileWithPostRequestAsync(request: GetAWSFormFileRequestModel): Promise<File> {
        const response = await this.request.post(``, request, {
            responseType: 'blob', // Yanıtı blob olarak al
        }); 
        
        const getFileService=new GetAWSFileService()
        const fileResponse=await getFileService.GetByStringManyParamsAsync([request.bucketName,request.fileName])
        console.log(fileResponse)
        const fileName=fileResponse.file.name
        const file = new File([response.data], fileName, {
            type: response.data.type,
        });

        return file;
    }

}