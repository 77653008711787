import React, { useEffect } from 'react';
import Swal from 'sweetalert2';
import './App.css';
import './styles/global.scss';
import MainRoutes from './navigators/MainRoutes';
import { LogoutService } from './services/auth/logout.service';
import TawkTo from './components/Tawkto';
import { UploadStatusProvider } from './context/UploadStatusContext';

function App() {
  return (
    <UploadStatusProvider>

      <MainRoutes />
    </UploadStatusProvider>
  );
}

export default App;
