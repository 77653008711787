import { motion, AnimatePresence } from 'framer-motion';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';
import { http } from '../../config/axios.config';

export interface DemoModalProps {
	isOpen: boolean;
	onClose: () => void;
}

export default function DemoModal({ isOpen, onClose }: DemoModalProps) {
	const [formData, setFormData] = useState({
		fullName: '',
		email: '',
		phone: '',
		company: '',
		message: ''
	});

	const [isSubmitting, setIsSubmitting] = useState(false);
	const [submitStatus, setSubmitStatus] = useState<{
		type: 'success' | 'error' | null;
		message: string;
	}>({ type: null, message: '' });

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		const { id, value } = e.target;
		setFormData(prev => ({
			...prev,
			[id]: value
		}));
	};

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		setIsSubmitting(true);
		setSubmitStatus({ type: null, message: '' });

		try {
			const axiosInstance = http('demo-requests', '');
			
			// Send demo request
			const response = await axiosInstance.post('', {
				fullName: formData.fullName,
				email: formData.email,
				phone: formData.phone || null,
				company: formData.company,
				message: formData.message
			});

			if (response.data.isSuccess) {
				setSubmitStatus({
					type: 'success',
					message: response.data.message
				});

				setTimeout(() => {
					onClose();
					setFormData({
						fullName: '',
						email: '',
						phone: '',
						company: '',
						message: ''
					});
					setSubmitStatus({ type: null, message: '' });
				}, 2000);
			} else {
				throw new Error(response.data.message);
			}
		} catch (error: any) {
			setSubmitStatus({
				type: 'error',
				message: error.response?.data?.message || error.message || 'An error occurred. Please try again later.'
			});
		} finally {
			setIsSubmitting(false);
		}
	};

	return (
		<AnimatePresence>
			{isOpen && (
				<>
					{/* Backdrop */}
					<motion.div
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						exit={{ opacity: 0 }}
						onClick={onClose}
						className="fixed inset-0 bg-black/60 backdrop-blur-sm z-[9998]"
						style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0 }}
					/>

					{/* Modal Container */}
					<div className="fixed inset-0 z-[9999] overflow-y-auto">
						<div className="flex min-h-full items-center justify-center p-4 text-center">
							{/* Modal */}
							<motion.div
								initial={{ opacity: 0, scale: 0.95, y: 20 }}
								animate={{ opacity: 1, scale: 1, y: 0 }}
								exit={{ opacity: 0, scale: 0.95, y: 20 }}
								className="w-full max-w-xl transform overflow-hidden rounded-2xl bg-white shadow-2xl transition-all relative z-[9999]"
							>
								{/* Header */}
								<div className="bg-gradient-to-r from-[#16254c] to-[#1e3a8a] px-6 py-4 relative">
									<motion.button
										whileHover={{ scale: 1.1 }}
										whileTap={{ scale: 0.9 }}
										onClick={onClose}
										className="absolute right-4 top-4 text-white/80 hover:text-white transition-colors"
									>
										<CloseIcon />
									</motion.button>
									<h2 className="text-2xl font-bold text-white">Book a Demo</h2>
									<p className="text-blue-200 mt-1">Experience the power of VEEWER firsthand</p>
								</div>

								{/* Content */}
								<div className="p-6">
									<form onSubmit={handleSubmit} className="space-y-6">
										<div className="grid md:grid-cols-2 gap-6">
											<div>
												<label htmlFor="fullName" className="block text-sm font-medium text-gray-700 mb-1">
													Full Name *
												</label>
												<input
													type="text"
													id="fullName"
													value={formData.fullName}
													onChange={handleInputChange}
													className="w-full px-4 py-2.5 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 
													focus:border-blue-500 transition-all duration-200"
													placeholder="John Doe"
													required
												/>
											</div>

											<div>
												<label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
													Work Email *
												</label>
												<input
													type="email"
													id="email"
													value={formData.email}
													onChange={handleInputChange}
													className="w-full px-4 py-2.5 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 
													focus:border-blue-500 transition-all duration-200"
													placeholder="john@company.com"
													required
												/>
											</div>
										</div>

										<div>
											<label htmlFor="phone" className="block text-sm font-medium text-gray-700 mb-1">
												Phone Number <span className="text-gray-500">(Optional)</span>
											</label>
											<input
												type="tel"
												id="phone"
												value={formData.phone}
												onChange={handleInputChange}
												className="w-full px-4 py-2.5 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 
												focus:border-blue-500 transition-all duration-200"
												placeholder="+1 (555) 000-0000"
											/>
										</div>

										<div>
											<label htmlFor="company" className="block text-sm font-medium text-gray-700 mb-1">
												Company Name *
											</label>
											<input
												type="text"
												id="company"
												value={formData.company}
												onChange={handleInputChange}
												className="w-full px-4 py-2.5 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 
												focus:border-blue-500 transition-all duration-200"
												placeholder="Your Company"
												required
											/>
										</div>

										<div>
											<label htmlFor="message" className="block text-sm font-medium text-gray-700 mb-1">
												How can we help you? *
											</label>
											<textarea
												id="message"
												value={formData.message}
												onChange={handleInputChange}
												rows={4}
												className="w-full px-4 py-2.5 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 
												focus:border-blue-500 transition-all duration-200"
												placeholder="Tell us about your needs and requirements..."
												required
											/>
										</div>

										{submitStatus.message && (
											<div
												className={`p-4 rounded-lg ${
													submitStatus.type === 'success'
														? 'bg-green-100 text-green-700'
														: 'bg-red-100 text-red-700'
												}`}
											>
												{submitStatus.message}
											</div>
										)}

										<motion.button
											whileHover={{ scale: 1.02 }}
											whileTap={{ scale: 0.98 }}
											type="submit"
											disabled={isSubmitting}
											className={`w-full bg-gradient-to-r from-blue-600 to-blue-700 hover:from-blue-700 hover:to-blue-800 
											text-white font-medium py-3 px-6 rounded-lg transition-all duration-200 shadow-lg 
											hover:shadow-blue-500/25 flex items-center justify-center gap-2 ${
												isSubmitting ? 'opacity-75 cursor-not-allowed' : ''
											}`}
										>
											{isSubmitting ? (
												<>
													<svg
														className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
														xmlns="http://www.w3.org/2000/svg"
														fill="none"
														viewBox="0 0 24 24"
													>
														<circle
															className="opacity-25"
															cx="12"
															cy="12"
															r="10"
															stroke="currentColor"
															strokeWidth="4"
														></circle>
														<path
															className="opacity-75"
															fill="currentColor"
															d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
														></path>
													</svg>
													Sending...
												</>
											) : (
												<>
													Request Demo
													<svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
														<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
													</svg>
												</>
											)}
										</motion.button>
									</form>

									<p className="text-gray-500 text-sm mt-6 text-center">
										By submitting this form, you agree to our{' '}
										<a href="/terms-of-service" className="text-blue-600 hover:text-blue-700 underline">
											Terms of Services
										</a>{' '}
										and{' '}
										<a href="/privacy" className="text-blue-600 hover:text-blue-700 underline">
											Privacy Policy
										</a>
										.
									</p>
								</div>
							</motion.div>
						</div>
					</div>
				</>
			)}
		</AnimatePresence>
	);
} 