import React, { useState, useMemo } from 'react';
import '../../styles/onedrive/multipleUploadModel.css'
import { Button, Form, Modal } from 'react-bootstrap';
import { RootState, useAppDispatch, useAppSelector } from '../../store';
import { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { fetchBoxFolder } from '../../store/features/box/get.box.folder.slice';
import LittleCircleLoading from '../Loading/LittleCircleLoading';
import { GetFolderItemModel } from '../../models/oneDrive/folder/get.folder.item.model';
import { BoxData } from '../../pages/box/BoxUpload';
import { GetBoxIAMFilesResponse } from '../../models/oneDrive/file/get.iam.files.model';
import CircleLoading from '../Loading/CircleLoading';
import { Box, Checkbox, List, ListItem, ListItemIcon, ListItemText, Typography, TextField, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { InsertDriveFile } from '@mui/icons-material';

interface AddedFileListProps {
    file: string;
    bucket: string | undefined
    id: string;
    handleRemoveClick: () => void;
    fileIds: string[]
}

interface MultipleBoxUploadModalProps {
    show: boolean;
    onHide: () => void;
    fileList: BoxData[];
    setFileList: React.Dispatch<React.SetStateAction<BoxData[]>>;
    bucket: string | undefined;
    setOpenZipOption: React.Dispatch<React.SetStateAction<boolean>>;
    iamFileList: GetBoxIAMFilesResponse | undefined
    processQueue: (index: number) => Promise<void>
    step: string
    processToUpload: boolean
    waitingToManifest: boolean
    manifestStatus: string
    isContinueToProgress: boolean
    setIsContinueToProgress: React.Dispatch<React.SetStateAction<boolean>>
    isFinishedToLoading: boolean
    rootFile: string
    setRootFile: React.Dispatch<React.SetStateAction<string>>
    selectedFile: {
        name: string;
        size: string;
    }
    setSelectedFile: React.Dispatch<React.SetStateAction<{
        name: string;
        size: string;
    }>>
}

interface FileItem {
    name: string;
    size: string;
}

const AddedFileList: React.FC<AddedFileListProps> = ({
    file,
    bucket,
    id,
    handleRemoveClick,
    fileIds,
}) => {
    const [isPrompt, setIsPrompt] = useState(false);
    const [folder, setFolder] = useState({
        name: "",
        id: "",
        check: false
    })

    const dispatch = useAppDispatch();
    const folderSelector = useAppSelector((x) => x.getBoxFolderSlice);

    useEffect(() => {
        if (bucket && file) {
            const existFolderName = `${bucket}-${file}`;
            dispatch(fetchBoxFolder({
                folderName: existFolderName,
                bucketName: bucket
            }));
        }
    }, [bucket, file, dispatch]);

    useEffect(() => {
        if (
            folderSelector &&
            folderSelector.data &&
            folderSelector.data.folder &&
            !folderSelector.error &&
            !folderSelector.loading
        ) {
            const folder = folderSelector.data.folder as GetFolderItemModel;
            if (folder && folder.name && folder.id) {
                setFolder({
                    ...folder,
                    check: false
                });
                setIsPrompt(true);
            }
        }
    }, [folderSelector]);

    return (
        <>
            {id && file && (
                <div className="flex items-center bg-gray-100 rounded-lg p-3 mb-2 shadow-sm hover:shadow-md transition-shadow duration-300">
                    <svg className="w-5 h-5 text-gray-500 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 21h10a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v14a2 2 0 002 2z"></path>
                    </svg>
                    <span id={id} className="text-gray-700 font-medium truncate flex-grow">{file}</span>

                </div>
            )}
        </>
    );
};

export const MultipleBoxUploadModal: React.FC<MultipleBoxUploadModalProps> = ({
    show,
    onHide,
    fileList,
    setFileList,
    bucket,
    setOpenZipOption,
    iamFileList,
    step,
    isContinueToProgress,
    isFinishedToLoading,
    setIsContinueToProgress,
    processQueue,
    processToUpload,
    waitingToManifest,
    manifestStatus,
    rootFile,
    setRootFile,
    selectedFile,
    setSelectedFile
}) => {
    const fetchIAMFilesSelector = useAppSelector((s: RootState) => s.getBoxSubFilesSlice)
    const [isVisible, setIsVisible] = useState(false);
    const [searchTerm, setSearchTerm] = useState('')
    const [sortBy, setSortBy] = useState<'name' | 'size'>('name')
    const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc')

    const handleselectedFile = (file: FileItem) => {
        console.log(file)
        setSelectedFile(file)
    }

    const filteredAndSortedFiles = useMemo(() => {
        if (!iamFileList || !iamFileList.iamFiles) return [];
        return iamFileList.iamFiles
            .filter(file => file.name.toLowerCase().includes(searchTerm.toLowerCase()))
            .sort((a, b) => {
                if (sortBy === 'name') {
                    return sortOrder === 'asc'
                        ? a.name.localeCompare(b.name)
                        : b.name.localeCompare(a.name)
                } else {
                    const sizeA = typeof a.size === "string" ? parseInt(a.size) : a.size;
                    const sizeB = typeof b.size === "string" ? parseInt(b.size) : b.size;
                    return sortOrder === 'asc' ? sizeA - sizeB : sizeB - sizeA;
                }
            })
    }, [iamFileList, searchTerm, sortBy, sortOrder])

    return (
        <Modal
            show={show}
            onHide={() => {
                if (rootFile !== "") {
                    setOpenZipOption(false)
                    setRootFile("")
                }
                onHide()
            }}
        >
            <Modal.Header closeButton onClick={() => {
                window.location.reload();
            }}>
                <Modal.Title></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    fetchIAMFilesSelector.loading ? (
                        <div className="text-center">
                            <CircleLoading text='Extracting all files from zip' />
                        </div>
                    ) : (
                        <Form>
                            <Form.Group>
                                {
                                    iamFileList !== undefined && iamFileList.iamFiles.length > 0 && rootFile === "" ? (
                                        <Box className="bg-white p-6 max-w-2xl max-h-[75vh] mx-auto overflow-y-hidden">
                                            <Box className="flex items-center justify-between mb-4">
                                                <Typography variant="h5" component="h2" className="font-semibold text-gray-800">
                                                    Selected files
                                                </Typography>
                                            </Box>
                                            <Box className="mb-4">
                                                <Typography variant="subtitle1" className="font-medium text-gray-700">
                                                    Main file{!selectedFile.name && <span className='text-xs text-red-400'>{" ("}Please select the main file.{")"}</span>}
                                                </Typography>
                                                {selectedFile.name && <Box className="flex items-center justify-between p-2 bg-gray-50 rounded">
                                                    <Box className="flex items-center">
                                                        <Checkbox
                                                            edge="start"
                                                            checked={true}
                                                            tabIndex={-1}
                                                            disableRipple
                                                            className="text-blue-500"
                                                        />
                                                        <InsertDriveFile className="text-gray-400 mr-2" />
                                                        <Typography variant="body2">{selectedFile.name.split("/")[selectedFile.name.split("/").length - 1]}</Typography>
                                                    </Box>
                                                    <Box className="flex items-center">
                                                        <Typography variant="body2" className="text-gray-500 mr-2">
                                                            {selectedFile.size}
                                                        </Typography>
                                                    </Box>
                                                </Box>}
                                            </Box>
                                            <Box className="mb-4">
                                                <Typography variant="subtitle1" className="font-medium text-gray-700 mb-2">
                                                    Reference files ({filteredAndSortedFiles.length})
                                                </Typography>
                                                <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-2 mb-2">
                                                    <TextField
                                                        type="text"
                                                        placeholder="Search files..."
                                                        value={searchTerm}
                                                        onChange={(e) => setSearchTerm(e.target.value)}
                                                        className="flex-grow"
                                                        variant="outlined"
                                                        size="small"
                                                    />
                                                    <FormControl variant="outlined" size="small" className="w-full sm:w-[180px]">
                                                        <InputLabel>Sort by</InputLabel>
                                                        <Select
                                                            value={sortBy}
                                                            onChange={(e) => setSortBy(e.target.value as 'name' | 'size')}
                                                            label="Sort by"
                                                        >
                                                            <MenuItem value="name">Name</MenuItem>
                                                            <MenuItem value="size">Size</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                    <Button
                                                        variant="outline-secondary"
                                                        onClick={() => setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')}
                                                        className="w-full sm:w-auto"
                                                    >
                                                        {sortOrder === 'asc' ? '↑' : '↓'}
                                                    </Button>
                                                </div>
                                                <List className="divide-y divide-gray-200 max-h-[50vh] overflow-auto">
                                                    {filteredAndSortedFiles.map((file, i) => (
                                                        selectedFile.name != file.name &&
                                                        <ListItem
                                                            key={i}
                                                            dense
                                                            button
                                                            onClick={() => handleselectedFile({
                                                                ...file,
                                                                size: file.size.toString(),
                                                            })}
                                                            className="py-1"
                                                        >
                                                            <ListItemIcon>
                                                                <Checkbox
                                                                    edge="start"
                                                                    tabIndex={-1}
                                                                    disableRipple
                                                                    checked={selectedFile.name == file.name}
                                                                    className="text-blue-500"
                                                                />
                                                            </ListItemIcon>
                                                            <ListItemIcon>
                                                                <InsertDriveFile className="text-gray-400" />
                                                            </ListItemIcon>
                                                            <ListItemText
                                                                primary={file.name.split('/')[file.name.split('/').length - 1]}
                                                                className="text-sm"
                                                            />
                                                            <Typography variant="body2" className="text-gray-500">
                                                                {file.size}
                                                            </Typography>
                                                        </ListItem>
                                                    ))}
                                                </List>
                                            </Box>
                                        </Box>
                                    ) : (
                                        <>
                                            {fileList.map((file) => (
                                                <AddedFileList
                                                    key={file.name}
                                                    fileIds={fileList.map(file => file.id)}
                                                    file={file.name || ""}
                                                    handleRemoveClick={() => {
                                                        const index = fileList.indexOf(file);
                                                        if (index > -1) {
                                                            if (index === 0) {
                                                                setFileList([]);
                                                            } else {
                                                                setFileList((prev) => {
                                                                    const newList = [...prev];
                                                                    newList.splice(index, 1);
                                                                    return newList;
                                                                });
                                                            }
                                                        }
                                                    }}
                                                    id={file.id}
                                                    bucket={bucket}
                                                />
                                            ))}
                                        </>
                                    )
                                }
                            </Form.Group>
                        </Form>
                    )
                }
            </Modal.Body>
            <Modal.Footer>
                {!isVisible &&
                <Box className="flex flex-col items-center">
                    <Button
                        variant="primary"
                        disabled={selectedFile?.name?.length < 1}
                        className="w-full max-w-xs disabled:!bg-gray-300 disabled:!text-gray-500 disabled:border-0"
                        onClick={() => {
                            const splitList = selectedFile?.name?.split("/")
                            const newRootFileName = splitList[splitList?.length - 1]
                            setRootFile(newRootFileName)
                            setOpenZipOption(false)
                            setIsVisible(true);
                        }}
                    >
                        CONTINUE
                    </Button>
                </Box>}
                {isFinishedToLoading || step === "Finishing..." || manifestStatus === "Finishing..." && (
                    <>
                        {fileList.length > 1 ? (
                            <p style={{ color: "green", marginRight: "auto" }}>
                                Files have been uploaded successfully
                            </p>
                        ) : (
                            <p style={{ color: "green", marginRight: "auto" }}>
                                File has been uploaded successfully
                            </p>
                        )}
                    </>
                )}
                {((bucket && isVisible) || (!iamFileList)) && !isContinueToProgress && !fetchIAMFilesSelector.loading && (
                    <Button
                        className='uploadButton'
                        disabled={isContinueToProgress || processToUpload || fileList.length === 0}
                        variant={isContinueToProgress || processToUpload ? "secondary" : "primary"}
                        onClick={async () => {
                            setIsContinueToProgress(true)
                            await processQueue(0)
                        }}
                    >
                        Translate {fileList.length > 1 ? "Files" : "File"}
                    </Button>
                )}

                {isContinueToProgress && (
                    (manifestStatus !== "Finishing...") && (
                        <div className="flex items-center w-full justify-center py-4 bg-blue-50 rounded-lg shadow-sm">
                            <svg className="animate-spin h-5 w-5 mr-3 text-blue-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                            <span className="text-blue-700 font-medium">
                                {step ? step : "Translation process is being initiated..."}
                            </span>
                        </div>
                    ))}

                {
                    isContinueToProgress &&
                    (manifestStatus == "Finishing...") &&
                    <div className="flex items-center w-full justify-center py-4 bg-green-50 rounded-lg shadow-sm">
                        <svg className="h-5 w-5 mr-3 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                        </svg>
                        <span className="text-green-700 font-medium">
                            Translation process completed successfully!
                        </span>
                    </div>
                }
            </Modal.Footer>
        </Modal>
    );
};

export default MultipleBoxUploadModal;

