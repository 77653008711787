'use client'

import React, { useRef, useEffect, useState } from 'react'

export default function LoadingScreen({ textColor = '#1a56db', text = "Loading Your Profile", tailwindcss = "", loadingTime = "3s", isLoginMobile = false }) {
  return (
    <div className="flex flex-col items-center justify-center h-full min-h-[60vh] space-y-8 bg-white">
      <div className="relative w-16 h-16">
        <div className="absolute inset-0 border-t-4 border-blue-500 border-solid rounded-full animate-spin"></div>
        <div className="absolute inset-2 border-t-4 border-blue-300 border-solid rounded-full animate-spin-slow"></div>
      </div>
      <div className="text-center">
        <h3 className="text-xl font-semibold text-gray-800 mb-2">{text}</h3>
        <p className="text-gray-500 text-sm animate-pulse">Please wait while we prepare your data...</p>
      </div>
      <style jsx>{`
        @keyframes spin-slow {
          to {
            transform: rotate(-360deg);
          }
        }
        .animate-spin-slow {
          animation: spin-slow 3s linear infinite;
        }
      `}</style>
    </div>
  )
}

