import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './styles/global.scss'
import "../node_modules/bootstrap/dist/css/bootstrap.min.css"
import "../node_modules/react-widgets/styles.css";

import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './store';
import {Chart, ArcElement, Tooltip, Legend,CategoryScale,LinearScale,BarController,BarElement} from 'chart.js'
import {GoogleOAuthProvider} from "@react-oauth/google"
import App from './App';
Chart.register(ArcElement,Tooltip, Legend,CategoryScale,LinearScale,BarController,BarElement);



const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
   <Provider store={store}>
    <GoogleOAuthProvider clientId='214971657024-ov356idjm5hc3b4a8vi2urcpvf4nbtj9.apps.googleusercontent.com'>
       <App/>
    </GoogleOAuthProvider>
   </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
