import { useState } from 'react';
import { motion } from 'framer-motion';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';
import VeewerLogo from '../../assets/Veewer_white_logoo.png';
import codeoLogoWhite from '../../assets/home/codeoLogoWhite.png';
import DemoModal from '../DemoModal/DemoModal';

interface FooterProps {
	onDemoClick?: () => void;
}

export default function Footer({ onDemoClick }: FooterProps) {
	const [isDemoModalOpen, setIsDemoModalOpen] = useState(false);
	const currentYear = new Date().getFullYear();

	const handleDemoClick = () => {
		if (onDemoClick) {
			onDemoClick();
		} else {
			setIsDemoModalOpen(true);
		}
	};

	return (
		<footer className="bg-[#16254c] text-white">
			

			{/* Main Footer */}
			<div className="container mx-auto px-4 py-16">
				<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-12">
					{/* Company Info */}
					<div className="space-y-6">
						<img src={VeewerLogo} alt="Veewer" className="h-8 brightness-0 invert" />
						<p className="text-gray-300">
						Upload, share, and showcase your interactive 3D models. Let your audience explore your designs from every angle.
						</p>
						<div className="flex space-x-4">
							<a href="https://www.youtube.com/@VEEWER3D" target="_blank" rel="noopener noreferrer" className="text-gray-300 hover:text-white transition-colors">
								<YouTubeIcon className="w-6 h-6" />
							</a>
							<a href="https://www.linkedin.com/showcase/veewer/" target="_blank" rel="noopener noreferrer" className="text-gray-300 hover:text-white transition-colors">
								<LinkedInIcon className="w-6 h-6" />
							</a>
							<a href="https://www.instagram.com/veewer3d/" target="_blank" rel="noopener noreferrer" className="text-gray-300 hover:text-white transition-colors">
								<InstagramIcon className="w-6 h-6" />
							</a>
						</div>
					</div>

					{/* Quick Links */}
					<div className="flex flex-col text-left">
						<h3 className="text-lg font-semibold mb-6">Quick Links</h3>
						<ul className="space-y-4  pl-0 ">
							<li className="text-left"><a href="/" className="text-gray-300 hover:text-white transition-colors">Home</a></li>
							<li className="text-left"><a href="/products" className="text-gray-300 hover:text-white transition-colors">Products</a></li>
							<li className="text-left"><a href="/ekana-sofa" className="text-gray-300 hover:text-white transition-colors">Product Details</a></li>
							<li className="text-left"><a href="/configurator" className="text-gray-300 hover:text-white transition-colors">Configurator</a></li>
							<li className="text-left"><a href="/pricing" className="text-gray-300 hover:text-white transition-colors hidden">Pricing</a></li>
							<li className="text-left"><a href="/contact" className="text-gray-300 hover:text-white transition-colors">Contact</a></li>
							<li className="text-left"><a href="/pricing" className="text-gray-300 hover:text-white transition-colors">Pricing</a></li>
						</ul>
					</div>

					{/* Legal */}
					<div className="flex flex-col text-left">
						<h3 className="text-lg font-semibold mb-6">Legal</h3>
						<ul className="space-y-4 pl-0">
							<li className="text-left"><a href="/privacy" className="text-gray-300 hover:text-white transition-colors">Privacy Policy</a></li>
							<li className="text-left"><a href="/terms-of-service" className="text-gray-300 hover:text-white transition-colors">Terms of Service</a></li>
							<li className="text-left"><a href="/cookies" className="text-gray-300 hidden hover:text-white transition-colors">Cookie Policy</a></li>
						</ul>
					</div>

					{/* Contact */}
					<div className="flex flex-col">
						<h3 className="text-lg font-semibold mb-6 pl-0">Contact</h3>
						<ul className="space-y-4 text-gray-300 pl-0">
							<li className="text-left">
								<a href="/contact" className="hover:text-white transition-colors">
								Gallants Farm Gallants Lane, East Farleigh, Maidstone, England, ME15 0LF
								</a>
							</li>
							<li className="text-left">
								<a href="mailto:contact@veewer.com" className="hover:text-white transition-colors">
									contact@veewer.com
								</a>
							</li>
							<li className="text-left">
								<a href="tel:+902321234567" className="hover:text-white transition-colors">
									+44 7469 011663
								</a>
							</li>
						</ul>
					</div>
				</div>
			</div>

			{/* Bottom Footer */}
			<div className="border-t border-gray-700">
				<div className="container mx-auto px-4 py-6">
					<div className="flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0">
						<div className="text-gray-300 text-sm">
							© {currentYear} VEEWER. All rights reserved. A product of{' '}
							<a 
								href="https://www.codeosolutions.com" 
								target="_blank" 
								rel="noopener noreferrer"
								className="text-blue-400 hover:text-blue-300 transition-colors"
							>
								Codeo Solutions
							</a>
						</div>
						<div className="text-gray-300 text-sm">
							Registration Number: 14645731 | VAT: GB471553781 | Codeo Solutions Limited
						</div>
					</div>
				</div>
			</div>

			{/* Demo Modal */}
			{!onDemoClick && (
				<DemoModal 
					isOpen={isDemoModalOpen} 
					onClose={() => setIsDemoModalOpen(false)} 
				/>
			)}
		</footer>
	);
}
