import { GetFormFileRequestModel, GetFormFileResponseModel } from "../../../models/oneDrive/v2/get.form.file.model";
import { BasePostService } from "../../base/base.post.service";
import { GetFileService } from "../file/get.file.service";


export class GetFormFileService extends BasePostService<GetFormFileRequestModel,GetFormFileResponseModel>{
    constructor(controllerName:string="oneDrive", endpointName:string="getFileForm"){
        super(controllerName, endpointName)
    }

    async GetFileWithPostRequestAsync(request: GetFormFileRequestModel): Promise<File> {
        const response = await this.request.post(``, request, {
            responseType: 'blob', // Yanıtı blob olarak al
        }); 
        
        const getFileService=new GetFileService()
        const fileResponse=await getFileService.GetByStringManyParamsAsync([request.bucketName,request.id])
        console.log(fileResponse)
        const fileName=fileResponse.file.name
        const file = new File([response.data], fileName, {
            type: response.data.type,
        });

        return file;
    }

}