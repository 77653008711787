import React, { useState } from "react";

const WebhookCreator = () => {
  const [urn, setUrn] = useState("");
  const [email, setEmail] = useState("");
  const [status, setStatus] = useState("");

  const createWebhook = async () => {
    if (!urn || !email) {
      setStatus("Lütfen URN ve e-posta adresini giriniz.");
      return;
    }

    try {
      const currentDate = new Date();
      const timestamp = `${currentDate.getFullYear()}${String(
        currentDate.getMonth() + 1
      ).padStart(2, "0")}${String(currentDate.getDate()).padStart(2, "0")}${String(
        currentDate.getHours()
      ).padStart(2, "0")}${String(currentDate.getMinutes()).padStart(2, "0")}${String(
        currentDate.getSeconds()
      ).padStart(2, "0")}`; // YYYYMMDDHHMMSS formatında zaman damgası

      const workflowId = `${email}_${timestamp}`; // Workflow ID oluştur

      // Webhook oluşturma isteği
      const webhookResponse = await fetch(
        "https://developer.api.autodesk.com/webhooks/v1/systems/derivative/events/extraction.finished/hooks",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer <your_oauth_token>", // Autodesk API Token buraya eklenmeli
          },
          body: JSON.stringify({
            callbackUrl: "https://veewerdev.netlify.app/callback", // Yerel sunucu
            scope: {
              workflow: workflowId,
            },
          }),
        }
      );

      if (webhookResponse.ok) {
        setStatus("Webhook başarıyla oluşturuldu. İşlenmeye başlıyor...");

        // Model Derivative job gönderimi
        const jobResponse = await fetch(
          "https://developer.api.autodesk.com/modelderivative/v2/designdata/job",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer <your_oauth_token>", // Autodesk API Token buraya eklenmeli
            },
            body: JSON.stringify({
              input: {
                urn: urn,
              },
              output: {
                formats: [
                  {
                    type: "obj", // Dönüştürme formatı
                  },
                ],
              },
              misc: {
                workflow: workflowId,
              },
            }),
          }
        );

        if (jobResponse.ok) {
          setStatus("Job gönderildi. Webhook'un tamamlanmasını bekliyoruz...");
        } else {
          setStatus("Job gönderilirken hata oluştu.");
        }
      } else {
        setStatus("Webhook oluşturulurken hata oluştu.");
      }
    } catch (error) {
      console.error("Hata oluştu:", error);
      setStatus("Bir hata meydana geldi.");
    }
  };

  return (
    <div>
      <h1>Webhook Creator</h1>
      <div>
        <label>
          URN:{" "}
          <input
            type="text"
            value={urn}
            onChange={(e) => setUrn(e.target.value)}
            placeholder="Dosya URN'sini giriniz"
          />
        </label>
      </div>
      <div>
        <label>
          E-posta:{" "}
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Kullanıcı e-posta adresini giriniz"
          />
        </label>
      </div>
      <button onClick={createWebhook}>Webhook Oluştur</button>
      <p>{status}</p>
    </div>
  );
};

export default WebhookCreator;
