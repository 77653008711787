import React, { useEffect, useState } from 'react';
import '../../styles/onedrive/upload.css';
import { RootState, useAppDispatch, useAppSelector } from '../../store';
import CircleLoading from '../Loading/CircleLoading';
import { resetCreateState } from '../../store/features/object/create.uploadedObject.slice';
import { getUploadedObjectByUserId } from '../../store/features/object/get.list.uploadedObject.slice';
import { UploadedFile } from '../../models/object/uploadedObject/put.uploadedObject.request.model';
import { SelectableZipFiles, StatusValues } from '../../pages/object/ObjectList';
import { Box, Typography, List, ListItem, ListItemIcon, ListItemText, Checkbox, IconButton, Button, Dialog, DialogTitle, DialogContent, Grid, DialogActions, TextField, Select, MenuItem, CircularProgress } from '@mui/material';
import { InsertDriveFile, Search, Sort } from '@mui/icons-material';
import FileFormats from './FileFormats';
import { resetCreditError } from '../../store/features/object/check.credit.slice';
import { resetCreditWhenUploadError } from '../../store/features/object/check.credit.when.upload.slice';
import JSZip from "jszip";
import path from "path-browserify"
import { resetStartUploadModelsError } from '../../store/features/object/uploadV2/starting.upload.models.slice';
import { LoginResponse } from '../../models/auth/login.model';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import FileDownloadDoneIcon from '@mui/icons-material/FileDownloadDone';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CloseIcon from '@mui/icons-material/Close';
import Swal from 'sweetalert2';
import { useUploadStatus } from '../../context/UploadStatusContext';
import { UploadManager, UploadProgress, UploadStage } from '../../services/upload/UploadManager';
import SelectedFilesList from './SelectedFilesList';

interface DragAndDropProps {
	handleCloseModal: () => void;
	setIsDragDrop: React.Dispatch<React.SetStateAction<boolean>>;
	selectedFiles: UploadedFile[];
	setSelectedFiles: React.Dispatch<React.SetStateAction<UploadedFile[]>>;
	setZipFile: React.Dispatch<React.SetStateAction<SelectableZipFiles | undefined>>;
	zipFile: SelectableZipFiles | undefined;
	processZipFile: (file: File) => Promise<SelectableZipFiles | undefined>;
	openZipOption: boolean;
	setOpenZipOption: React.Dispatch<React.SetStateAction<boolean>>;
	extractZipStatus: boolean;
	setExtractZipStatus: React.Dispatch<React.SetStateAction<boolean>>;
	creditStatus: string;
	setCreditStatus: React.Dispatch<React.SetStateAction<string>>;
	setManifestStatus: React.Dispatch<React.SetStateAction<string>>;
	setSteppedFileName: React.Dispatch<React.SetStateAction<string>>;
	setStep: React.Dispatch<React.SetStateAction<string>>;
	invalidFileMessage: string;
	setInvalidFileMessage: React.Dispatch<React.SetStateAction<string>>;
	isFinishedToLoading: boolean;
	setIsFinishedToLoading: React.Dispatch<React.SetStateAction<boolean>>;
	afterUploadError: string;
	setAfterUploadError: React.Dispatch<React.SetStateAction<string>>;
	startToGetChunkStatus: boolean;
	setStartToGetChunkStatus: React.Dispatch<React.SetStateAction<boolean>>;
	processToUpload: boolean;
	setProcessToUpload: React.Dispatch<React.SetStateAction<boolean>>;
	isProcessing: boolean;
	setIsProcessing: React.Dispatch<React.SetStateAction<boolean>>;
	waitingForManifest: boolean;
	setWaitingForManifest: React.Dispatch<React.SetStateAction<boolean>>;
	step: string;
	steppedFileName: string;
	manifestStatus: string;
	currentUploadIndex: number;
	isStarted: boolean;
	setIsStarted: React.Dispatch<React.SetStateAction<boolean>>;
	statusValues: StatusValues[];
	setStatusValues: React.Dispatch<React.SetStateAction<StatusValues[]>>;
}

interface SelectedFile {
  fileName: string;
  file: string;
  fileSize: string;
  rootFileName?: string;
}

// Dosya durumunu temsil eden interface
interface FileStatus extends UploadProgress {
	isProcessed: boolean;
	currentOperation?: string;
	error?: string;
}

// Upload progress response interface'i
interface UploadProgressResponse {
	progress: number;
	bytesRead: number;
	totalBytes: number;
	path: string;
	fileName: string;
	completed: boolean;
}

const ZipSelectionDialog: React.FC<{
	zipFile: SelectableZipFiles;
	onSelect: (file: any) => void;
	selectedFile: SelectedFile;
	searchTerm: string;
	setSearchTerm: (term: string) => void;
	sortBy: string;
	setSortBy: (sort: string) => void;
	sortOrder: string;
	setSortOrder: (order: string) => void;
	onContinue: () => void;
	setOpenZipOption: (value: boolean) => void;
	currentZipName: string;
	totalZips: number;
	currentZipIndex: number;
}> = ({ zipFile, onSelect, selectedFile, searchTerm, setSearchTerm, sortBy, setSortBy, sortOrder, setSortOrder, onContinue, setOpenZipOption, currentZipName, totalZips, currentZipIndex }) => {
	
	const processedZipCount = currentZipIndex + 1;
	const buttonText = processedZipCount === totalZips ? 'Continue' : `Next ZIP (${processedZipCount}/${totalZips})`;

	const filteredAndSortedFiles = React.useMemo(() => {
		let files = [...zipFile.subFiles];

		if (searchTerm) {
			files = files.filter(file => 
				file.fileName.toLowerCase().includes(searchTerm.toLowerCase())
			);
		}

		files.sort((a, b) => {
			if (sortBy === 'name') {
				const comparison = a.fileName.toLowerCase().localeCompare(b.fileName.toLowerCase());
				return sortOrder === 'asc' ? comparison : -comparison;
			} else {
				const sizeA = parseFloat(a.fileSize.toString());
				const sizeB = parseFloat(b.fileSize.toString());
				return sortOrder === 'asc' ? sizeA - sizeB : sizeB - sizeA;
			}
		});

		return files;
	}, [zipFile.subFiles, searchTerm, sortBy, sortOrder]);

	return (
		<div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center p-4 z-[60]">
			<div className="bg-white rounded-xl shadow-xl w-full max-w-2xl h-[90vh] flex flex-col my-4">
				{/* Header */}
				<div className="p-4 border-b shrink-0">
					<div className="flex items-center justify-between">
						<div>
							<h2 className="text-lg font-semibold text-gray-900">
								Select Root File
							</h2>
							<div className="mt-1 flex items-center gap-2 text-sm text-gray-500">
								<span className="font-medium truncate max-w-[300px]">
									{currentZipName}
								</span>
								<span className="text-gray-400">•</span>
								<span>ZIP {processedZipCount} of {totalZips}</span>
							</div>
							<p className="mt-1 text-sm text-gray-500">
								Please select the main file from this ZIP archive
							</p>
						</div>
						<button
							onClick={() => setOpenZipOption(false)}
							className="p-1.5 hover:bg-gray-100 rounded-lg transition-colors"
						>
							<CloseIcon className="w-5 h-5 text-gray-400" />
						</button>
					</div>
				</div>

				{/* Search & Sort - Sabit yükseklik */}
				<div className="p-4 border-b bg-gray-50 shrink-0">
					<div className="flex items-center gap-3">
						<div className="flex-1 relative">
							<Search className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400 w-5 h-5" />
							<input
								type="text"
								placeholder="Search files..."
								value={searchTerm}
								onChange={(e) => setSearchTerm(e.target.value)}
								className="w-full pl-10 pr-4 py-2 bg-white border border-gray-200 rounded-lg"
							/>
						</div>
						<select
							value={sortBy}
							onChange={(e) => setSortBy(e.target.value)}
							className="px-3 py-2 bg-white border border-gray-200 rounded-lg"
						>
							<option value="name">Name</option>
							<option value="size">Size</option>
						</select>
						<button
							onClick={() => setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')}
							className="p-2 bg-white border border-gray-200 rounded-lg"
						>
							<Sort className={`transform ${sortOrder === 'desc' ? 'rotate-180' : ''}`} />
						</button>
					</div>
				</div>

				{/* File List - Esnek yükseklik */}
				<div className="flex-1 overflow-y-auto min-h-0 relative">
					<div className="absolute inset-0 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-300 hover:scrollbar-thumb-gray-400 scrollbar-track-gray-100">
						<div className="divide-y">
							{filteredAndSortedFiles.map((file, i) => (
								<div
									key={i}
									onClick={() => onSelect(file)}
									className={`flex items-center px-4 py-3 cursor-pointer ${
										selectedFile.fileName === file.fileName
											? 'bg-blue-50'
											: 'hover:bg-gray-50'
									}`}
								>
									<Checkbox
										checked={selectedFile.fileName === file.fileName}
										className="text-blue-500"
									/>
									<div className="ml-3 flex-1">
										<p className="text-sm font-medium text-gray-900">{file.fileName}</p>
										<p className="text-xs text-gray-500">{file.fileSize.toFixed(2)} KB</p>
									</div>
								</div>
							))}
						</div>
					</div>
				</div>

				{/* Actions - Sabit yükseklik */}
				<div className="p-4 border-t bg-gray-50 shrink-0">
					<div className="flex justify-end gap-3">
						<button
							onClick={() => setOpenZipOption(false)}
							className="px-4 py-2 text-sm border border-gray-200 rounded-lg hover:bg-gray-100"
						>
							Cancel
						</button>
						<button
							onClick={onContinue}
							disabled={!selectedFile.fileName}
							className="px-4 py-2 text-sm bg-blue-600 text-white rounded-lg hover:bg-blue-700 disabled:opacity-50"
						>
							{buttonText}
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

const ErrorDisplay: React.FC<{
	error: string | null;
	onCancel: () => void;
}> = ({ error, onCancel }) => (
	<div className="text-center p-6 bg-red-50 rounded-lg">
		<ErrorOutlineIcon className="text-red-500 w-12 h-12 mb-4" />
		<p className="text-red-600 mb-4">{error}</p>
		<button
			onClick={onCancel}
			className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700"
		>
			Close
		</button>
	</div>
);

const modalStyles = {
	overlay: "fixed inset-0 bg-black/30 flex items-center justify-center p-4 z-50",
	container: "bg-white rounded-xl shadow-xl w-full max-w-3xl min-h-[400px] h-auto max-h-[95vh] flex flex-col",
	content: "flex flex-col h-full overflow-hidden",
	header: "flex items-center justify-between p-4 border-b shrink-0",
	body: "flex-1 overflow-y-auto p-4 scrollbar-thin scrollbar-thumb-gray-300 hover:scrollbar-thumb-gray-400 scrollbar-track-transparent"
};

const DragDropArea: React.FC<{
	handleFileInput: (e: React.ChangeEvent<HTMLInputElement>) => Promise<void>;
	handleFileInputWithDrop: (e: React.DragEvent<HTMLDivElement>) => Promise<void>;
	isDisabled: boolean;
}> = ({ handleFileInput, handleFileInputWithDrop, isDisabled }) => {
	const fileInputRef = React.useRef<HTMLInputElement>(null);

	return (
		<div
			onDrop={(e) => handleFileInputWithDrop(e)}
			onDragOver={(e) => e.preventDefault()}
			className="relative group border-2  border-dashed border-gray-200 hover:border-blue-400 rounded-2xl bg-gray-50/50 hover:bg-blue-50/50 transition-all duration-300"
		>
			<input
				ref={fileInputRef}
				type="file"
				onChange={handleFileInput}
				disabled={isDisabled}
				hidden
				multiple
			/>
			<div className="p-8 text-center">
				<div className="w-20 h-20 bg-gradient-to-br from-blue-500 to-blue-600 rounded-2xl mx-auto flex items-center justify-center mb-6 group-hover:scale-110 transition-transform duration-300 shadow-lg shadow-blue-500/20">
					<CloudUploadIcon className="text-white w-10 h-10" />
				</div>
				<h3 className="text-xl font-semibold text-gray-900 mb-2">
					Drag & Drop Files
				</h3>
				<p className="text-gray-600 mb-6">
					or click to browse from your computer
				</p>
				<button 
					onClick={() => fileInputRef.current?.click()}
					className="inline-flex items-center px-6 py-3 bg-gradient-to-r from-blue-500 to-blue-600 text-white font-medium rounded-xl hover:from-blue-600 hover:to-blue-700 transition-all duration-300 shadow-lg shadow-blue-500/20 hover:shadow-xl hover:shadow-blue-500/30"
				>
					<FileUploadIcon className="w-5 h-5 mr-2" />
					Choose Files
				</button>
				<p className="mt-4 text-sm text-gray-500">
					Supported formats: IPT, DWG, DWFX, RVT and more
				</p>
				<p className="text-xs text-gray-400 italic">
					Note: You can drag and drop more files here while others are uploading. Files will be processed in the order they are added.
				</p>
			</div>
		</div>
	);
};

const MultipleUploadModal: React.FC<DragAndDropProps> = ({
	handleCloseModal,
	setIsDragDrop,
	selectedFiles,
	setSelectedFiles,
	setZipFile,
	zipFile,
	processZipFile,
	openZipOption,
	setOpenZipOption,
	extractZipStatus,
	setExtractZipStatus,
	creditStatus,
	setCreditStatus,
	setManifestStatus,
	setSteppedFileName,
	setStep,
	invalidFileMessage,
	setInvalidFileMessage,
	isFinishedToLoading,
	setIsFinishedToLoading,
	afterUploadError,
	setAfterUploadError,
	startToGetChunkStatus,
	setStartToGetChunkStatus,
	processToUpload,
	setProcessToUpload,
	isProcessing,
	setIsProcessing,
	waitingForManifest,
	setWaitingForManifest,
	step,
	steppedFileName,
	manifestStatus,
	currentUploadIndex,
	isStarted,
	setIsStarted,
	statusValues,
	setStatusValues
}) => {
	const dispatch = useAppDispatch();
	const uploadedFilesSelector = useAppSelector((s: RootState) => s.createUploadedObject);
	const startUploadModelsSelector = useAppSelector((s: RootState) => s.startUploadModelsSlice)
	const { uploadStatuses, updateUploadStatus } = useUploadStatus();
	const [selectedFile, setSelectedFile] = useState<SelectedFile>({
		fileName: "",
		file: "",
		fileSize: "",
		rootFileName: "",
	});
	const [currentZipIndex, setCurrentZipIndex] = useState(0);
	const [searchTerm, setSearchTerm] = useState('');
	const [sortBy, setSortBy] = useState('name');
	const [sortOrder, setSortOrder] = useState('asc');
	const [showSupportedFormats, setShowSupportedFormats] = useState(false);
	const checkCreditWhenUploadSelector = useAppSelector(x => x.checkCreditUploadedObjectWhenUploadSlice)
	const [lastAddedMainFile, setLastAddedMainFile] = useState<SelectedFile>({
		fileName: "",
		file: "",
		fileSize: "",
		rootFileName: '',
	});
	const [processingZipFile, setProcessingZipFile] = useState<string>('');
	const [currentZipQueue, setCurrentZipQueue] = useState<File[]>([]);
	const [fileStatuses, setFileStatuses] = useState<Map<string, FileStatus>>(new Map());
	const [processedCount, setProcessedCount] = useState<number>(0);
	const [activeUploads, setActiveUploads] = useState<Set<string>>(new Set());
	const uploadManager = UploadManager.getInstance();
	const [uploadProgress, setUploadProgress] = useState<Map<string, UploadProgress>>(new Map());
	const [totalZipCount, setTotalZipCount] = useState(0);

	useEffect(() => {
		const subscription = uploadManager.getProgress().subscribe(progress => {
			console.log('New upload progress:', progress);
			setUploadProgress(progress);
		});

		return () => subscription.unsubscribe();
	}, []);

	useEffect(() => {
		if (step && steppedFileName) {
			try {
				if (typeof step === 'string' && step.startsWith('{')) {
					const response = JSON.parse(step);
					if ('bytesRead' in response) {
						uploadManager.handleRootPathResponse(response, steppedFileName);
					}
				} else if (step === 'preparing') {
					uploadManager.updateProgress(steppedFileName, {
						stage: UploadStage.PREPARING,
						message: 'Preparing file for upload...'
					});
				}
			} catch (error) {
				console.error('Error handling upload progress:', error);
			}
		}
	}, [step, steppedFileName]);

	useEffect(() => {
		if (manifestStatus && steppedFileName) {
			try {
				const response = typeof manifestStatus === 'string' ? 
					JSON.parse(manifestStatus) : manifestStatus;
				uploadManager.handleManifestResponse(response, steppedFileName);
			} catch (error) {
				console.error('Error handling manifest status:', error);
			}
		}
	}, [manifestStatus, steppedFileName]);

	const FinishedSection = () =>{
	
		return  (
			<div>
			  <span>
				<h3 style={{ color: "green" }}>Finished.</h3>
			  </span>
			</div>
		  );
	}
	  
	const handleCloseAttempt = () => {
		const allCompleted = Array.from(uploadProgress.values()).every(p => p.stage === UploadStage.COMPLETED);
		
		// If all files are completed, close without warning
		if (allCompleted) {
			clearAllStates();
			handleCloseModal();
			return;
		}

		// Only show warning if there are unfinished uploads
		const hasUnfinishedFiles = selectedFiles.some(file => {
			const status = uploadProgress.get(file.file.name);
			return !status || status.stage !== UploadStage.COMPLETED;
		});

		if (hasUnfinishedFiles && (isProcessing || isStarted)) {
			Swal.fire({
				title: 'Warning!',
				text: 'There are unfinished uploads. Closing now may cause errors. Are you sure you want to continue?',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Yes, close it',
				cancelButtonText: 'No, keep it open'
			}).then((result) => {
				if (result.isConfirmed) {
					clearAllStates();
					handleCloseModal();
				}
			});
		} else {
			clearAllStates();
			handleCloseModal();
		}
	};

	useEffect(() => {
		const handleBeforeUnload = (e: BeforeUnloadEvent) => {
			if (isProcessing || isStarted || selectedFiles.length > 0) {
				e.preventDefault();
				e.returnValue = '';
			}
		};

		window.addEventListener('beforeunload', handleBeforeUnload);
		return () => window.removeEventListener('beforeunload', handleBeforeUnload);
	}, [isProcessing, isStarted, selectedFiles]);

	const handleselectedFile = (file: any) => {
		if (!zipFile) return;

		setSelectedFile({
			fileName: file.fileName,
			file: file.file,
			fileSize: file.fileSize.toString(),
			rootFileName: file.fileName
		});
	};

	const handleContinueRootFileSelection = async () => {
		if (!zipFile || !selectedFile.fileName) return;

		// Mevcut zip için root file'ı kaydet
		await handleSelectFileAsRoot(zipFile.mainZipFile, selectedFile.fileName);

		// Kuyrukta bekleyen zip varsa işle
		if (currentZipQueue.length > 0) {
			const nextZip = currentZipQueue[0];
			const remainingZips = currentZipQueue.slice(1);
			setCurrentZipQueue(remainingZips);
			setCurrentZipIndex(prev => prev + 1);
			await processZipFile(nextZip);
		} else {
			// Tüm zip'ler tamamlandı
			setOpenZipOption(false);
			setZipFile(undefined);
			setCurrentZipIndex(0);
			setTotalZipCount(0);
			setSelectedFile({
				fileName: "",
				file: "",
				fileSize: "",
				rootFileName: "",
			});
		}
	};

	const handleSelectFileAsRoot = async (file: File, rootFileName: string) => {
		setSelectedFiles(prev => [
			...prev,
			{ file, rootFileName }
		]);
		
		updateFileStatus(file.name, {
			stage: UploadStage.QUEUED,
			currentOperation: 'Ready to upload'
		});
	};

	const loginInfo = sessionStorage.getItem('login') as string;
	const jsonLoginInfo = JSON.parse(loginInfo) as LoginResponse

	useEffect(() => {
		document.body.style.overflow = 'hidden';
		return () => {
			document.body.style.overflow = 'auto';
		};
	}, []);  

	useEffect(() => {
		console.log(selectedFiles);
	}, [selectedFiles])

	const handleCancel = () => {
		dispatch(resetCreateState());
		
		dispatch(resetCreditError());
		dispatch(resetCreditWhenUploadError());
		setInvalidFileMessage("");
		setAfterUploadError("");
		
		dispatch(resetStartUploadModelsError());
	};

	const error = React.useMemo(() => {
		if (uploadedFilesSelector.error) return uploadedFilesSelector.error;
		if (checkCreditWhenUploadSelector.error) return checkCreditWhenUploadSelector.error.message;
																		if (startUploadModelsSelector.error) {
																			return typeof startUploadModelsSelector.error === 'object' && startUploadModelsSelector.error.error
																				? startUploadModelsSelector.error.error
																				: startUploadModelsSelector.error;
																		}
																		if (invalidFileMessage) return invalidFileMessage;
																		if (afterUploadError) return afterUploadError;
		return null;
	}, [
		uploadedFilesSelector.error,
		checkCreditWhenUploadSelector.error,
		startUploadModelsSelector.error,
		invalidFileMessage,
		afterUploadError
	]);

	const handleRemoveFile = (index: number) => {
		if (index === -1) {
			// Remove all files
			setSelectedFiles([]);
			uploadManager.clearAll();
			setFileStatuses(new Map());
			setProcessedCount(0);
		} else {
			setSelectedFiles(prev => {
				const newFiles = [...prev];
				const removedFile = newFiles[index];
				
				// Remove the file's progress from UploadManager
				uploadManager.removeProgress(removedFile.file.name);
				
				// Remove the file from the list
				newFiles.splice(index, 1);
				return newFiles;
			});
		}
	};

	const clearAllStates = () => {
		// Reset file selection states
		setSelectedFile({
			fileName: "",
			file: "",
			fileSize: "",
			rootFileName: "",
		});
		setSelectedFiles([]);
		setLastAddedMainFile({
			fileName: "",
			file: "",
			fileSize: "",
			rootFileName: "",
		});

		// Reset ZIP related states
		setZipFile(undefined);
		setOpenZipOption(false);
		setExtractZipStatus(false);
		setCurrentZipIndex(0);
		setCurrentZipQueue([]);
		setSearchTerm("");
		setSortBy("name");
		setSortOrder("asc");

		// Reset upload progress states
		setIsProcessing(false);
		setIsStarted(false);
		setWaitingForManifest(true);
		setSteppedFileName("");
		setStep("");
		setManifestStatus("");
		setStartToGetChunkStatus(false);
		setProcessToUpload(false);
		setIsFinishedToLoading(false);
		setProcessingZipFile("");
		setStatusValues([]);

		// Reset error states
		setInvalidFileMessage("");
		setAfterUploadError("");
		setCreditStatus("");

		// Reset file tracking states
		setFileStatuses(new Map());
		setProcessedCount(0);
		setActiveUploads(new Set());

		// Clear UploadManager state
		uploadManager.clearAll();

		// Reset any Redux states if needed
		dispatch(resetCreateState());
		dispatch(resetCreditError());
		dispatch(resetCreditWhenUploadError());
		dispatch(resetStartUploadModelsError());
		setTotalZipCount(0);
	};

	const handleStartNewUpload = () => {
		clearAllStates();
		// Force a small delay to ensure all states are cleared before allowing new uploads
		setTimeout(() => {
			setIsStarted(false);
		}, 100);
	};

	const handleFileInput = async (e: React.ChangeEvent<HTMLInputElement>) => {
		const newFiles = Array.from(e.target.files || []);
		newFiles.forEach(file => {
			uploadManager.updateProgress(file.name, {
				stage: UploadStage.QUEUED,
				message: 'Ready to upload',
				currentOperation: 'Ready to upload'
			});
		});

		const zipFiles = newFiles.filter(file => file.name.endsWith('.zip'));
		const nonZipFiles = newFiles.filter(file => !file.name.endsWith('.zip'));

		// Add non-zip files first
		if (nonZipFiles.length > 0) {
			setSelectedFiles(prevFiles => [
				...prevFiles,
				...nonZipFiles.map(file => ({ file, rootFileName: "" }))
			]);
		}

		// Process zip files
		if (zipFiles.length > 0) {
			const firstZip = zipFiles[0];
			uploadManager.updateProgress(firstZip.name, {
				stage: UploadStage.PREPARING,
				message: 'Extracting ZIP contents...'
			});

			// ZIP işleme sayaçlarını sıfırla ve toplam ZIP sayısını kaydet
			setCurrentZipIndex(0);
			setCurrentZipQueue(zipFiles.slice(1));
			setTotalZipCount(zipFiles.length);
			
			await processZipFile(firstZip);
			setOpenZipOption(true);
		}

		e.target.value = '';
	};

	const handleFileInputWithDrop = async (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		const files: File[] = Array.from(e.dataTransfer.files || []);
		const zipFiles = files.filter(file => file.name.endsWith('.zip'));
		const nonZipFiles = files.filter(file => !file.name.endsWith('.zip'));

		// Add non-zip files first
		if (nonZipFiles.length > 0) {
			nonZipFiles.forEach(file => {
				uploadManager.updateProgress(file.name, {
					stage: UploadStage.QUEUED,
					message: 'Ready to upload',
					currentOperation: 'Ready to upload'
				});
			});

			setSelectedFiles(prevFiles => [
						...prevFiles,
				...nonZipFiles.map(file => ({ file, rootFileName: "" }))
			]);
		}

		// Process zip files
		if (zipFiles.length > 0) {
			const firstZip = zipFiles[0];
			uploadManager.updateProgress(firstZip.name, {
				stage: UploadStage.PREPARING,
				message: 'Extracting ZIP contents...'
			});

			// ZIP işleme sayaçlarını sıfırla ve toplam ZIP sayısını kaydet
			setCurrentZipIndex(0);
			setCurrentZipQueue(zipFiles.slice(1));
			setTotalZipCount(zipFiles.length);
			
			await processZipFile(firstZip);
			setOpenZipOption(true);
		}

		e.dataTransfer.clearData();
	};

	const updateFileStatus = (
		fileName: string,
		update: Partial<FileStatus>
	) => {
		console.log('Updating file status:', fileName, update);
		uploadManager.updateProgress(fileName, {
			stage: update.stage,
			progress: update.progress,
			message: update.message,
			currentOperation: update.currentOperation,
			bytesUploaded: update.bytesUploaded,
			totalBytes: update.totalBytes
		});
	};

	const handleUploadProgress = (fileName: string, progressData: UploadProgressResponse) => {
		console.log('Handling upload progress:', fileName, progressData);
		uploadManager.updateProgress(fileName, {
			stage: UploadStage.UPLOADING,
			progress: progressData.progress,
			bytesUploaded: progressData.bytesRead,
				totalBytes: progressData.totalBytes,
				message: `Uploading: ${(progressData.bytesRead / (1024 * 1024)).toFixed(1)}MB / ${(progressData.totalBytes / (1024 * 1024)).toFixed(1)}MB`,
				currentOperation: `Uploading: ${progressData.progress}%`
		});
	};

	const handleManifestProgress = (fileName: string, manifestStatus: string) => {
		console.log('Handling manifest progress:', fileName, manifestStatus);
		if (manifestStatus === 'complete') {
			uploadManager.updateProgress(fileName, {
				stage: UploadStage.COMPLETED,
				progress: 100,
				message: 'Successfully uploaded',
				currentOperation: 'Complete'
			});
					} else {
			const progressMatch = manifestStatus.match(/(\d+)%/);
			if (progressMatch) {
				const progress = parseInt(progressMatch[1]);
				uploadManager.updateProgress(fileName, {
					stage: UploadStage.MANIFEST_PROCESSING,
					progress,
					message: `Processing: ${progress}%`,
					currentOperation: 'Processing manifest'
				});
			}
		}
	};

	useEffect(() => {
		if (steppedFileName) {
			try {
				// Upload progress
				if (step.startsWith('{')) {
					const progressData: UploadProgressResponse = JSON.parse(step);
					handleUploadProgress(steppedFileName, progressData);
				} 
				// Diğer durumlar
				else if (step === 'preparing') {
					updateFileStatus(steppedFileName, {
						stage: UploadStage.PREPARING,
						currentOperation: 'Preparing file for upload...'
					});
				} else if (step === 'processing') {
					updateFileStatus(steppedFileName, {
						stage: UploadStage.PROCESSING,
						currentOperation: 'Processing file on server...'
					});
				}
			} catch (error) {
				console.error('Error handling step update:', error);
			}
		}
	}, [step, steppedFileName]);

	useEffect(() => {
		if (steppedFileName && manifestStatus) {
			handleManifestProgress(steppedFileName, manifestStatus);
		}
	}, [manifestStatus, steppedFileName]);

	useEffect(() => {
		if (isProcessing && steppedFileName) {
			updateFileStatus(steppedFileName, {
				stage: UploadStage.PROCESSING,
				currentOperation: 'Processing file on server...'
			});
		}
	}, [isProcessing, steppedFileName]);

	useEffect(() => {
		if (isStarted && selectedFiles.length > 0) {
			selectedFiles.forEach(file => {
				updateFileStatus(file.file.name, {
					stage: UploadStage.QUEUED,
					currentOperation: 'Ready to upload'
				});
			});
		}
	}, [isStarted]);

	// Root path response handler
	const handleRootPathResponse = (response: any, fileName: string) => {
		updateUploadStatus(fileName, {
			rootPathResponse: response
		});

		updateFileStatus(fileName, {
			stage: UploadStage.UPLOADING,
			progress: response.progress,
			bytesUploaded: response.bytesRead,
			totalBytes: response.totalBytes,
			currentOperation: `Uploading: ${(response.bytesRead / (1024 * 1024)).toFixed(1)}MB / ${(response.totalBytes / (1024 * 1024)).toFixed(1)}MB`
		});
	};

	// Finalize response handler
	const handleFinalizeResponse = (response: any, fileName: string) => {
		updateUploadStatus(fileName, {
			finalizeResponse: response
		});

		if (response.isSuccess) {
			updateFileStatus(fileName, {
				stage: UploadStage.MANIFEST_PROCESSING,
				currentOperation: 'Starting manifest processing...'
			});
		}
	};

	// Manifest response handler
	const handleManifestResponse = (response: any, fileName: string) => {
		updateUploadStatus(fileName, {
			manifestResponse: response
		});

		if (response.progress === 'complete') {
			updateFileStatus(fileName, {
				stage: UploadStage.COMPLETED,
				progress: 100,
				currentOperation: 'Upload completed successfully'
			});
			setProcessedCount(prev => prev + 1);
		} else {
			const progressMatch = response.progress.match(/(\d+)%/);
			if (progressMatch) {
				const progress = parseInt(progressMatch[1]);
				updateFileStatus(fileName, {
					stage: UploadStage.MANIFEST_PROCESSING,
					progress,
					currentOperation: `Processing: ${progress}%`
				});
			}
		}
	};

	// useEffect güncellemeleri
	useEffect(() => {
		if (step && steppedFileName) {
			try {
				if (typeof step === 'string' && step.startsWith('{')) {
					const response = JSON.parse(step);
					if ('bytesRead' in response) {
						handleRootPathResponse(response, steppedFileName);
					}
				}
			} catch (error) {
				console.error('Error parsing step:', error);
			}
		}
	}, [step, steppedFileName]);

	useEffect(() => {
		if (manifestStatus && steppedFileName) {
			try {
				const response = typeof manifestStatus === 'string' ? JSON.parse(manifestStatus) : manifestStatus;
				handleManifestResponse(response, steppedFileName);
			} catch (error) {
				console.error('Error parsing manifest status:', error);
			}
		}
	}, [manifestStatus, steppedFileName]);

	// SelectedFilesList component'ini güncelleyelim
	const getFileStatus = (fileName: string) => {
		return uploadProgress.get(fileName) || {
			stage: UploadStage.QUEUED,
			message: 'Ready to upload'
		};
	};

	useEffect(() => {
		if (selectedFiles.length > 0) {
			selectedFiles.forEach(file => {
				const currentProgress = uploadProgress.get(file.file.name);
				if (!currentProgress || currentProgress.stage === UploadStage.QUEUED) {
					uploadManager.updateProgress(file.file.name, {
						stage: UploadStage.QUEUED,
						message: 'Ready to upload',
						currentOperation: 'Ready to upload'
					});
				}
			});
		}
	}, [selectedFiles, uploadManager]);

	const allFilesCompleted = () => {
		if (selectedFiles.length === 0) return false;
		return selectedFiles.every(file => {
			const progress = uploadProgress.get(file.file.name);
			return progress && progress.stage === UploadStage.COMPLETED;
		});
	};

	const getTotalZipCount = () => {
		return totalZipCount;
	};

	const getProcessedZipCount = () => {
		return currentZipIndex + 1;
	};

	return (
		<div className={modalStyles.overlay}>
			<div className={`${modalStyles.container} ${selectedFiles.length === 0 ? 'h-auto' : 'h-[90vh]'}`}>
				<div className={modalStyles.content}>
					{/* Header */}
					<div className={modalStyles.header}>
						<h2 className="text-xl font-semibold text-gray-900">Upload Files</h2>
						<button
							className="p-2 hover:bg-gray-100 rounded-full transition-colors"
							onClick={handleCloseAttempt}
							hidden={uploadedFilesSelector.loading}
						>
							<CloseIcon className="text-gray-500" />
				</button>
					</div>

					{/* Body */}
					<div className={modalStyles.body}>
						{extractZipStatus ? (
							<div className="flex flex-col items-center justify-center py-12">
								<div className="w-24 h-24 mb-6 relative">
									<div className="absolute inset-0 border-4 border-blue-100 rounded-full animate-pulse"></div>
									<div className="absolute inset-0 border-4 border-blue-500 rounded-full animate-spin" 
										 style={{ borderRightColor: 'transparent', animationDuration: '1s' }}></div>
								</div>
								<h3 className="text-xl font-semibold text-gray-900 mb-2">
									Extracting ZIP File
								</h3>
								<p className="text-gray-500 text-center max-w-sm">
									Please wait while we extract and prepare the contents of your ZIP file...
								</p>
							</div>
						) : (
							<div className="flex flex-col space-y-4">
								{zipFile && openZipOption && (
									<ZipSelectionDialog
										zipFile={zipFile}
										onSelect={handleselectedFile}
										selectedFile={selectedFile}
										searchTerm={searchTerm}
										setSearchTerm={setSearchTerm}
										sortBy={sortBy}
										setSortBy={setSortBy}
										sortOrder={sortOrder}
										setSortOrder={setSortOrder}
										onContinue={handleContinueRootFileSelection}
										setOpenZipOption={setOpenZipOption}
										currentZipName={zipFile?.mainZipFile.name || ''}
										totalZips={totalZipCount}
										currentZipIndex={getProcessedZipCount() - 1}
									/>
								)}

										{
											(
												uploadedFilesSelector && uploadedFilesSelector.error !== null) ||
												(checkCreditWhenUploadSelector && checkCreditWhenUploadSelector.error !== null) ||
												(startUploadModelsSelector && startUploadModelsSelector.error !== null) ||
												invalidFileMessage !== "" ? (
										<ErrorDisplay error={error} onCancel={handleCancel} />
									) : (
										<div className="flex flex-col">
											<DragDropArea 
												handleFileInput={handleFileInput}
												handleFileInputWithDrop={handleFileInputWithDrop}
												isDisabled={uploadedFilesSelector.loading}
											/>

											{selectedFiles.length > 0 && (
												<SelectedFilesList 
													files={selectedFiles} 
													onRemove={handleRemoveFile}
													uploadProgress={uploadProgress}
													isProcessing={isProcessing}
													isStarted={isStarted}
												/>
											)}

											<div className="mt-6">
												<div className="flex items-center justify-between">
														<button
																	className="text-sm text-blue-600 hover:text-blue-700 flex items-center"
																	onClick={() => setShowSupportedFormats(true)}
													>
																	<InfoOutlinedIcon className="w-4 h-4 mr-1" />
																	See supported formats
														</button>

													<div>
																	{selectedFiles.length > 0 && (
															<button
																			className={`px-4 py-2 text-white text-sm rounded-lg ${
																				allFilesCompleted() ? 'bg-green-600 hover:bg-green-700' : 'bg-blue-600 hover:bg-blue-700'
																			}`}
																			onClick={() => {
																	if (allFilesCompleted()) {
																		handleStartNewUpload();
																	} else {
																	setSelectedFile({
																		fileName: "",
																		file: "",
																		fileSize: "",
																		rootFileName: "",
																	});
																	setLastAddedMainFile({
																		fileName: "",
																		file: "",
																		fileSize: "",
																		rootFileName: "",
																			});
																		if (waitingForManifest) {
																		setWaitingForManifest(false);
																	}
																	setIsStarted(true); 
																	}
																}}
																			disabled={isProcessing || (isStarted && !allFilesCompleted())}
															>
																			{allFilesCompleted() ? 'Start New Upload' : 'Upload Files'}
															</button>
														)}
													</div>
												</div>
											</div>
										</div>
									)
								}
							</div>
						)}
					</div>

																<Dialog
																	open={showSupportedFormats}
																	onClose={() => setShowSupportedFormats(false)}
						maxWidth="md"
																>
																	<DialogContent>
																		<FileFormats />
																	</DialogContent>
																	<DialogActions>
							<Button onClick={() => setShowSupportedFormats(false)}>
								Close
							</Button>
																	</DialogActions>
																</Dialog>
													</div>
			</div>
		</div>
	);
};

export default MultipleUploadModal;
