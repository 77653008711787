import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { BaseState } from '../../../commons/base.state';
import { ResetPasswordRequest, ResetPasswordResponse } from '../../../models/resetPassword/reset.password.model';
import { ResetPasswordPostService } from '../../../services/resetPassword/reset.password.service';


interface ResetPasswordState extends BaseState<ResetPasswordResponse> { }

const initialState: ResetPasswordState = {
  data: null,
  loading: false,
  error: null,
};

export const fetchResetPassword = createAsyncThunk(
  'auth/fetchResetPassword',
  async (request: ResetPasswordRequest, thunkAPI) => {
    try {
      const service = new ResetPasswordPostService();
      const response = await service.PostAsync(request);
      return response;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }

      const errorData = err.response.data;
      return thunkAPI.rejectWithValue({
        error: errorData.error ? errorData.error : errorData.message,
      });
    }
  }
);

const resetPasswordSlice = createSlice({
  name: 'resetPasswordSlice',
  initialState,
  reducers: {
    resetResetPasswordState:(state)=>{
      state.error = null;
      state.data=null;
      state.loading=false
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchResetPassword.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchResetPassword.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchResetPassword.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const {resetResetPasswordState}=resetPasswordSlice.actions
export default resetPasswordSlice.reducer;
