import React, { useEffect, useState } from 'react';
import { RootState, useAppDispatch, useAppSelector } from '../../store';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import CardItem from '../../components/CardItem';
import IAM from '../../assets/IAM.png';
import IPT from '../../assets/IPT.png';
import ZIP from '../../assets/zip.svg';
import DWFX from '../../assets/DWFX.png'
import DWG from '../../assets/DWG.jpg';
import FBX from '../../assets/FBX.png';
import MODEL from '../../assets/MODEL.png';
import MAX from '../../assets/MAX.png';
import STL from '../../assets/STL.png'
import STP from '../../assets/STP.png'
import SLDASM from '../../assets/SLDASM.png'
import RVT from '../../assets/RVT.jpg'
import DWF from '../../assets/DWF.png'
import F3D from '../../assets/f3d.png'
import GLB from '../../assets/GLB.png'
import OBJ from '../../assets/obj.png'
import PSM from '../../assets/psm.png'
import IDW from "../../assets/IDW.png"
import STEP from "../../assets/STEP.png"
import CloudIcon from '@mui/icons-material/Cloud'
import UNDEFINEDIMAGE from "../../assets/undefined.png"
import '../../styles/onedrive/upload.css';
import { fetchAllFiles } from '../../store/features/oneDrive/get.all.files.slice';
import CircleLoading from '../../components/Loading/CircleLoading';
import MultipleOneDriveUploadModal from '../../components/MultipleUploadModal/MultipleOneDriveUploadModal';
import { LoginResponse } from '../../models/auth/login.model';
import OneDriveUnauthorized from './Unauthorized';
import { SaveCodeResponseModel } from '../../models/oneDrive/auth/save.code.response.model';
import { SelectableZipFiles, StatusValues } from '../object/ObjectList';
import { GetIAMFilesModelResponse } from '../../models/oneDrive/file/get.iam.files.model';
import { fetchIAMFiles } from '../../store/features/oneDrive/get.iam.files.service';
import { ProcessUploadChunkService } from '../../services/oneDrive/v2/process.upload.chunk.service';
import { ProcessingUploadModel, ProcessUploadIterationResponseModel } from '../../models/object/uploadedObject/uploadV2/processing.upload.model';
import { SaveFilePathRootService } from '../../services/oneDrive/v2/save.file.path.service';
import { FinalizeUploadModelsService } from '../../services/oneDrive/v2/finalize.upload.model.service';
import { CheckManifestToOneDriveFile } from '../../services/oneDrive/file/check.manifest.oneDrive.file.service';
import { GetFormFileService } from '../../services/oneDrive/v2/get.form.file.service';
import { UploadedFile } from '../../models/object/uploadedObject/put.uploadedObject.request.model';

export interface OneDriveFileData {
  name: string;
  id: string;
  size: number;
  rootFileName: string
}
const CombinedComponent: React.FC = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showTranslationModal, setShowTranslationModal] = useState<boolean>(false);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [secondSelectedFiles, setSecondSelectedFiles] = useState<OneDriveFileData[]>([]);
  const [fileLimit, setFileLimit] = useState(false);
  const [progress, setProgress] = useState(0);
  const [dragNdrop, setDragNdrop] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [itemToDeleteFile, setItemToDeleteFile] = useState({ id: '', name: '' });
  const [zipFile, setZipFile] = useState<SelectableZipFiles | undefined>();
  const [zipOption, setOpenZipOption] = useState(false)
  const dispatch = useAppDispatch();
  const getUserBucket = useAppSelector((s: RootState) => s.getUserBucket);
  const getFiles = useAppSelector((s: RootState) => s.getAllFiles);
  const folderListSelector = useAppSelector((s: RootState) => s.getFolderList);
  const folderList = folderListSelector.data;
  const loginInfo = sessionStorage.getItem('login') as string;
  const jsonLoginInfo = JSON.parse(loginInfo) as LoginResponse;
  const bucket = sessionStorage.getItem('b') as string;
  const microsoftAuth = sessionStorage.getItem('microsoft-auth') as string;
  const microsoftAuthInfo = JSON.parse(microsoftAuth) as SaveCodeResponseModel
  const MAX_COUNT = 40;
  const UploadFilesSwal = withReactContent(Swal);
  const deleteFileSelector = useAppSelector((s: RootState) => s.deleteFileSlice);
  const deleteFolderSelector = useAppSelector((s: RootState) => s.deleteFolderSlice);
  const [iamFiles, setIamFiles] = useState<GetIAMFilesModelResponse | undefined>(undefined)
  const iamFilesSelector = useAppSelector((s: RootState) => s.getIAMFilesSlice)
  const [isProcessing, setIsProcessing] = useState(false)
  const [step, setStep] = useState("");
  const [startToGetChunkStatus, setStartToGetChunkStatus] = useState(false)
  const [chunks, setChunks] = useState<ProcessingUploadModel[]>([])
  const [processUpload, setProcessToUpload] = useState(false)
  const [steppedFileName, setSteppedFileName] = useState("")
  const [manifestStatus, setManifestStatus] = useState("")
  const [waitingToManifest, setWaitingForManifest] = useState(false)
  const [afterToUploadError, setAfterUploadError] = useState("")
  const [isFinishedToLoading, setIsFinishedToLoading] = useState(false)
  const [isContinueProgress, setIsContinueProgress] = useState(false)
  const [rootFile, setRootFile] = useState("")
  const [selectedFile, setSelectedFile] = useState({
    name: "",
    size: ""
  });
  const [isStarted, setIsStarted] = useState(false);
  const [processedFiles, setProcessedFiles] = useState<OneDriveFileData[]>([]);
  const [isProcessingFile, setIsProcessingFile] = useState(false);
  const [statusValues, setStatusValues] = useState<StatusValues[]>([]);
  const actualSupportedFormats = [
    "3DM", "3DS", "A", "ASM", "AXM", "BRD", "CATPART", "CATPRODUCT", "CGR", "COLLABORATION", "DAE", "DDX", "DDZ", "DGK",
    "DGN", "DLV3", "DMT", "DWF", "DWFX", "DWG", "DWT", "DXF", "EMODEL", "EXP", "F3D", "FBRD", "FBX", "FSCH", "G", "GBXML",
    "GLB", "GLTF", "ZIP", "IDW", "IFC", "IGE", "IGES", "IGS", "IPT", "IWM", "JT", "MAX", "MODEL", "MPF", "MSR", "NEU",
    "NEW", "NWC", "NWD", "OBJ", "OSB", "PAR", "PMLPRJ", "PMLPRJZ", "PRT", "PSM", "PSMODEL", "RVM", "RVT", "SAB", "SAT",
    "SCH", "SESSION", "SKP", "SLDPRT", "SMB", "SMT", "STE", "STEP", "STL", "STLA", "STLB", "STP", "STPZ", "VPB",
    "VUE", "WIRE", "X_B", "X_T", "XAS", "XPR"
  ];

  useEffect(() => {
    dispatch(fetchAllFiles(bucket));
  }, [dispatch, bucket]);



  const handleUploadFiles = (chosenFiles: File[]) => {
    const uploaded = [...selectedFiles];
    let limitedExceeded = false;

    chosenFiles.forEach((file) => {
      if (uploaded.findIndex((f) => f.name === file.name) === -1) {
        if (file.name.endsWith('.zip')) {
          setOpenZipOption(true)
        }
        uploaded.push(file);
      }

      if (uploaded.length === MAX_COUNT) setFileLimit(true);

      if (uploaded.length > MAX_COUNT) {
        UploadFilesSwal.fire({
          title: <h3>Maximum upload file limit warning</h3>,
          didOpen: () => {
            UploadFilesSwal.showLoading();
          },
        }).then(() => {
          UploadFilesSwal.fire(<p>{`You can only add a maximum of ${MAX_COUNT} files`}</p>);
        });
        setFileLimit(false);
        limitedExceeded = true;
        return true;
      }
    });

    if (!limitedExceeded) {
      setSelectedFiles(uploaded);
      setShowModal(true);
    }
  };

  const handleCloseModal = () => {
    setShowTranslationModal(false)
    setShowModal(false);
    setSelectedFiles([]);
  };

  const renderFileIcon = (fileExtension: string) => {
    switch (fileExtension.toLowerCase()) { // Added .toLowerCase() for case-insensitivity
      case 'zip':
        return ZIP;
      case 'iam':
        return IAM;
      case 'ipt':
        return IPT;
      case 'rvt':
        return RVT;
      case 'dwg':
        return DWG;
      case 'fbx':
        return FBX;
      case 'dwfx':
        return DWFX;
      case 'idw':
        return IDW;
      case 'stl':
        return STL;
      case 'stp':
        return STP;
      case 'sldasm':
        return SLDASM;
      case 'max':
        return MAX;
      case 'model':
        return MODEL;
      case 'dwf':
        return DWF;
      case 'f3d':
        return F3D;
      case 'glb':
        return GLB;
      case 'obj':
        return OBJ;
      case 'psm':
        return PSM;
      case 'step':
        return STEP;
      default:
        return UNDEFINEDIMAGE;
    }
  };


  const handleTranslateFile = (file: OneDriveFileData) => {
    setSecondSelectedFiles([file]);
    setShowTranslationModal(true);
  };

  const getFile = async (id: string, bucketName: string, rootFileName: string) => {
    const service = new GetFormFileService()
    const response = await service.GetFileWithPostRequestAsync({
      id,
      bucketName,
      rootFileName
    })
    return response
  }

  function generateGUID(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = (Math.random() * 16) | 0,
        v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  const checkFileExtension = async (file: OneDriveFileData) => {
    const fileExtension = file.name.split('.').pop()?.toUpperCase();
    return fileExtension ? actualSupportedFormats.includes(fileExtension) : false;
  }

  const getChunksAsync = async (file: OneDriveFileData): Promise<ProcessingUploadModel | null> => {

    if (file !== undefined) {
      setStep("Preparing to upload...")
      const fileFormResponse = await getFile(file.id, bucket, rootFile)
      console.log(fileFormResponse)
      if (fileFormResponse !== undefined || fileFormResponse !== null) {
        setStartToGetChunkStatus(true)
        const guid = generateGUID()
        const service = new SaveFilePathRootService()

        const firstChunkSize = 81920;
        let progress = 0;
        let bytesRead = 0;
        const totalBytes = fileFormResponse.size
        let firstFileName = ""
        let firstPath = ""
        let completed = false

        while (completed === false) {
          const startByte = bytesRead;
          const endByte = Math.min(startByte + firstChunkSize, totalBytes);
          if (startByte >= totalBytes) {
            console.error("Start byte exceeds total bytes. Breaking the loop.");
            break;
          }
          const chunkBlob = fileFormResponse.slice(startByte, endByte);
          if (chunkBlob.size === 0) {
            console.error("Chunk blob size is zero. Breaking the loop.");
            break;
          }
          const chunkFile = new File([chunkBlob], fileFormResponse.name, { type: fileFormResponse.type });
          const response = await service.GetContentRoot({
            rootFileName: rootFile || "",
            file: chunkFile,
            bucketName: bucket,
            totalBytes: totalBytes,
            bytesRead: bytesRead,
            guid: guid
          });
          console.log(response)
          bytesRead = response.bytesRead;
          progress = response.progress;
          console.log(progress)
          setStep(`${progress}% Preparing to upload...`)
          setProgress(progress)

          completed = response.completed;
          firstFileName = response.fileName
          firstPath = response.path

        }

        const fileName = fileFormResponse.name.replace(/[^a-zA-Z0-9]/g, '');
        const cleanFileName = fileName.substring(0, 50);
        const sessionId = `SI${cleanFileName}${Math.floor(Math.random() * 100000)}`;
        const chunkSize = 5 * 1024 * 1024; // 5 MB
        const length = fileFormResponse.size;
        const chunkCount = Math.round(0.5 + (length / chunkSize));

        console.log(file)

        const val: ProcessingUploadModel = {
          dataLength: length,
          chunkCount: chunkCount,
          dataWrited: 0,
          startByte: 0,
          chunkIndex: 0,
          sessionId: sessionId,
          saveFileName: firstFileName,
          saveFilePath: firstPath,// Backend'de belirlenecek
          rootFileName: rootFile !== null || rootFile !== "" ? rootFile : "",
          bucketKey: bucket
        }
        chunks.push(val)
        return val
      }

      else {
        return null;
      }
    } else {
      return null
    }

  };


  const processToChunksAsync = async (chunk: ProcessingUploadModel) => {
    setProcessToUpload(true)
    try {
      let iterationParameter: ProcessUploadIterationResponseModel = {
        startByte: chunk.startByte,
        response: undefined,
        progress: 0,
        dataWrited: 0
      }

      for (let i = 0; i < chunk.chunkCount; i++) {
        const service = new ProcessUploadChunkService()
        setSteppedFileName(chunk.saveFileName as string)
        if (i === chunk.chunkIndex) {
          console.log(chunk)
          iterationParameter = await service.PostAsync(chunk)
          console.log(iterationParameter)
        }
        else {
          const newChunk: ProcessingUploadModel = {
            startByte: iterationParameter.startByte,
            saveFileName: chunk.saveFileName,
            chunkIndex: chunk.chunkIndex,
            chunkCount: chunk.chunkCount,
            dataWrited: iterationParameter.dataWrited,
            sessionId: chunk.sessionId,
            saveFilePath: chunk.saveFilePath,
            bucketKey: chunk.bucketKey,
            dataLength: chunk.dataLength,
            rootFileName: chunk.rootFileName
          }
          console.log(newChunk)
          iterationParameter = await service.PostAsync(newChunk)
          console.log(iterationParameter)
        }
        setProgress(iterationParameter.progress)
        setStep(`${iterationParameter.progress}% Uploading...`)
        if (iterationParameter.progress === 100) {
          break;
        }
      }

      if (iterationParameter.progress === 100) {
        return iterationParameter
      }
    } catch (error: any) {
      console.error("Error:", error);
      setAfterUploadError(error.message !== undefined ? error.message : error.statusText)
      // break;
    }
  }

  const finalizeUploadAsync = async (list: ProcessUploadIterationResponseModel, mainFileName: string) => {
    setProcessToUpload(false)
    const finalizeService = new FinalizeUploadModelsService()
    const finalizeResponse = await finalizeService.PostAsync({
      list: [list],
      mainFileName: mainFileName
    })
    return finalizeResponse
  }
  // Manifest kontrolünü yapan fonksiyon
  const checkManifestStatus = async (
    urns: string[],
    files: string[],
    uid: string,
    mainFileName: string
  ) => {

    setStep("Translate starting...")
    setManifestStatus("Translate starting...")
    const manifestService = new CheckManifestToOneDriveFile();
    let isRequestInProgress = false; // İstek durumu flag'i
    let requestTimeoutId: NodeJS.Timeout | null = null; // İstek zaman aşımı için timeout ID'si
    const REQUEST_TIMEOUT = 60000; // 60 saniye
    const INTERVAL_DELAY = 10000; // 10 saniye

    const intervalId = setInterval(async () => {
      if (!urns || urns.length === 0 || !uid) {
        console.log("Gerekli veriler mevcut değil.");
        clearInterval(intervalId); // Gerekli veriler yoksa interval'i durdur
        return;
      }
      if (isRequestInProgress) {
        console.log("İstek zaten devam ediyor, bekleniyor...");
        return;
      }
      isRequestInProgress = true;
      requestTimeoutId = setTimeout(() => {
        console.log("İstek 1 dakikadan uzun sürdü, yeni istek yapılabilir.");
        isRequestInProgress = false;
      }, REQUEST_TIMEOUT);

      try {
        const response = await manifestService.GetByStringManyParamsAsync([urns[0], files[0], uid]);
        if (response.progress === "complete") {
          setProcessToUpload(false)
          setStep("")
          setManifestStatus("Finishing...");
          //dispatch(resetCreateState())
          //setSelectedFiles([])
          setIsFinishedToLoading(true)
          clearInterval(intervalId); // Interval'i durdur
          if (requestTimeoutId) {
            clearTimeout(requestTimeoutId);
          }
        } else {
          setStep(response.progress);
          setManifestStatus(response.progress);
        }
        setIsProcessing(false)
        setWaitingForManifest(true)
      } catch (error: any) {
        console.error("Error:", error);
        setAfterUploadError(error.message !== undefined ? error.message : error.statusText)
      } finally {
        isRequestInProgress = false;

        if (requestTimeoutId) {
          clearTimeout(requestTimeoutId);
          requestTimeoutId = null;
        }
      }
    }, INTERVAL_DELAY);



  };


  const processQueue = async (index: number) => {
    if (secondSelectedFiles.length > 0) {
      setWaitingForManifest(false);
      setIsProcessing(true);
      while (index < secondSelectedFiles.length) {
        let currentFile = secondSelectedFiles[index];
        console.log(currentFile)
        if (currentFile !== undefined) {

          const chunks = await getChunksAsync(currentFile);
          console.log(chunks);
          if (chunks !== null && chunks !== undefined) {
            var processChunkResponse = await processToChunksAsync(chunks as ProcessingUploadModel);
            console.log(processChunkResponse);
            const { urns, files, uid } = await finalizeUploadAsync(processChunkResponse as ProcessUploadIterationResponseModel,
              currentFile.name
            );
            await checkManifestStatus(urns, files, uid, currentFile.name);
          }
        }
        index++;
      }

      setIsProcessing(false);
    }
  };


  return (
    <>
      {
        microsoftAuthInfo && microsoftAuthInfo.isSuccess ? (
          <>
            {getUserBucket.loading || getFiles.loading || folderListSelector.loading ? (

              <div className='loading-bucket'>
                <CircleLoading text='Trying to open models...' />
              </div>
            ) : (
              <>
                
                <div className="inline-flex items-center space-x-2 px-2 py-2 mx-4 bg-white text-blue-600 border-b-2 border-blue-600">
                  <CloudIcon className="w-5 h-5" />
                  <span className="text-lg font-semibold tracking-wide">OneDrive</span>
                </div>
              

                <div className='flex flex-wrap justify-start gap-4 p-4 '>

                  {getFiles.data &&
                    getFiles.data.files.map((file, i) => {
                      const fileExtension = file.name.split('.').pop();
                      const fileIcon = renderFileIcon(fileExtension as string);
                      return (
                        <CardItem
                          isCloud={true}
                          isActive={true}
                          key={file.id}
                          title={file.name}
                          imageName={fileIcon as string}
                          buttonNames={[]}
                          buttonEvents={[]}
                          onClickToCard={async () => {
                            if (file.name.endsWith(".zip")) {
                              dispatch(fetchIAMFiles({
                                itemId: file.id,
                                bucketName: bucket
                              }))
                            }
                            let oneDriveData: OneDriveFileData = {
                              id: file.id,
                              name: file.name,
                              size: file.size as number,
                              rootFileName: ""
                            }
                            handleTranslateFile(oneDriveData);

                          }}
                          onDelete={() => {
                            setShowDeleteModal(true);
                            setItemToDeleteFile({
                              id: file.id,
                              name: file.name,
                            });
                          }}
                        />
                      );
                    })}
                </div>
                {showTranslationModal && (
                  <MultipleOneDriveUploadModal
                    fileList={secondSelectedFiles}
                    setFileList={setSecondSelectedFiles}
                    show={showTranslationModal}
                    onHide={() => setShowTranslationModal(false)}
                    bucket={bucket}
                    zipOption={zipOption}
                    setOpenZipOption={setOpenZipOption}
                    zipFile={zipFile}
                    setZipFile={setZipFile}
                    iamFiles={iamFilesSelector.data ? iamFilesSelector.data : undefined}
                    processQueue={processQueue}
                    step={step}
                    processToUpload={processUpload}
                    manifestStatus={manifestStatus}
                    waitingToManifest={waitingToManifest}
                    isContinueToProgress={isContinueProgress}
                    setIsContinueToProgress={setIsContinueProgress}
                    setRootFile={setRootFile}
                    rootFile={rootFile}
                    selectedFile={selectedFile}
                    setSelectedFile={setSelectedFile}
                  />
                )}
              </>
            )}
          </>
        ) : (<><OneDriveUnauthorized /></>)
      }
    </>
  );
};

export default CombinedComponent;



