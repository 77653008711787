import React, { useState, useEffect } from 'react';
import { RootState, useAppDispatch, useAppSelector } from '../../store';
import SecondHeader from '../../components/Navbar/SecondHeader';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import CardItem from '../../components/CardItem';
import IAM from '../../assets/IAM.png';
import IPT from '../../assets/IPT.png';
import ZIP from '../../assets/zip.svg';
import DWFX from '../../assets/DWFX.png'
import DWG from '../../assets/DWG.jpg';
import FBX from '../../assets/FBX.png';
import MODEL from '../../assets/MODEL.png';
import MAX from '../../assets/MAX.png';
import STL from '../../assets/STL.png'
import STP from '../../assets/STP.png'
import SLDASM from '../../assets/SLDASM.png'
import RVT from '../../assets/RVT.jpg'
import DWF from '../../assets/DWF.png'
import F3D from '../../assets/f3d.png'
import GLB from '../../assets/GLB.png'
import OBJ from '../../assets/obj.png'
import PSM from '../../assets/psm.png'
import STEP from "../../assets/STEP.png"
import CloudIcon from '@mui/icons-material/Cloud'
import IDW from "../../assets/IDW.png"
import UNDEFINEDIMAGE from "../../assets/undefined.png"
import '../../styles/onedrive/upload.css';
import CircleLoading from '../../components/Loading/CircleLoading';
import { Container, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlushed } from '@fortawesome/free-solid-svg-icons';
import MultipleBoxUploadModal from '../../components/MultipleUploadModal/MultipleBoxUploadModal';
import { fetchAllBoxFiles } from '../../store/features/box/get.all.box.files.slice';
import { SelectableZipFiles } from '../object/ObjectList';
import { fetchBoxSubFiles } from '../../store/features/box/get.sub.files.from.zip.slice';
import { ProcessingUploadModel, ProcessUploadIterationResponseModel } from '../../models/object/uploadedObject/uploadV2/processing.upload.model';
import { SaveBoxFilePathRootService } from '../../services/box/v2/save.box.file.path.service';
import { OneDriveFileData } from '../oneDrive/Upload';
import { GetBoxFormFileService } from '../../services/box/v2/get.box.file.form.service';
import { ProcessUploadChunkService } from '../../services/box/v2/process.box.upload.chunk.service';
import { FinalizeBoxUploadModelsService } from '../../services/box/v2/finalize.box.upload.model.service';
import { CheckManifestBoxService } from '../../services/box/check.manifest.box.service';


export interface BoxData {
  name: string;
  id: string;
  size: number;
}

const BoxUpload: React.FC = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showTranslationModal, setShowTranslationModal] = useState<boolean>(false);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [secondSelectedFiles, setSecondSelectedFiles] = useState<BoxData[]>([]);
  const [fileLimit, setFileLimit] = useState(false);
  const [progress, setProgress] = useState(0);
  const [dragNdrop, setDragNdrop] = useState(false);
  const [zipFile, setZipFile] = useState<SelectableZipFiles | undefined>();
  const [zipOption, setOpenZipOption] = useState(false)
  const dispatch = useAppDispatch();
  const getUserBucket = useAppSelector((s: RootState) => s.getUserBucket);
  const getFiles = useAppSelector((s: RootState) => s.getAllBoxFilesSlice);
  const bucket = sessionStorage.getItem('b') as string
  const MAX_COUNT = 40;
  const UploadFilesSwal = withReactContent(Swal);
  const iamFilesSelector = useAppSelector((s: RootState) => s.getBoxSubFilesSlice)
  const [step, setStep] = useState("")
  const [startToGetChunkStatus, setStartToGetChunkStatus] = useState(false)
  const [chunks, setChunks] = useState<ProcessingUploadModel[]>([])
  const [processUpload, setProcessToUpload] = useState(false)
  const [steppedFileName, setSteppedFileName] = useState("")
  const [manifestStatus, setManifestStatus] = useState("")
  const [waitingToManifest, setWaitingForManifest] = useState(false)
  const [afterToUploadError, setAfterUploadError] = useState("")
  const [isFinishedToLoading, setIsFinishedToLoading] = useState(false)
  const [isContinueProgress, setIsContinueProgress] = useState(false)
  const [isProcessing, setIsProcessing] = useState(false)
  const [rootFile, setRootFile] = useState("")
  const [selectedFile, setSelectedFile] = useState({
    name: "",
    size: ""
  });
  useEffect(() => {
    dispatch(fetchAllBoxFiles(bucket));
  }, [dispatch, bucket]);

  const renderFileIcon = (fileExtension: string) => {
    switch (fileExtension.toLowerCase()) { // Added .toLowerCase() for case-insensitivity
      case 'zip':
        return ZIP;
      case 'iam':
        return IAM;
      case 'ipt':
        return IPT;
      case 'rvt':
        return RVT;
      case 'dwg':
        return DWG;
      case 'fbx':
        return FBX;
      case 'dwfx':
        return DWFX;
      case 'idw':
        return IDW;
      case 'stl':
        return STL;
      case 'stp':
        return STP;
      case 'sldasm':
        return SLDASM;
      case 'max':
        return MAX;
      case 'model':
        return MODEL;
      case 'dwf':
        return DWF;
      case 'f3d':
        return F3D;
      case 'glb':
        return GLB;
      case 'obj':
        return OBJ;
      case 'psm':
        return PSM;
      case 'step':
        return STEP;
      default:
        return UNDEFINEDIMAGE;
    }
  };

  const handleUploadFiles = (chosenFiles: File[]) => {
    const uploaded = [...selectedFiles];
    let limitedExceeded = false;

    chosenFiles.map((file) => {
      if (uploaded.findIndex((f) => f.name === file.name) === -1) {
        if (uploaded.findIndex((f) => f.name === file.name) === -1) {
          if (file.name.endsWith('.zip')) {
            setOpenZipOption(true)
          }
          uploaded.push(file);
        }
      }

      if (uploaded.length === MAX_COUNT) setFileLimit(true);

      if (uploaded.length > MAX_COUNT) {
        UploadFilesSwal.fire({
          title: <h3>Maximum upload file limit warning</h3>,
          didOpen: () => {
            UploadFilesSwal.showLoading();
          },
        }).then(() => {
          UploadFilesSwal.fire(
            <p>{`You can only add a maximum of ${MAX_COUNT} files`}</p>
          );
        });
        setFileLimit(false);
        limitedExceeded = true;
        return true;
      }
    });

    if (!limitedExceeded) {
      console.log(uploaded);
      setSelectedFiles(uploaded);
      setShowModal(true);
    }
  };

  const handleFileEvent = (e: React.ChangeEvent<HTMLInputElement>) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    handleUploadFiles(chosenFiles);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedFiles([]);
  };

  const handleFileDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const items = event.dataTransfer.items;
    let containsDirectory = false;

    for (let i = 0; i < items.length; i++) {
      const item = items[i].webkitGetAsEntry();
      if (item && item.isDirectory) {
        containsDirectory = true;
        break;
      }
    }

    if (containsDirectory) {
      alert('You cannot drag folders. Please drag only files.');
    } else {
      const files = Array.prototype.slice.call(event.dataTransfer.files);
      handleUploadFiles(files);
    }
  };

  function generateGUID(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = (Math.random() * 16) | 0,
        v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  const getFile = async (fileName: string, bucketName: string, folderName: string) => {
    const service = new GetBoxFormFileService()
    const response = await service.GetFileWithPostRequestAsync({
      fileName,
      bucketName,
      folderName
    })
    return response
  }

  const getChunksAsync = async (file: OneDriveFileData): Promise<ProcessingUploadModel | null> => {

    if (file !== undefined) {
      setStep("Preparing to upload...")
      console.log(file)
      const fileFormResponse = await getFile(file.name, bucket, ``)
      console.log(fileFormResponse)
      if (fileFormResponse !== undefined || fileFormResponse !== null) {
        const guid = generateGUID()
        setStartToGetChunkStatus(true)
        const service = new SaveBoxFilePathRootService()

        const firstChunkSize = 81920;
        let progress = 0;
        let bytesRead = 0;
        const totalBytes = fileFormResponse.size
        let firstFileName = ""
        let firstPath = ""
        let completed = false

        while (completed === false) {
          const startByte = bytesRead;
          const endByte = Math.min(startByte + firstChunkSize, totalBytes);
          if (startByte >= totalBytes) {
            console.error("Start byte exceeds total bytes. Breaking the loop.");
            break;
          }
          const chunkBlob = fileFormResponse.slice(startByte, endByte);
          if (chunkBlob.size === 0) {
            console.error("Chunk blob size is zero. Breaking the loop.");
            break;
          }
          const chunkFile = new File([chunkBlob], fileFormResponse.name, { type: fileFormResponse.type });
          const response = await service.GetContentRoot({
            rootFileName: file.rootFileName || "",
            file: chunkFile,
            bucketName: bucket,
            totalBytes: totalBytes,
            bytesRead: bytesRead,
            guid: guid
          });
          console.log(response)
          bytesRead = response.bytesRead;
          progress = response.progress;
          console.log(progress)
          setStep(`${progress}% Preparing to upload...`)
          setProgress(progress)

          completed = response.completed;
          firstFileName = response.fileName
          firstPath = response.path

        }




        const fileName = fileFormResponse.name.replace(/[^a-zA-Z0-9]/g, '');
        const cleanFileName = fileName.substring(0, 50);
        const sessionId = `SI${cleanFileName}${Math.floor(Math.random() * 100000)}`;

        const chunkSize = 5 * 1024 * 1024; // 5 MB
        const length = fileFormResponse.size;
        const chunkCount = Math.round(0.5 + (length / chunkSize));

        console.log(file)

        const val: ProcessingUploadModel = {
          dataLength: length,
          chunkCount: chunkCount,
          dataWrited: 0,
          startByte: 0,
          chunkIndex: 0,
          sessionId: sessionId,
          saveFileName: firstFileName,
          saveFilePath: firstPath,// Backend'de belirlenecek
          rootFileName: file.rootFileName !== null || file.rootFileName !== "" ? file.rootFileName : "",
          bucketKey: bucket
        }
        chunks.push(val)
        return val
      }

      else {
        return null;
      }
    } else {
      return null
    }

  };


  const processToChunksAsync = async (chunk: ProcessingUploadModel) => {
    setProcessToUpload(true)
    try {
      let iterationParameter: ProcessUploadIterationResponseModel = {
        startByte: chunk.startByte,
        response: undefined,
        progress: 0,
        dataWrited: 0
      }

      for (let i = 0; i < chunk.chunkCount; i++) {
        const service = new ProcessUploadChunkService()
        setSteppedFileName(chunk.saveFileName as string)
        if (i === chunk.chunkIndex) {
          console.log(chunk)
          iterationParameter = await service.PostAsync(chunk)
          console.log(iterationParameter)
        }
        else {
          const newChunk: ProcessingUploadModel = {
            startByte: iterationParameter.startByte,
            saveFileName: chunk.saveFileName,
            chunkIndex: chunk.chunkIndex,
            chunkCount: chunk.chunkCount,
            dataWrited: iterationParameter.dataWrited,
            sessionId: chunk.sessionId,
            saveFilePath: chunk.saveFilePath,
            bucketKey: chunk.bucketKey,
            dataLength: chunk.dataLength,
            rootFileName: chunk.rootFileName
          }
          console.log(newChunk)
          iterationParameter = await service.PostAsync(newChunk)
          console.log(iterationParameter)
        }
        setProgress(iterationParameter.progress)
        setStep(`${iterationParameter.progress}% Uploading...`)
        if (iterationParameter.progress === 100) {
          break;
        }
      }

      if (iterationParameter.progress === 100) {
        return iterationParameter
      }
    } catch (error: any) {
      console.error("Error:", error);
      setAfterUploadError(error.message !== undefined ? error.message : error.statusText)
      // break;
    }
  }

  const finalizeUploadAsync = async (list: ProcessUploadIterationResponseModel, mainFileName: string) => {
    setProcessToUpload(false)
    const finalizeService = new FinalizeBoxUploadModelsService()
    const finalizeResponse = await finalizeService.PostAsync({
      list: [list],
      mainFileName: mainFileName
    })
    return finalizeResponse
  }



  // Manifest kontrolünü yapan fonksiyon
  const checkManifestStatus = async (
    urns: string[],
    files: string[],
    uid: string,
    mainFileName: string
  ) => {

    setStep("Translate starting...")
    setManifestStatus("Translate starting...")
    const manifestService = new CheckManifestBoxService()
    let isRequestInProgress = false; // İstek durumu flag'i
    let requestTimeoutId: NodeJS.Timeout | null = null; // İstek zaman aşımı için timeout ID'si
    const REQUEST_TIMEOUT = 60000; // 60 saniye
    const INTERVAL_DELAY = 10000; // 10 saniye

    const intervalId = setInterval(async () => {
      if (!urns || urns.length === 0 || !uid) {
        console.log("Gerekli veriler mevcut değil.");
        clearInterval(intervalId); // Gerekli veriler yoksa interval'i durdur
        return;
      }
      if (isRequestInProgress) {
        console.log("İstek zaten devam ediyor, bekleniyor...");
        return;
      }
      isRequestInProgress = true;
      requestTimeoutId = setTimeout(() => {
        console.log("İstek 1 dakikadan uzun sürdü, yeni istek yapılabilir.");
        isRequestInProgress = false;
      }, REQUEST_TIMEOUT);

      try {
        const response = await manifestService.GetByStringManyParamsAsync([urns[0], files[0], uid]);
        if (response.progress === "complete") {
          setProcessToUpload(false)
          setStep("Finishing...")
          setManifestStatus("Finishing...");
          setIsFinishedToLoading(true)
          clearInterval(intervalId); // Interval'i durdur
          if (requestTimeoutId) {
            clearTimeout(requestTimeoutId);
          }
        } else {
          setStep(response.progress);
          setManifestStatus(response.progress);
        }
        setIsProcessing(false)
        setWaitingForManifest(true)
      } catch (error: any) {
        console.error("Error:", error);
        setAfterUploadError(error.message !== undefined ? error.message : error.statusText)
      } finally {
        isRequestInProgress = false;

        if (requestTimeoutId) {
          clearTimeout(requestTimeoutId);
          requestTimeoutId = null;
        }
      }
    }, INTERVAL_DELAY);
  };


  const processQueue = async (index: number) => {
    if (secondSelectedFiles.length > 0) {
      setWaitingForManifest(false);
      setIsProcessing(true);
      while (index < secondSelectedFiles.length) {
        const currentFile = secondSelectedFiles[index];
        if (currentFile !== undefined) {
          // const file=getFile(currentFile.id,bucket,currentFile.rootFileName)
          const chunks = await getChunksAsync({
            id: secondSelectedFiles[index].id,
            name: secondSelectedFiles[index].name,
            size: secondSelectedFiles[index].size,
            rootFileName: rootFile
          });
          console.log(chunks);
          if (chunks !== null && chunks !== undefined) {
            var processChunkResponse = await processToChunksAsync(chunks as ProcessingUploadModel);
            console.log(processChunkResponse);
            const { urns, files, uid } = await finalizeUploadAsync(processChunkResponse as ProcessUploadIterationResponseModel, currentFile.name);
            await checkManifestStatus(urns, files, uid, currentFile.name);
          }
        }
        index++;
      }

      setIsProcessing(false);
    }
  };

  // return microsoftAuth && isSuccess ? (
  // 	loginInfo && jsonLoginInfo.isAuthenticated ? 
  return (
    <>
      {getFiles.loading === true || getUserBucket.loading === true ? (
        <div className='loading-bucket'>
          <CircleLoading text='Trying to open models...' />
        </div>
      ) : (

        <>
            <div className="inline-flex items-center space-x-2 px-2 py-2 mx-4 bg-white text-blue-600 border-b-2 border-blue-600">
              <CloudIcon className="w-5 h-5" />
              <span className="text-lg font-semibold tracking-wide">Box</span>
            </div>
          <div className='onedrive-upload'>

            {getFiles &&
              getFiles.data?.files &&
              getFiles.data.files.length > 0 ? (
              <>
                <div className='flex flex-wrap justify-start gap-4'>
                  {getFiles.data.files.map((file) => {
                    const fileExtension = file.name.split('.').pop();
                    const fileIcon = renderFileIcon(fileExtension as string);
                    return (
                      <CardItem
                        isCloud={true}
                        isActive={true}
                        imageName={fileIcon as string}
                        title={file.name}
                        buttonNames={[]}
                        buttonEvents={[]}
                        onDelete={() => { }}
                        onClickToCard={() => {
                          if (file.name.includes(".zip")) {
                            dispatch(fetchBoxSubFiles({
                              bucketName: bucket,
                              fileName: file.name
                            }))
                          }
                          if (showTranslationModal !== true) {
                            setShowTranslationModal(true);
                          }
                          setSecondSelectedFiles(() => {
                            const condition =
                              secondSelectedFiles.find(
                                (selectedfile) => selectedfile.name === file.name
                              ) === undefined;
                            if (condition) {
                              secondSelectedFiles.push({
                                name: file.name,
                                id: file.id,
                                size: file.size!,
                              });
                            }
                            return secondSelectedFiles;
                          });
                        }
                        }

                      />
                    )
                  })
                  }
                </div>
              </>
            ) : (<>
              <Container
                className='modal show'
                style={{
                  display: 'block',
                  position: 'initial',
                  alignContent: 'center',
                  marginTop: '200px',
                }}
              >
                <Modal.Dialog>
                  <Modal.Header>
                    <Modal.Title>
                      <FontAwesomeIcon icon={faFlushed} />
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    There is no file here, if you wanna upload, you can click the button
                  </Modal.Body>
                </Modal.Dialog>
              </Container>
            </>)}
            {showTranslationModal ? (
              <MultipleBoxUploadModal
                fileList={secondSelectedFiles}
                setFileList={setSecondSelectedFiles}
                show={showTranslationModal}
                onHide={() => setShowTranslationModal(false)}
                bucket={bucket}
                iamFileList={iamFilesSelector.data ? iamFilesSelector.data : undefined}
                setOpenZipOption={setOpenZipOption}
                processQueue={processQueue}
                step={step}
                processToUpload={processUpload}
                manifestStatus={manifestStatus}
                waitingToManifest={waitingToManifest}
                isContinueToProgress={isContinueProgress}
                setIsContinueToProgress={setIsContinueProgress}
                isFinishedToLoading={isFinishedToLoading}
                rootFile={rootFile}
                setRootFile={setRootFile}
                selectedFile={selectedFile}
                setSelectedFile={setSelectedFile}
              />
            ) : (
              <></>
            )}
          </div>
        </>
      )}
    </>
  )
};

export default BoxUpload;
