import React from 'react';
import { motion } from 'framer-motion';
import { FiMapPin, FiMail, FiInfo, FiFileText } from 'react-icons/fi';
import Footer from '../components/Footer/Footer';

const Contact = () => {
    const containerVariants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                staggerChildren: 0.15
            }
        }
    };

    const itemVariants = {
        hidden: { opacity: 0, y: 30 },
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                type: "spring",
                stiffness: 100,
                damping: 10
            }
        }
    };

    const glowVariants = {
        initial: { opacity: 0.5 },
        animate: {
            opacity: [0.5, 1, 0.5],
            transition: {
                duration: 2,
                repeat: Infinity,
                ease: "easeInOut"
            }
        }
    };

    return (
        <>
        <div className="h-full bg-[#0a0a1f] relative overflow-hidden">
            {/* Animated background elements */}
            <div className="absolute inset-0 overflow-hidden">
                <motion.div
                    className="absolute top-0 left-0 w-96 h-96 bg-blue-500 rounded-full filter blur-[128px] opacity-20"
                    animate={{
                        x: [-100, 100],
                        y: [-100, 100],
                    }}
                    transition={{
                        duration: 8,
                        repeat: Infinity,
                        repeatType: "reverse"
                    }}
                />
                <motion.div
                    className="absolute bottom-0 right-0 w-96 h-96 bg-purple-500 rounded-full filter blur-[128px] opacity-20"
                    animate={{
                        x: [100, -100],
                        y: [100, -100],
                    }}
                    transition={{
                        duration: 8,
                        repeat: Infinity,
                        repeatType: "reverse"
                    }}
                />
            </div>

            <div className="container mx-auto px-4 py-20 relative z-10">
                <motion.div
                    variants={containerVariants}
                    initial="hidden"
                    animate="visible"
                    className="max-w-6xl mx-auto"
                >
                    {/* Header */}
                    <motion.div
                        variants={itemVariants}
                        className="text-center mb-16"
                    >
                        <motion.h1 
                            className="text-6xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-purple-400 mb-4 relative inline-block"
                            variants={glowVariants}
                            initial="initial"
                            animate="animate"
                        >
                            Contact Us
                            <div className="absolute inset-0 bg-gradient-to-r from-blue-400 to-purple-400 opacity-30 blur-xl -z-10" />
                        </motion.h1>
                        <p className="text-xl text-gray-400">Step into the future with us</p>
                    </motion.div>

                    <div className="grid lg:grid-cols-2 gap-12">
                        {/* Map Section */}
                        <motion.div
                            variants={itemVariants}
                            className="rounded-3xl overflow-hidden relative group"
                            whileHover={{ scale: 1.02 }}
                            transition={{ duration: 0.3 }}
                        >
                            <div className="absolute inset-0 bg-gradient-to-r from-blue-500 to-purple-500 opacity-50 group-hover:opacity-70 transition-opacity" />
                            <div className="absolute inset-0 bg-gradient-to-r from-blue-500 to-purple-500 opacity-30 blur-md" />
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2495.9776857075766!2d0.4918613!3d51.2527778!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47df31d8c2c6899f%3A0x86049df1db28c519!2sGallants%20Farm%2C%20Gallants%20Ln%2C%20East%20Farleigh%2C%20Maidstone%20ME15%200LF%2C%20UK!5e0!3m2!1sen!2str!4v1710835000000!5m2!1sen!2str"
                                width="100%"
                                height="450"
                                style={{ border: 0 }}
                                allowFullScreen
                                loading="lazy"
                                referrerPolicy="no-referrer-when-downgrade"
                                className="relative z-10"
                            />
                        </motion.div>

                        {/* Contact Information */}
                        <motion.div
                            variants={containerVariants}
                            className="space-y-6"
                        >
                            <motion.div
                                variants={itemVariants}
                                className="bg-[#12123e] p-8 rounded-3xl relative group"
                                whileHover={{ scale: 1.02 }}
                                transition={{ duration: 0.3 }}
                            >
                                <div className="absolute inset-0 bg-gradient-to-r from-blue-500/20 to-purple-500/20 rounded-3xl opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
                                <div className="absolute inset-0 bg-gradient-to-r from-blue-500/10 to-purple-500/10 rounded-3xl blur-xl" />
                                
                                <h2 className="text-3xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-purple-400 mb-8 relative z-10">
                                    Company Information
                                </h2>
                                
                                <div className="space-y-8 relative z-10">
                                    <motion.div
                                        variants={itemVariants}
                                        className="flex items-start space-x-4 group"
                                        whileHover={{ x: 10 }}
                                    >
                                        <div className="bg-gradient-to-br from-blue-500 to-purple-500 p-3 rounded-2xl group-hover:shadow-[0_0_15px_rgba(59,130,246,0.5)] transition-shadow">
                                            <FiMapPin className="text-white text-xl" />
                                        </div>
                                        <div>
                                            <h3 className="text-xl font-semibold text-gray-200 mb-2">Address</h3>
                                            <p className="text-gray-400">
                                                Gallants Farm, Gallants Lane,<br />
                                                East Farleigh, Maidstone,<br />
                                                England, ME15 0LF
                                            </p>
                                        </div>
                                    </motion.div>

                                    <motion.div
                                        variants={itemVariants}
                                        className="flex items-start space-x-4 group"
                                        whileHover={{ x: 10 }}
                                    >
                                        <div className="bg-gradient-to-br from-blue-500 to-purple-500 p-3 rounded-2xl group-hover:shadow-[0_0_15px_rgba(59,130,246,0.5)] transition-shadow">
                                            <FiInfo className="text-white text-xl" />
                                        </div>
                                        <div>
                                            <h3 className="text-xl font-semibold text-gray-200 mb-2">Company Details</h3>
                                            <p className="text-gray-400">
                                                VEEWER is a product of{' '}
                                                <a 
                                                    href="https://www.codeosolutions.com" 
                                                    target="_blank" 
                                                    rel="noopener noreferrer"
                                                    className="text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-purple-400 font-semibold hover:opacity-80 transition-opacity"
                                                >
                                                    CodeoSolutions
                                                </a>
                                            </p>
                                            <p className="text-gray-400 mt-2">
                                                Registration Number: 14645731
                                            </p>
                                        </div>
                                    </motion.div>

                                    <motion.div
                                        variants={itemVariants}
                                        className="flex items-start space-x-4 group"
                                        whileHover={{ x: 10 }}
                                    >
                                        <div className="bg-gradient-to-br from-blue-500 to-purple-500 p-3 rounded-2xl group-hover:shadow-[0_0_15px_rgba(59,130,246,0.5)] transition-shadow">
                                            <FiFileText className="text-white text-xl" />
                                        </div>
                                        <div>
                                            <h3 className="text-xl font-semibold text-gray-200 mb-2">UTR Information</h3>
                                            <p className="text-gray-400">
                                                UTR Number: 4417829251<br />
                                                VAT Number: GB471553781
                                            </p>
                                        </div>
                                    </motion.div>

                                    <motion.div
                                        variants={itemVariants}
                                        className="flex items-start space-x-4 group"
                                        whileHover={{ x: 10 }}
                                    >
                                        <div className="bg-gradient-to-br from-blue-500 to-purple-500 p-3 rounded-2xl group-hover:shadow-[0_0_15px_rgba(59,130,246,0.5)] transition-shadow">
                                            <FiMail className="text-white text-xl" />
                                        </div>
                                        <div>
                                            <h3 className="text-xl font-semibold text-gray-200 mb-2">Contact</h3>
                                            <p className="text-gray-400">
                                                <a 
                                                    href="mailto:contact@veewer.com" 
                                                    className="hover:text-blue-400 transition-colors"
                                                >
                                                    contact@veewer.com
                                                </a>
                                                <br />
                                                <a 
                                                    href="tel:+902321234567" 
                                                    className="hover:text-blue-400 transition-colors"
                                                >
                                                    +44 7469 011663
                                                </a>
                                            </p>
                                        </div>
                                    </motion.div>
                                </div>
                            </motion.div>
                        </motion.div>
                    </div>
                </motion.div>
            </div>
        </div>
        <Footer />
        </>
    );
};

export default Contact; 