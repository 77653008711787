import { UploadedFile } from '../../models/object/uploadedObject/put.uploadedObject.request.model';
import { UploadProgress, UploadStage } from '../../services/upload/UploadManager';
import { InsertDriveFile } from '@mui/icons-material';
import { Close as CloseIcon } from '@mui/icons-material';
import { FileDownloadDone, ErrorOutline, CloudUpload, HourglassEmpty } from '@mui/icons-material';

interface SelectedFilesListProps {
  files: UploadedFile[];
  onRemove: (index: number) => void;
  uploadProgress: Map<string, UploadProgress>;
  isProcessing: boolean;
  isStarted: boolean;
}

const SelectedFilesList: React.FC<SelectedFilesListProps> = ({
  files,
  onRemove,
  uploadProgress,
  isProcessing,
  isStarted
}) => {
  const getCompletedCount = () => {
    const completed = Array.from(uploadProgress.values()).filter(
      progress => progress.stage === UploadStage.COMPLETED
    ).length;
    console.log('Completed files count:', completed);
    return completed;
  };

  const getStatusDisplay = (progress: UploadProgress) => {
    const { stage, message, currentOperation, progress: progressValue, error } = progress;
    console.log('Getting status display for:', { stage, message, currentOperation, progressValue });
    
    switch (stage) {
      case UploadStage.ERROR:
        return {
          text: error || 'Error occurred',
          color: 'bg-red-50',
          border: 'border-red-100',
          icon: <ErrorOutline className="w-5 h-5 text-red-500" />,
          progressValue: 0
        };
      case UploadStage.COMPLETED:
        return {
          text: message || currentOperation || 'Upload complete',
          color: 'bg-green-50',
          border: 'border-green-100',
          icon: <FileDownloadDone className="w-5 h-5 text-green-500" />,
          progressValue: 100
        };
      case UploadStage.UPLOADING:
        return {
          text: message || currentOperation || 'Uploading...',
          color: 'bg-blue-50',
          border: 'border-blue-100',
          icon: <CloudUpload className="w-5 h-5 text-blue-500" />,
          progressValue: progressValue || 0
        };
      case UploadStage.PAUSED:
        return {
          text: message || currentOperation || 'Upload paused - Insufficient credits',
          color: 'bg-yellow-50',
          border: 'border-yellow-100',
          icon: <HourglassEmpty className="w-5 h-5 text-yellow-500" />,
          progressValue: progressValue || 0
        };
      case UploadStage.PREPARING:
        return {
          text: message || currentOperation || 'Preparing file...',
          color: 'bg-purple-50',
          border: 'border-purple-100',
          icon: <HourglassEmpty className="w-5 h-5 text-purple-500" />,
          progressValue: progressValue || 0
        };
      case UploadStage.QUEUED:
      default:
        return {
          text: message || currentOperation || 'Ready to upload',
          color: 'bg-gray-50',
          border: 'border-gray-100',
          icon: <InsertDriveFile className="w-5 h-5 text-gray-500" />,
          progressValue: 0
        };
    }
  };

  return (
    <div className="mt-4 flex flex-col">
      <div className="flex items-center justify-between mb-3 sticky top-0 bg-white z-10 pb-2">
        <div className="flex items-center gap-3">
          <h4 className="text-sm font-medium text-gray-700">Selected Files</h4>
          <div className="flex items-center gap-2">
            <span className={`w-2 h-2 rounded-full ${
              files.length === 0 ? 'bg-gray-300' : 
              isProcessing ? 'bg-yellow-500 animate-pulse' : 
              Array.from(uploadProgress.values()).filter(p => p.stage === UploadStage.COMPLETED).length === files.length && files.length > 0 ? 
              'bg-green-500' : 'bg-gray-300'
            }`} />
            <span className="text-xs text-gray-500">
              {files.length} file(s) selected • {Array.from(uploadProgress.values()).filter(
                progress => progress.stage === UploadStage.COMPLETED
              ).length} completed
              {files.length > 0 && Array.from(uploadProgress.values()).filter(p => p.stage === UploadStage.COMPLETED).length === files.length && (
                <span className="ml-2 text-green-600">• All files completed successfully!</span>
              )}
            </span>
          </div>
        </div>
        {files.length > 0 && !isProcessing && !isStarted && (
          <button
            onClick={() => onRemove(-1)}
            className="text-xs text-red-500 hover:text-red-600 px-2 py-1 hover:bg-red-50 rounded"
          >
            Remove All
          </button>
        )}
      </div>

      <div className="overflow-y-auto scrollbar-thin scrollbar-thumb-gray-300 hover:scrollbar-thumb-gray-400 scrollbar-track-transparent" style={{ maxHeight: '200px' }}>
        <div className="space-y-2 pr-2">
          {files.map((file, i) => {
            const status = uploadProgress.get(file.file.name) || {
              stage: UploadStage.QUEUED,
              message: 'Ready to upload'
            };
            const statusDisplay = getStatusDisplay(status);
            console.log('File status:', file.file.name, status, statusDisplay);

            return (
              <div key={i} className={`flex items-center justify-between p-3 rounded-lg border ${statusDisplay.color} ${statusDisplay.border}`}>
                <div className="flex items-center space-x-3">
                  {statusDisplay.icon}
                  <div>
                    <div className="flex items-center gap-2">
                      <p className="text-sm text-gray-700 truncate max-w-[300px]">
                        {file.file.name}
                      </p>
                      {file.rootFileName && (
                        <span className="px-2 py-0.5 bg-blue-50 text-blue-600 text-xs rounded-full">
                          Root: {file.rootFileName}
                        </span>
                      )}
                    </div>
                    <p className="text-xs text-gray-500 mt-0.5">
                      {statusDisplay.text}
                    </p>
                    {(status.stage === UploadStage.UPLOADING || status.stage === UploadStage.MANIFEST_PROCESSING) && (
                      <div className="mt-2">
                        <div className="flex items-center justify-between text-xs text-gray-500 mb-1">
                          <span>{status.currentOperation || statusDisplay.text}</span>
                          <span>{statusDisplay.progressValue}%</span>
                        </div>
                        <div className="w-full h-1 bg-gray-100 rounded-full overflow-hidden">
                          <div 
                            className="h-1 bg-blue-500 rounded-full transition-all duration-300"
                            style={{ width: `${statusDisplay.progressValue}%` }}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  <span className="text-xs text-gray-500">
                    {(file.file.size / 1024).toFixed(1)} KB
                  </span>
                  <button
                    onClick={() => onRemove(i)}
                    className="p-1 hover:bg-gray-100 rounded-full"
                    disabled={isProcessing || isStarted}
                  >
                    <CloseIcon className="w-4 h-4 text-gray-400" />
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default SelectedFilesList; 