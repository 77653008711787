import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CardItem from '../../components/CardItem';
import { RootState, useAppDispatch, useAppSelector } from '../../store';
import { TokenResponse } from '../../models/auth/auth.token.model';
import Unauthorized from '../../components/Unauthorized';
import ConfirmModal from '../../components/Modals/ConfirmDeleteModal';
import { DeleteBucketService } from '../../services/bucket/delete.bucket.service';
import CircleLoading from '../../components/Loading/CircleLoading';
import SecondHeader from '../../components/Navbar/SecondHeader';
import '../../styles/bucketList/bucketList.css';
import folderVariant from '../../assets/folderVariant.png';
import { fetchUserBucket } from '../../store/features/bucket/get.user.bucket.slice';
import { LoginResponse } from '../../models/auth/login.model';

const BucketList = () => {
  const [confirmModal, setConfirmModal] = useState(false);
  const [deletedBucket, setDeletedBucket] = useState('');

  const dispatch = useAppDispatch();
  const userBucketSelector = useAppSelector((s: RootState) => s.getUserBucket)
  const userBucket = userBucketSelector.data


  const navigate = useNavigate();

  const openObjectList = (bucketKey: string) => {
    navigate('/models', {
      state: {
        bucketKey,
      },
    });
  };

  const loginInfo = sessionStorage.getItem('login') as string;
  const jsonLoginInfo = JSON.parse(loginInfo) as LoginResponse

  useEffect(() => {
    dispatch(fetchUserBucket())
  }, []);

  return loginInfo && jsonLoginInfo.isAuthenticated ? (
    <div>
      {userBucketSelector.loading ? (
        <div className='loading-bucket'>
          <div className='loading-message'>
            <p>Loading Storage Page...</p>
            <div className='spinner'></div>
          </div>
        </div>
      ) : (
        <>
          <div className='card-container-grid'>
            <div className='grid-wrapper'>
              {
                userBucket?(
                  <div className='layout-grid'>
                  <CardItem
                    isCloud={true}
                    isActive={true}
                    imageName={folderVariant}
                    title={userBucket?.bucketKey}
                    buttonNames={[]}
                    buttonEvents={[
                      () => {
                      
                      },
                    ]}
                    onDelete={() => {
                      // setDeletedBucket(userBucket?.bucketKey);
                      // setConfirmModal(true);
                    }}
                    onClickToCard={() => {
                      openObjectList(userBucket?.bucketKey);
                    }}
                  />
                </div>
                ):(<></>)
              }         
            </div>
        </div>

      {/* {confirmModal ? (
        <ConfirmModal
          onYes={async () => {
            const deleteBucketService = new DeleteBucketService();

            const response =
              await deleteBucketService.DeleteByStringParamByAsync(
                deletedBucket
              );
            console.log(response);
            if (response !== null) {
              setConfirmModal(false);
              window.location.reload();
            }
          }}
          onNo={() => {
            setConfirmModal(false);
          }}
          show={confirmModal}
          onHide={setConfirmModal}
        />
      ) : (
        <></>
      )} */}
    </>
  )}
    </div >
  ) : (
  <>
    <Unauthorized />
  </>
);
};

export default BucketList;
