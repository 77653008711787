import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchForgotPassword, resetForgotPasswordState } from '../store/features/forgotPassword/forgot.password.slice';
import { RootState, useAppDispatch, useAppSelector } from '../store';
import LoadingScreen from '../utils/LoadingScreen';
import logo from '../assets/Veewer_black_stripes_E.png';

export default function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState({content: "",color: ""});
  const [isEmailTouched, setIsEmailTouched] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const forgotPasswordState = useAppSelector((state: RootState) => state.forgotPasswordSlice);


  //If we need to email regex control we will think this
  // const [passToEmailValidate, setPassToEmailValidate] = useState(true)
  // const isValidEmail = (email: string) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  
 
 

  useEffect(() => {
    dispatch(resetForgotPasswordState())
    setMessage({
      content: "",
      color: ""
    });
  }, []);

  const validateEmail = (email: string) => {
    const regex = /^[^\s@]+@[^\s@]+\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?$/;
    return regex.test(email);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setEmail(value);
    
    // Email validasyonunu anlık olarak yap
    setIsEmailValid(validateEmail(value));
    setIsEmailTouched(true);

    if (message.content !== "") {
      setMessage({
        content: "",
        color: ""
      });
    }
  };


  useEffect(() => {
    if (forgotPasswordState.data === null &&
      (forgotPasswordState.error === null || forgotPasswordState.error === undefined) && message.content !== "") {
      setMessage({
        content: "",
        color: ""
      })
    }
  }, [forgotPasswordState, message])

  const handleForgotPassword = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(fetchForgotPassword({ email }));
  };

  useEffect(() => {
    if (forgotPasswordState.data) {
      setMessage({
        content: forgotPasswordState.data.message,
        color: "green"
      });
      if (forgotPasswordState.data.token && forgotPasswordState.data.message.includes('Password reset email sent')) {
        setTimeout(() => {
          dispatch(resetForgotPasswordState())
          navigate('/login')
        }, 3000);
      }
    }
    else {
      if (forgotPasswordState.error && forgotPasswordState.error.error) {
        setMessage({
          content: forgotPasswordState.error.error,
          color: "red"
        })
      }

    }
  }, [forgotPasswordState.data, forgotPasswordState.error, navigate]);

  return (
    <div className="min-h-[80vh] flex flex-col items-center justify-center w-full px-4 sm:px-6 lg:px-8">
      <div className="flex flex-col items-center mb-8">

        <div className="text-center">
          <h1 className="text-2xl sm:text-3xl font-bold text-gray-900 mb-2">Forgot Password?</h1>
          <p className="text-sm sm:text-base text-gray-600 max-w-sm">
            No worries! Enter your email and we'll send you instructions to reset your password.
          </p>
        </div>
      </div>

      <div className="w-full max-w-md">
        {forgotPasswordState.loading ? (
          <div className="flex items-center justify-center min-h-[200px]">
            <LoadingScreen isLoginMobile={false} />
          </div>
        ) : (
          <form onSubmit={handleForgotPassword} className="bg-white p-6 space-y-6">
            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-700">Email</label>
              <input
                type="email"
                value={email}
                onChange={handleChange}
                required
                className={`w-full h-10 px-3 py-2 border rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 ${
                  !isEmailValid && email.length > 0 ? 'border-red-500' : 'border-gray-300'
                }`}
              />
              {!isEmailValid && email.length > 0 && (
                <p className="text-red-500 text-sm">Please enter a valid email address</p>
              )}
            </div>

            {message.content && (
              <div className={`text-sm ${message.color === "green" ? "text-green-600" : "text-red-600"}`}>
                {message.content}
              </div>
            )}

            <div className="flex flex-col space-y-4">
              <button
                type="submit"
                disabled={!isEmailValid || email.length === 0 || message.color === "red"}
                className={`w-full h-10 px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white 
                  ${(!isEmailValid || email.length === 0 || message.color === "red")
                    ? 'bg-gray-400 cursor-not-allowed'
                    : 'bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
                }`}
              >
                Reset Password
              </button>
              <button
                type="button"
                onClick={() => navigate('/login')}
                className="text-sm text-blue-600 hover:text-blue-500"
              >
                Back to Login
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
}
