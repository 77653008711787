import { Link } from "react-router-dom";
import { OneDriveLogoutService } from "../../services/oneDrive/auth/logout.service";
import { LoginResponse } from "../../models/auth/login.model";
import { LogoutService } from "../../services/auth/logout.service";
import { useState, useEffect } from "react";

interface OneDriveNavProps {
    handleCloudMenuToggle: () => void;
    setMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
  }

  const OneDriveNav: React.FC<OneDriveNavProps> = ({ handleCloudMenuToggle, setMenuOpen }) => {
  const clickedToOneDrive = sessionStorage.getItem("clickOneDrive");
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (clickedToOneDrive !== null && clickedToOneDrive === "true") {
      sessionStorage.removeItem(clickedToOneDrive);
    }
  }, [clickedToOneDrive]);

  const handleLogout = async () => {
    const logoutService = new OneDriveLogoutService();
    const b = sessionStorage.getItem("b") as string;
    const res = await logoutService.DeleteByStringParamByAsync(b);
    console.log(res);
    if (res.isSuccess) {
      const microsoftInternalAuth = sessionStorage.getItem("login") as string;
      const jsonMicrosoftInternalAuth = JSON.parse(
        microsoftInternalAuth
      ) as LoginResponse;

      if (
        microsoftInternalAuth &&
        jsonMicrosoftInternalAuth &&
        jsonMicrosoftInternalAuth.microsoft_auth &&
        jsonMicrosoftInternalAuth.isAuthenticated
      ) {
        const secondaryLogoutService = new LogoutService();
        const secondaryRes = await secondaryLogoutService.GetAsync();
        if (secondaryRes.isSuccess) {
          sessionStorage.removeItem("login");
          sessionStorage.removeItem("b");
        }
      }

      sessionStorage.removeItem("microsoft-auth");
      window.location.replace("/");
      if(handleCloudMenuToggle) handleCloudMenuToggle();
    }
  };

  return (
    <div className="relative inline-block text-left">
      {/* Dropdown Trigger */}
      <button
        onClick={() => setIsOpen((prev) => !prev)}
        className="flex items-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
      >
        OneDrive
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-5 h-5 ml-2 text-gray-500"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M5.23 7.21a.75.75 0 011.06.02L10 10.94l3.71-3.71a.75.75 0 111.06 1.06l-4 4a.75.75 0 01-1.06 0l-4-4a.75.75 0 01.02-1.06z"
            clipRule="evenodd"
          />
        </svg>
      </button>

      {/* Dropdown Menu */}
      {isOpen && (
        <div
          className="absolute left-30 z-10 w-56 mt-2 origin-top-right bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg focus:outline-none"
          role="menu"
        >
          <div className="py-1" onClick={()=> { setMenuOpen(false); handleCloudMenuToggle();}}>
            <Link
              to="/oneDrive/models"
              className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-blue-600"
              role="menuitem"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-5 h-5 mr-3 text-blue-500"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M10 2a8 8 0 100 16 8 8 0 000-16zM8 10a2 2 0 114 0 2 2 0 01-4 0zm5 0a3 3 0 11-6 0 3 3 0 016 0z"
                  clipRule="evenodd"
                />
              </svg>
              Models
            </Link>
          </div>
          <div className="py-1">
            <button
              onClick={handleLogout}
              className="flex items-center w-full px-4 py-2 text-sm text-left text-gray-700 hover:bg-gray-100 hover:text-red-600"
              role="menuitem"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-5 h-5 mr-3 text-red-500"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 111.414 1.414L11.414 10l4.293 4.293a1 1 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
              Logout
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default OneDriveNav;
