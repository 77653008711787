import { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../store';
import { fetchUserRegister, reset } from '../../store/features/user/user.register.slice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash, faArrowLeft, faArrowRight, faXmark, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import LoadingScreen from '../../utils/LoadingScreen';
import Swal from 'sweetalert2';
import axios from 'axios';
import { baseUrl } from '../../config/axios.config';

type FormField = 'userName' | 'firstName' | 'lastName' | 'companyName' | 'email' | 'password';

const SignUp = ({setActiveAction}:any) => {
  const [currentStep, setCurrentStep] = useState(1);
  const totalSteps = 3;
  const [showPassword, setShowPassword] = useState(false);
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [email, setEmail] = useState('');
  const [touched, setTouched] = useState<Record<FormField, boolean>>({
    userName: false,
    firstName: false,
    lastName: false,
    companyName: false,
    email: false,
    password: false
  });
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [acceptedPrivacy, setAcceptedPrivacy] = useState(false);
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [showPrivacyModal, setShowPrivacyModal] = useState(false);
  const [isCheckingUsername, setIsCheckingUsername] = useState(false);
  const [isCheckingEmail, setIsCheckingEmail] = useState(false);

  const dispatch = useAppDispatch();
  const postRegisterSelector = useAppSelector((x) => x.fetchUserRegisterSlice);
  const loginSelector = useAppSelector((x) => x.postAuth);

  const [errors, setErrors] = useState<Record<FormField, string>>({
    firstName: '',
    lastName: '',
    userName: '',
    email: '',
    password: '',
    companyName: ''
  });

  // Password validation rules
  const passwordRules = [
    {
      text: 'Minimum 6 characters',
      state: () => /.{6,}/.test(password),
      errorMessage:'Password must be at least 6 characters'
    },
    {
      text: 'A non-alphanumeric character',
      state: () => /.*[^a-zA-Z\d].*/.test(password),
      errorMessage:'Password must contain at least 1 non-alphanumeric character'
    },
    {
      text: 'A lowercase letter',
      state: () => /[a-z]/.test(password),
      errorMessage:'Password must contain at least 1 lowercase letter'
    },
    {
      text: 'An uppercase letter',
      state: () => /[A-Z]/.test(password),
      errorMessage:'Password must contain at least 1 uppercase letter'
    },
    {
      text: 'A number',
      state: () => /\d/.test(password),
      errorMessage:'Password must contain at least 1 number'
    },
  ];

  const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  const nameRegex = /^[a-zA-Z0-9\sÇŞĞÜÖİçşğüöı]*$/;

  const validateField = (field: FormField, value: string) => {
    let error = '';

    switch (field) {
      case 'firstName':
        if (!value.trim()) {
          error = 'First name is required';
        } else if (!nameRegex.test(value)) {
          error = 'First name contains invalid characters';
        } else if (value.length < 2) {
          error = 'First name is too short';
        } else if (/^\d+$/.test(value)) {
          error = "First name cannot consist of only numbers";
        }
        break;

      case 'lastName':
        if (!value.trim()) {
          error = 'Last name is required';
        } else if (!nameRegex.test(value)) {
          error = 'Last name contains invalid characters';
        } else if (value.length < 2) {
          error = 'Last name is too short';
        } else if (/^\d+$/.test(value)) {
          error = "Last name cannot consist of only numbers";
        }
        break;

      case 'userName':
        if (!value.trim()) {
          error = 'Username is required';
        } else if (!nameRegex.test(value)) {
          error = 'Username contains invalid characters';
        } else if (value.length < 3) {
          error = 'Username is too short';
        } else if (/^\d+$/.test(value)) {
          error = "Username cannot consist of only numbers";
        }
        break;

      case 'email':
        if (!value.trim()) {
          error = 'Email is required';
        } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
          error = 'Invalid email format';
        }
        break;

      case 'companyName':
        if (!value.trim()) {
          error = 'Company name is required';
        } else if (!nameRegex.test(value)) {
          error = 'Company name contains invalid characters';
        } else if (value.length < 2) {
          error = 'Company name is too short';
        }
        break;

      case 'password':
        const invalidRules = passwordRules.filter(rule => !rule.state());
        if (invalidRules.length > 0) {
          error = invalidRules[0].errorMessage;
        }
        break;
    }

    return error;
  };

  const handleChange = (field: FormField, value: string) => {
    const error = validateField(field, value);
    setErrors(prev => ({ ...prev, [field]: error }));

    switch (field) {
      case 'userName':
        setUserName(value);
        break;
      case 'firstName':
        setFirstName(value);
        break;
      case 'lastName':
        setLastName(value);
        break;
      case 'companyName':
        setCompanyName(value);
        break;
      case 'email':
        setEmail(value);
        break;
      case 'password':
        setPassword(value);
        break;
    }
  };

  const handleBlur = (field: FormField) => {
    setTouched(prev => ({ ...prev, [field]: true }));
    const error = validateField(field, field === 'email' ? email : 
                                   field === 'password' ? password :
                                   field === 'userName' ? userName :
                                   field === 'firstName' ? firstName :
                                   field === 'lastName' ? lastName :
                                   companyName);
    setErrors(prev => ({ ...prev, [field]: error }));
  };

  const canProceedToNextStep = () => {
    switch (currentStep) {
      case 1:
        return userName && !errors.userName && 
               firstName && !errors.firstName && 
               lastName && !errors.lastName;
      case 2:
        return companyName && !errors.companyName && 
               email && !errors.email;
      case 3:
        return password && !errors.password && 
               acceptedTerms && acceptedPrivacy;
      default:
        return false;
    }
  };

  // Add function to check username availability
  const checkUsername = async () => {
    setIsCheckingUsername(true);
    try {
      const response = await axios.get(`${baseUrl}user/check-username?username=${userName}`);
      const { isAvailable, message } = response.data;
      
      if (!isAvailable) {
        setErrors(prev => ({ ...prev, userName: message }));
        return false;
      }
      return true;
    } catch (error) {
      setErrors(prev => ({ ...prev, userName: 'Error checking username availability' }));
      return false;
    } finally {
      setIsCheckingUsername(false);
    }
  };

  // Add function to check email availability
  const checkEmail = async () => {
    setIsCheckingEmail(true);
    try {
      const response = await axios.get(`${baseUrl}user/check-email?email=${email}`);
      const { isAvailable, message } = response.data;
      
      if (!isAvailable) {
        setErrors(prev => ({ ...prev, email: message }));
        return false;
      }
      return true;
    } catch (error) {
      setErrors(prev => ({ ...prev, email: 'Error checking email availability' }));
      return false;
    } finally {
      setIsCheckingEmail(false);
    }
  };

  // Modify the next step handler
  const handleNextStep = async () => {
    if (!canProceedToNextStep()) return;

    if (currentStep === 1) {
      const isUsernameAvailable = await checkUsername();
      if (!isUsernameAvailable) return;
    }

    if (currentStep === 2) {
      const isEmailAvailable = await checkEmail();
      if (!isEmailAvailable) return;
    }

    setCurrentStep(prev => prev + 1);
  };

  const renderStepContent = () => {
    switch (currentStep) {
      case 1:
  return (
          <div className='space-y-3 sm:space-y-4'>
            <div className='form-group min-h-[70px]'>
              <label className="block text-xs sm:text-sm font-medium text-gray-700 mb-1">Username*</label>
                  <input
                    required
                className={`w-full h-9 sm:h-10 px-2 sm:px-3 py-1.5 sm:py-2 text-sm border rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 ${
                  touched.userName && errors.userName ? 'border-red-500' : 'border-gray-300'
                }`}
                    type='text'
                    value={userName}
                    onChange={(e) => {
                      setUserName(e.target.value);
                  handleChange('userName', e.target.value);
                    }}
                onBlur={() => handleBlur('userName')}
                  />
              {touched.userName && errors.userName && (
                <div className="text-red-500 text-xs mt-1">{errors.userName}</div>
              )}
                </div>

            <div className='form-group min-h-[70px]'>
              <label className="block text-xs sm:text-sm font-medium text-gray-700 mb-1">First Name*</label>
                  <input
                    required
                className={`w-full h-9 sm:h-10 px-2 sm:px-3 py-1.5 sm:py-2 text-sm border rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 ${
                  touched.firstName && errors.firstName ? 'border-red-500' : 'border-gray-300'
                }`}
                    type='text'
                    value={firstName}
                    onChange={(e) => {
                      setFirstName(e.target.value);
                  handleChange('firstName', e.target.value);
                    }}
                onBlur={() => handleBlur('firstName')}
                  />
              {touched.firstName && errors.firstName && (
                <div className="text-red-500 text-xs mt-1">{errors.firstName}</div>
              )}
                </div>

            <div className='form-group min-h-[70px]'>
              <label className="block text-xs sm:text-sm font-medium text-gray-700 mb-1">Last Name*</label>
                  <input
                    required
                className={`w-full h-9 sm:h-10 px-2 sm:px-3 py-1.5 sm:py-2 text-sm border rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 ${
                  touched.lastName && errors.lastName ? 'border-red-500' : 'border-gray-300'
                }`}
                    type='text'
                    value={lastName}
                    onChange={(e) => {
                      setLastName(e.target.value);
                  handleChange('lastName', e.target.value);
                    }}
                onBlur={() => handleBlur('lastName')}
                  />
              {touched.lastName && errors.lastName && (
                <div className="text-red-500 text-xs mt-1">{errors.lastName}</div>
              )}
            </div>
                </div>
        );

      case 2:
        return (
          <div className='space-y-3 sm:space-y-4'>
            <div className='form-group min-h-[70px]'>
              <label className="block text-xs sm:text-sm font-medium text-gray-700 mb-1">Company*</label>
                  <input
                    required
                className={`w-full h-9 sm:h-10 px-2 sm:px-3 py-1.5 sm:py-2 text-sm border rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 ${
                  touched.companyName && errors.companyName ? 'border-red-500' : 'border-gray-300'
                }`}
                    type='text'
                    value={companyName}
                    onChange={(e) => {
                      setCompanyName(e.target.value);
                  handleChange('companyName', e.target.value);
                    }}
                onBlur={() => handleBlur('companyName')}
                  />
              {touched.companyName && errors.companyName && (
                <div className="text-red-500 text-xs mt-1">{errors.companyName}</div>
              )}
                </div>

            <div className='form-group min-h-[70px]'>
              <label className="block text-xs sm:text-sm font-medium text-gray-700 mb-1">Email*</label>
                  <input
                    required
                className={`w-full h-9 sm:h-10 px-2 sm:px-3 py-1.5 sm:py-2 text-sm border rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 ${
                  touched.email && errors.email ? 'border-red-500' : 'border-gray-300'
                }`}
                    type='email'
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                  handleChange('email', e.target.value);
                    }}
                onBlur={() => handleBlur('email')}
                  />
              {touched.email && errors.email && (
                <div className="text-red-500 text-xs mt-1">{errors.email}</div>
              )}
            </div>
                </div>
        );

      case 3:
        return (
          <div className='space-y-3 sm:space-y-4'>
            <div className='form-group relative min-h-[70px]'>
              <label className="block text-xs sm:text-sm font-medium text-gray-700 mb-1">Password*</label>
              <div className="relative">
                  <input
                    type={showPassword ? 'text' : 'password'}
                    required
                  className={`w-full h-9 sm:h-10 px-2 sm:px-3 py-1.5 sm:py-2 text-sm border rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 ${
                    touched.password && errors.password ? 'border-red-500' : 'border-gray-300'
                  }`}
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                    handleChange('password', e.target.value);
                  }}
                  onBlur={() => handleBlur('password')}
                />
                <button
                  type="button"
                  className="absolute inset-y-0 right-0 pr-2 sm:pr-3 flex items-center"
                  onClick={() => setShowPassword(prev => !prev)}
                >
                  <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} className="text-gray-400 text-sm sm:text-base" />
                </button>
              </div>
              {touched.password && errors.password && (
                <div className="text-red-500 text-xs mt-1">{errors.password}</div>
              )}
              <ul className='mt-2 space-y-1'>
                    {passwordRules.map((rule, index) => (
                      <li
                    className='text-xs flex items-center'
                        key={index}
                      >
                        <span
                      className={`mr-1.5 sm:mr-2 ${rule.state() ? 'text-green-500' : 'text-red-500'}`}
                        >
                          {rule.state() ? '✔' : '•'}
                        </span>
                        {rule.text}
                      </li>
                    ))}
                  </ul>
            </div>

            <div className="space-y-3 sm:space-y-4 mt-2 sm:mt-4">
              <div className="flex items-center">
                <input
                  type="checkbox"
                  id="terms"
                  checked={acceptedTerms}
                  onChange={(e) => setAcceptedTerms(e.target.checked)}
                  className="h-4 w-4 text-[#16254c] focus:ring-[#16254c] border-gray-300 rounded"
                />
                <label htmlFor="terms" className="ml-2 block text-xs sm:text-sm text-gray-900">
                  I accept the{' '}
                  <button
                    type="button"
                    onClick={() => setShowTermsModal(true)}
                    className="text-[#16254c] hover:text-[#1e3a8a] transition-colors duration-300 underline"
                  >
                    Terms of Service
                  </button>
                </label>
              </div>

              <div className="flex items-center">
                <input
                  type="checkbox"
                  id="privacy"
                  checked={acceptedPrivacy}
                  onChange={(e) => setAcceptedPrivacy(e.target.checked)}
                  className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                />
                <label htmlFor="privacy" className="ml-2 block text-xs sm:text-sm text-gray-900">
                  I accept the{' '}
                  <button
                    type="button"
                    onClick={() => setShowPrivacyModal(true)}
                    className="text-blue-600 hover:text-blue-500 underline"
                  >
                    Privacy Policy
                  </button>
                </label>
              </div>
            </div>
          </div>
        );
    }
  };

  // Modal Components
  const TermsModal = () => (
    <div className={`fixed inset-0 z-50 flex items-center justify-center ${showTermsModal ? '' : 'hidden'}`}>
      <div className="fixed inset-0 bg-black/50 backdrop-blur-sm" onClick={() => setShowTermsModal(false)}></div>
      <div className="bg-white rounded-lg p-8 max-w-3xl w-full mx-4 relative z-10 max-h-[90vh] overflow-y-auto">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-bold text-gray-900">Terms of Service</h2>
          <button 
            onClick={() => setShowTermsModal(false)}
            className="text-gray-400 hover:text-gray-600 transition-colors"
          >
            <FontAwesomeIcon icon={faXmark} className="w-6 h-6" />
          </button>
        </div>
        
        <div className="prose prose-sm max-w-none mb-6">
          <h3 className="text-lg font-semibold mb-3">1. Acceptance of Terms</h3>
          <p className="mb-4">By accessing and using Veewer's services, you agree to be bound by these Terms of Service and all applicable laws and regulations.</p>

          <h3 className="text-lg font-semibold mb-3">2. Use of Service</h3>
          <p className="mb-4">You agree to use the service only for lawful purposes and in accordance with these Terms. You are responsible for maintaining the confidentiality of your account information.</p>

          <h3 className="text-lg font-semibold mb-3">3. User Data</h3>
          <p className="mb-4">We respect your privacy and handle your data in accordance with our Privacy Policy. You retain all rights to your data uploaded to our platform.</p>

          <h3 className="text-lg font-semibold mb-3">4. Service Modifications</h3>
          <p className="mb-4">We reserve the right to modify or discontinue the service at any time, with or without notice.</p>

          <h3 className="text-lg font-semibold mb-3">5. Limitation of Liability</h3>
          <p className="mb-4">To the maximum extent permitted by law, Veewer shall not be liable for any indirect, incidental, special, consequential, or punitive damages.</p>
        </div>

        <div className="flex justify-end">
          <button
            onClick={() => {
              setAcceptedTerms(true);
              setShowTermsModal(false);
            }}
            className="bg-blue-600 text-white px-6 py-2 rounded-md hover:bg-blue-700 transition-colors"
          >
            I Accept
          </button>
        </div>
      </div>
    </div>
  );

  const PrivacyModal = () => (
    <div className={`fixed inset-0 z-50 flex items-center justify-center ${showPrivacyModal ? '' : 'hidden'}`}>
      <div className="fixed inset-0 bg-black/50 backdrop-blur-sm" onClick={() => setShowPrivacyModal(false)}></div>
      <div className="bg-white rounded-lg p-8 max-w-3xl w-full mx-4 relative z-10 max-h-[90vh] overflow-y-auto">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-bold text-gray-900">Privacy Policy</h2>
          <button 
            onClick={() => setShowPrivacyModal(false)}
            className="text-gray-400 hover:text-gray-600 transition-colors"
          >
            <FontAwesomeIcon icon={faXmark} className="w-6 h-6" />
          </button>
        </div>

        <div className="prose prose-sm max-w-none mb-6">
          <h3 className="text-lg font-semibold mb-3">Privacy Policy</h3>
          <p className="mb-4">We respect your privacy and are committed to protecting your personal information. This page outlines our policies regarding the collection, use, and disclosure of personal data when you use our website and application.</p>
          <p className="mb-4">By using our website and application, you agree to the collection and use of information as described in this policy.</p>

          <h3 className="text-lg font-semibold mb-3">Information Collection and Use</h3>
          <p className="mb-4">We collect personal information that you voluntarily provide to us when you use our website and application. This includes your name, email address, and any other information you choose to provide.</p>
          <p className="mb-4">Any information you provide will be used to respond to your inquiries, fulfill your requests, and improve our services.</p>

          <h3 className="text-lg font-semibold mb-3">How We Use Google User Data</h3>
          <p className="mb-4">Our application, Veewer, accesses, uses, stores, and/or shares Google user data to provide and improve our services. Specifically:</p>
          <ul className="mb-4">
              <li><strong>File Upload:</strong> We use Google Drive to allow users to upload 3D model files to their Drive storage. This enables users to easily store and manage their 3D models.</li>
              <li><strong>File Listing:</strong> Our application lists the 3D model files stored in the user's Google Drive. This helps users to view and select the files they want to work with directly from their Drive.</li>
              <li><strong>File Viewing:</strong> We use the Viewer to display the 3D models stored in Google Drive. This allows users to view their 3D models directly within our application.</li>
          </ul>
          <p className="mb-4">We only use Google user data for the purposes disclosed in this Privacy Policy and in accordance with Google's Limited Use Requirements.</p>

          <h3 className="text-lg font-semibold mb-3">Log Data</h3>
          <p className="mb-4">Like many site operators, we collect information that your browser sends whenever you visit our site. This may include information such as your computer's Internet Protocol ("IP") address, browser type, browser version, the pages of our site that you visit, the time and date of your visit, the time spent on those pages, and other statistics.</p>

          <h3 className="text-lg font-semibold mb-3">Cookies</h3>
          <p className="mb-4">We use cookies to improve your experience on our site. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our site.</p>

          <h3 className="text-lg font-semibold mb-3">In-Product Privacy Notifications</h3>
          <p className="mb-4">In-product privacy notifications are prominently displayed in our app interface to inform users about how their data is being used. These notifications are kept up to date with our current practices.</p>

          <h3 className="text-lg font-semibold mb-3">Changes to This Privacy Policy</h3>
          <p className="mb-4">We may update our privacy policy from time to time. We will notify you of any changes by posting the new privacy policy on this page. You are advised to review this privacy policy periodically for any changes. Changes to this privacy policy are effective when they are posted on this page.</p>

          <h3 className="text-lg font-semibold mb-3">Contact Us</h3>
          <p className="mb-4">If you have any questions about this privacy policy, please contact us through our Codeo website.</p>
        </div>

        <div className="flex justify-end">
          <button
            onClick={() => {
              setAcceptedPrivacy(true);
              setShowPrivacyModal(false);
            }}
            className="bg-blue-600 text-white px-6 py-2 rounded-md hover:bg-blue-700 transition-colors"
          >
            I Accept
          </button>
        </div>
      </div>
    </div>
  );

  // Success handling
  useEffect(() => {
    if (postRegisterSelector.data != null && postRegisterSelector.loading === false) {
      Swal.fire({
        title: "Registration Complete!",
        text: "Welcome to Veewer! Please check your email to confirm your email address. Once confirmed, you can log in and explore all the features we have to offer.",
        icon: "success",
        confirmButtonText: "Go to Login",
        confirmButtonColor: "#2563eb",
      }).then((result) => {
        if (result.isConfirmed) {
          setActiveAction("login");
        }
      });
      dispatch(reset());
    }
  }, [postRegisterSelector.data, postRegisterSelector.loading]);

  // Update the button rendering in the form
  const renderNextButton = () => {
    if (currentStep >= totalSteps) return null;

    const isLoading = 
      (currentStep === 1 && isCheckingUsername) || 
      (currentStep === 2 && isCheckingEmail);

    return (
      <button
        type="button"
        onClick={handleNextStep}
        disabled={!canProceedToNextStep() || isLoading}
        className={`ml-auto flex items-center px-3 sm:px-4 py-2 rounded-md text-xs sm:text-sm font-medium ${
          canProceedToNextStep() && !isLoading
            ? 'bg-gradient-to-r from-[#16254c] to-[#1e3a8a] text-white hover:opacity-90 transition-all duration-300'
            : 'bg-gray-300 text-gray-500 cursor-not-allowed'
        }`}
      >
        {isLoading ? (
          <span className="flex items-center">
            Checking...
            <svg className="animate-spin ml-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
          </span>
        ) : (
          <>
            Next
            <FontAwesomeIcon icon={faArrowRight} className="ml-1 sm:ml-2" />
          </>
        )}
      </button>
    );
  };

  // Update the form navigation section
  const renderFormNavigation = () => (
    <div className="flex justify-between mt-4 sm:mt-6">
      {currentStep > 1 && (
        <button
          type="button"
          onClick={() => setCurrentStep(prev => prev - 1)}
          className="flex items-center px-3 sm:px-4 py-2 text-xs sm:text-sm font-medium text-gray-700 hover:text-gray-900"
        >
          <FontAwesomeIcon icon={faArrowLeft} className="mr-1 sm:mr-2" />
          Back
        </button>
      )}
      
      {currentStep < totalSteps ? (
        renderNextButton()
      ) : (
        <button
          type="submit"
          className={`w-full bg-gradient-to-r from-[#16254c] to-[#1e3a8a] text-white py-3 rounded-xl ${
            canProceedToNextStep() && acceptedTerms && acceptedPrivacy
            ? 'hover:opacity-90 transition-all duration-300'
            : 'opacity-50 cursor-not-allowed'
          } font-medium shadow-lg`}
          disabled={!canProceedToNextStep() || !acceptedTerms || !acceptedPrivacy}
          onClick={() => {
            if (canProceedToNextStep() && acceptedTerms && acceptedPrivacy) {
              dispatch(
                fetchUserRegister({
                  userName,
                  firstName,
                  lastName,
                  companyName,
                  password,
                  email,
                  phoneNumber: "",
                  subscriptionName: "Free"
                })
              );
            }
          }}
        >
          Sign Up
        </button>
      )}
    </div>
  );

  return (
    <div className="w-full">
      {postRegisterSelector.loading ? (
        <div className="flex items-center justify-center min-h-[400px]">
          <LoadingScreen isLoginMobile={false} />
        </div>
      ) : (
        <>
          {loginSelector.loading ? (
            <div className="flex items-center justify-center min-h-[400px]">
              <LoadingScreen isLoginMobile={false} />
            </div>
          ) : (
            <div className="min-h-[400px] flex flex-col">
              <div className="flex justify-between items-center mb-4">
                <div className="text-xs sm:text-sm font-medium text-gray-500">
                  Step {currentStep} of {totalSteps}
                </div>
                <div className="h-1.5 sm:h-2 flex-1 mx-2 sm:mx-4 bg-gray-200 rounded-full overflow-hidden">
                  <div
                    className="h-full bg-gradient-to-r from-[#16254c] to-[#1e3a8a] transition-all duration-300"
                    style={{ width: `${(currentStep / totalSteps) * 100}%` }}
                  />
                </div>
              </div>

              <form className='py-2 sm:py-4 flex-1 flex flex-col'>
                <div className='flex-1'>
                  {renderStepContent()}
                </div>
                {renderFormNavigation()}
              </form>
            </div>
          )}
        </>
      )}
      <TermsModal />
      <PrivacyModal />
    </div>
  );
};

export default SignUp;

